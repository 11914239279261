import { Col, Form, message, Radio, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Card, FormFinish } from 'src/components';
import { RadioChangeEvent } from 'antd/lib/radio';

import GoBack from 'src/components/GoBack/GoBack';
import { decorator, propertyTypes } from 'src/utils';
import {
  fetchProperty,
  updateProperty,
  removeFile,
} from 'src/store/properties/actions';
import { CLEAR_PROPERTY } from 'src/store/properties/types';
import { IReduxState, store } from 'src/store';

import PropertyAddressForm from './components/updateProperty/propertyAddress.component';
import PropertyDataForm from './components/updateProperty/propertyData.component';
import PropertyRuralForm from './components/updateProperty/propertyRural.component';
import { IParams, IProps } from './components/updateProperty/update.types';
import PropertyAreaForm from './components/updateProperty/propertyArea.component';
import PropertyValuesForm from './components/updateProperty/propertyValues.component';
import PropertyDocumentsForm from './components/updateProperty/propertyDocuments.component';
import PropertyImagesForm from './components/updateProperty/propertyImages.component';

const UpdatePropertyScreen: React.FC<IProps> = (props) => {
  // tslint:disable-next-line: no-shadowed-variable
  const { updateProperty, fetchProperty, property, removeFile } = props;
  const [loading, setLoading] = useState(true);
  const [sending, setSending] = useState(false);
  const [redirect, setRedirect] = useState('');
  const [zone, setZone] = useState('');
  const [type, setType] = useState('');
  const [subtype, setSubtype] = useState(property?.subtype);
  const [columns, setColumns] = useState(property?.columns);

  const { Option } = Select;

  const selected: [] = propertyTypes.types[type];

  useEffect(() => {
    const id = (props.match.params as IParams).id;
    fetchProperty(id)
      .then((res) => {
        document.title = `Imóvel: ${res.type}`;
        setLoading(false);
        if (res.zone === 'Urbana') {
          setZone('Urbana');
        } else {
          setZone('Rural');
        }
        setType(res.type);
        setSubtype(res.subtype);
      })
      .catch(() => {
        message.error('Imóvel não encontrado.');
      });
    return () => {
      document.title = 'Embrane | Admin';
    };
  }, []);

  const onFinish = (values: any) => {
    setSending(true);
    values.propertyId = property?.id;
    updateProperty(values)
      .then((res) => {
        setSending(false);
        message.success('Imóvel atualizado com sucesso!');
        setRedirect(`/painel/imoveis/${res.id}`);
      })
      .catch(() => {
        setSending(false);
      });
  };

  const onChangeZone = (e: RadioChangeEvent) => {
    setZone(e.target.value);
  };

  const onChangeType = (e: string, option: any) => {
    setType(e);
  };

  const onChangeSubtype = (e: string, option: any) => {
    setSubtype(e);
  };

  const onChangeColumn = (e: RadioChangeEvent) => {
    setColumns(e.target.value);
  };

  return (
    <>
      {!!redirect && <Redirect to={redirect} />}

      {!!property && (
        <>
          <GoBack />

          <Form onFinish={onFinish} layout="vertical">
            <PropertyAddressForm address={property.address} />
            <Card
              title="Características do Imóvel"
              subtitle="Altere as características do imóvel abaixo."
            >
              <Row gutter={8}>
                <Col xl={4} lg={5} md={8} sm={6}>
                  <Form.Item
                    label="Zona do Imóvel"
                    name="zone"
                    rules={decorator.zone}
                    initialValue={property.zone}
                  >
                    <Radio.Group onChange={onChangeZone} value={zone}>
                      <Radio value="Urbana" checked={zone === 'Urbana'}>
                        Urbana
                      </Radio>
                      <Radio value="Rural" checked={zone === 'Rural'}>
                        Rural
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col md={6}>
                  <Form.Item
                    label="Tipo do imóvel"
                    name="type"
                    rules={decorator.propertyType}
                    initialValue={property.type}
                  >
                    <Select
                      notFoundContent="Não há dados"
                      placeholder="Insira o tipo do imóvel"
                      onChange={onChangeType}
                    >
                      {propertyTypes &&
                        propertyTypes.propertyTypes.map((types) => (
                          <Option key={types.label} value={types.label}>
                            {types.label}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={7}>
                  <Form.Item
                    label="Subtípo do imóvel"
                    name="subtype"
                    rules={decorator.propertySubType}
                    initialValue={property.subtype}
                  >
                    <Select
                      placeholder="Insira o subtípo do imóvel"
                      disabled={!type}
                      notFoundContent="Conteúdo não encontrado."
                      onChange={onChangeSubtype}
                      value={subtype}
                    >
                      {selected &&
                        selected.map((sub: any) => (
                          <Option key={sub.id} value={sub.id}>
                            {sub.id}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={8}>
                {type === 'Apartamento' ||
                type === 'Apartamento Duplex' ||
                type === 'Apartamento Triplex' ||
                type === 'Cobertura Linear' ||
                type === 'Cobertura Duplex' ||
                type === 'Cobertura Triplex' ||
                type === 'Flat' ||
                type === 'Loft' ? (
                  <Col md={8}>
                    <Form.Item
                      label="Coluna"
                      name="columns"
                      rules={decorator.columns}
                      initialValue={property.columns}
                    >
                      <Radio.Group onChange={onChangeColumn} value={columns}>
                        <Radio value="Frente" checked={columns === 'frente'}>
                          Frente
                        </Radio>
                        <Radio value="Meio" checked={columns === 'Meio'}>
                          Meio
                        </Radio>
                        <Radio value="Fundos" checked={columns === 'fundos'}>
                          Fundos
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                ) : null}
              </Row>
            </Card>

            {zone === 'Urbana' && type !== 'Terreno' ? (
              <PropertyDataForm caracteristics={property.caracteristics} />
            ) : null}

            {zone === 'Rural' && type !== 'Terreno' ? (
              <PropertyRuralForm
                caracteristics={property.caracteristics}
                rural={property.rural}
              />
            ) : null}

            {zone === 'Urbana' || type === 'Terreno' ? (
              <PropertyAreaForm area={property.area} />
            ) : null}

            <PropertyValuesForm values={property.values} />

            <PropertyDocumentsForm
              scripture={property.scripture}
              registration={property.registration}
              subscription={property.subscription}
              car={property.car}
              itr={property.itr}
              ccir={property.ccir}
            />

            <PropertyImagesForm
              id={property.id}
              removeFile={removeFile}
              images={property.images}
            />

            <FormFinish text="Atualizar" sending={sending} />
          </Form>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  property: state.properties.property,
});

export default connect(mapStateToProps, {
  fetchProperty,
  updateProperty,
  removeFile,
})(UpdatePropertyScreen);
