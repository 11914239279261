import { message as messageAnt } from 'antd';

const form = {
  errors: ({ graphQLErrors, networkError }: any) => {
    console.log(graphQLErrors);
    let error = false;
    if (graphQLErrors.length) {
      graphQLErrors.map((item: any) => {
        if (graphQLErrors[0].code === 'CUSTOM_ERROR') {
          const { message } = graphQLErrors[0];
          messageAnt.error(message);
          error = true;
          return true;
        }
        if (
          item.extensions &&
          item.extensions.errors &&
          item.extensions.errors.length
        ) {
          item.extensions.errors.map((err: any) => {
            messageAnt.error(err);
            error = true;
            return true;
          });
        }
        return true;
      });
    }

    if (!error) {
      messageAnt.error(
        'Ocorreu um erro no servidor, por favor tente novamente mais tarde.'
      );

      return;
    }
  },
};

export default form;
