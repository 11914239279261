import gql from 'graphql-tag';
import {
  ICreatePropertyInput,
  IRemoveFileInput,
  IUpdatePropertyInput,
} from 'src/interfaces/property';
import client from './client.auth';

const PropertyServices = {
  fetchProperties: () =>
    client
      .query({
        query: gql`
          query properties {
            properties {
              id

              type
              subtype
              zone
              status
              address {
                street
                number
                neighborhood
                city
                state
                zipcode
                complement
                complex
                latitude
                longitude
              }
              builder
              images {
                path
              }
              area {
                backyard
                frontLot
                leftLot
                lotArea
                otherMeasures
                privateArea
                rightLot
              }
              caracteristics {
                balconies
                bathrooms
                dependencies
                garages
                kitchens
                livingRooms
                others
                rooms
                suites
                technicalAreas
                toilets
              }
              values {
                annualIptu
                monthlyIptu
                communityRentPrice
                communitySalePrice
                rentPrice
                salePrice
                rentExtraTaxes
                saleExtraTaxes
              }
              appointments {
                id
                appointmentDate
                appointmentHour
                broker {
                  id
                  name
                  companyName
                  email
                  phone
                }
                canceledDate
                createdAt
              }
              columns
              isSold
            }
          }
        `,
      })
      .then((res) => res.data.properties),

  fetchProperty: (id: string) =>
    client
      .query({
        query: gql`
          query fetchProperty($id: ID!) {
            property(id: $id) {
              id
              type
              subtype
              zone
              status
              address {
                street
                number
                neighborhood
                city
                state
                zipcode
                complement
                complex
                latitude
                longitude
              }
              builder
              images {
                path
              }
              car
              subscription
              itr
              scripture
              ccir
              registration
              bank
              overbalance
              extraHouses {
                balconies
                bathrooms
                dependencies
                garages
                kitchens
                livingRooms
                others
                rooms
                suites
                technicalAreas
                toilets
              }
              area {
                backyard
                frontLot
                leftLot
                lotArea
                otherMeasures
                privateArea
                rightLot
              }
              caracteristics {
                balconies
                bathrooms
                dependencies
                garages
                kitchens
                livingRooms
                others
                rooms
                suites
                technicalAreas
                toilets
              }
              values {
                annualIptu
                monthlyIptu
                communityRentPrice
                communitySalePrice
                rentPrice
                salePrice
                rentExtraTaxes
                saleExtraTaxes
              }
              appointments {
                id
                appointmentDate
                appointmentHour
                broker {
                  id
                  name
                  companyName
                  email
                  phone
                }
                canceledDate
                createdAt
              }
              columns
              isSold
            }
          }
        `,
        variables: { id },
      })
      .then((res) => res.data.property),

  createProperty: (input: ICreatePropertyInput) =>
    client
      .mutate({
        mutation: gql`
          mutation createProperty($input: CreatePropertyInput!) {
            createProperty(input: $input) {
              id
              type
              subtype
              zone
              status
              address {
                street
                number
                neighborhood
                city
                state
                zipcode
                complement
                complex
                latitude
                longitude
              }
              builder
              images {
                path
              }
              car
              subscription
              itr
              scripture
              ccir
              registration
              bank
              overbalance
              extraHouses {
                balconies
                bathrooms
                dependencies
                garages
                kitchens
                livingRooms
                others
                rooms
                suites
                technicalAreas
                toilets
              }
              area {
                backyard
                frontLot
                leftLot
                lotArea
                otherMeasures
                privateArea
                rightLot
              }
              caracteristics {
                balconies
                bathrooms
                dependencies
                garages
                kitchens
                livingRooms
                others
                rooms
                suites
                technicalAreas
                toilets
              }
              values {
                annualIptu
                monthlyIptu
                communityRentPrice
                communitySalePrice
                rentPrice
                salePrice
                rentExtraTaxes
                saleExtraTaxes
              }
              columns
              isSold
            }
          }
        `,
        variables: { input },
      })
      .then((res) => res.data.createProperty),

  updateProperty: (input: IUpdatePropertyInput) =>
    client
      .mutate({
        mutation: gql`
          mutation updateProperty($input: UpdatePropertyInput!) {
            updateProperty(input: $input) {
              id
              type
              subtype
              zone
              status
              address {
                street
                number
                neighborhood
                city
                state
                zipcode
                complement
                complex
                latitude
                longitude
              }
              builder
              images {
                path
              }
              area {
                backyard
                frontLot
                leftLot
                lotArea
                otherMeasures
                privateArea
                rightLot
              }
              caracteristics {
                balconies
                bathrooms
                dependencies
                garages
                kitchens
                livingRooms
                others
                rooms
                suites
                technicalAreas
                toilets
              }
              values {
                annualIptu
                monthlyIptu
                communityRentPrice
                communitySalePrice
                rentPrice
                salePrice
                rentExtraTaxes
                saleExtraTaxes
              }
              columns
              isSold
            }
          }
        `,
        variables: { input },
      })
      .then((res) => res.data.updateProperty),

  removeProperty: (id: string) =>
    client
      .mutate({
        mutation: gql`
          mutation removeProperty($id: ID!) {
            removeProperty(id: $id)
          }
        `,
        variables: { id },
      })
      .then((res) => res.data.removeProperty),

  removeFile: (input: IRemoveFileInput) =>
    client
      .mutate({
        mutation: gql`
          mutation removeFile($input: RemoveFile!) {
            removeFile(input: $input)
          }
        `,
        variables: { input },
      })
      .then((res) => res.data.removeFile),

  updatePropertyStatus: (id: string, newStatus: string) =>
    client
      .mutate({
        mutation: gql`
          mutation updatePropertyStatus($newStatus: String!, $id: ID!) {
            updatePropertyStatus(newStatus: $newStatus, id: $id) {
              id
            }
          }
        `,
        variables: { id, newStatus },
      })
      .then((res) => res.data.updatePropertyStatus),
};

export default PropertyServices;
