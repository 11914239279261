import React, { useState } from 'react';
import { Form, Row, Col, Input, Select } from 'antd';
import InputMask from 'react-input-mask';

import { decorator } from 'src/utils';
import { states } from 'src/utils/';

interface IProps {
  onSearch: (value: string, event: any) => void;
  loading: boolean;
}

const CompanyForm: React.FC<IProps> = (props) => {
  const [phone, setPhone] = useState('');
  const [cep, setCep] = useState('');

  const maskCep = '99999-999';

  const { Search } = Input;
  const { onSearch, loading } = props;
  const { Option } = Select;

  const handleCep = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (value.replace(/ /g, '').length === 9) {
      setCep(value);
    }
  };

  const handlePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (value.replace(/ /g, '').length === 14) {
      setPhone('(99)99999-9999');
    }
  };
  return (
    <>
      <Row gutter={16}>
        <Col md={12}>
          <Form.Item
            name="companyName"
            label="Nome da empresa"
            rules={decorator.companyName}
          >
            <Input placeholder="Insira o nome da empresa..." />
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item rules={decorator.email} name="email" label="E-mail">
            <Input placeholder="Insira o e-mail da empresa..." />
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item rules={decorator.phone} name="phone" label="Telefone">
            <InputMask
              mask="99 99999-9999"
              value={phone}
              onChange={handlePhone}
              maskChar=""
            >
              {(inputProps: string) => (
                <Input
                  {...inputProps}
                  placeholder="Insira o telefone da empresa..."
                />
              )}
            </InputMask>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={12}>
          <Form.Item name="cnpj" label="CNPJ" rules={decorator.cnpj}>
            <InputMask maskChar="" mask="99.999.999/9999-99">
              {(inputProps: string) => (
                <Input
                  {...inputProps}
                  placeholder="Insira o CNPJ da empresa..."
                />
              )}
            </InputMask>
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item label="Profissão" name="profession">
            <Input placeholder="Insira a profissão da empresa" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col md={4} lg={4} xs={24} sm={24}>
          <Form.Item
            rules={decorator.state}
            name={['address', 'state']}
            label="Estado"
          >
            <Select placeholder="">
              {states.map((item) => (
                <Option key={item.key} value={item.uf}>
                  {item.uf}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col md={4}>
          <Form.Item
            name={['address', 'zipcode']}
            label="CEP"
            rules={decorator.zipcode}
          >
            <InputMask
              maskChar=""
              mask={maskCep}
              value={cep}
              onChange={handleCep}
            >
              {(inputProps: string) => (
                <Search
                  {...inputProps}
                  onSearch={onSearch}
                  loading={loading}
                  placeholder="Insira o CEP..."
                />
              )}
            </InputMask>
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item
            name={['address', 'city']}
            label="Cidade"
            rules={decorator.city}
          >
            <Input placeholder="Insira a cidade da empresa..." />
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item
            name={['address', 'neighborhood']}
            rules={decorator.neighborhood}
            label="Bairro"
          >
            <Input placeholder="Insira o bairro da empresa..." />
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item
            name={['address', 'street']}
            label="Rua"
            rules={decorator.street}
          >
            <Input placeholder="Insira a rua da empresa..." />
          </Form.Item>
        </Col>
        <Col md={4}>
          <Form.Item
            name={['address', 'number']}
            rules={decorator.streetNumber}
            label="Número"
          >
            <Input placeholder="Insira o número..." />
          </Form.Item>
        </Col>
        <Col md={8}>
          <Form.Item
            name={['address', 'complement']}
            label="Complemento"
            rules={decorator.complement}
          >
            <Input placeholder="(Opcional) Insira um complemento..." />
          </Form.Item>
        </Col>

        <Col md={12}>
          <Form.Item name="password" label="Senha" rules={decorator.password}>
            <Input
              type="password"
              placeholder="Insira a senha do proprietário..."
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default CompanyForm;
