import React, { useState } from 'react';
import {
  Col,
  Form,
  Input,
  message,
  notification,
  Radio,
  Row,
  Select,
} from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { connect } from 'react-redux';

import { Card, FormFinish } from 'src/components';
import { IProps } from './owners.types';
import { decorator, propertyTypes } from 'src/utils';
import { createOwner } from 'src/store/owners/actions';
import { createProperty } from 'src/store/properties/actions';

import PersonForm from './components/newOwner/person.form.component';
import CompanyForm from './components/newOwner/company.form.component';
import PropertyAddressForm from './components/newOwner/createProperty/address.component';
import PropertyCategoryForm from './components/newOwner/createProperty/category.component';
import PropertyCaracteristicsForm from './components/newOwner/createProperty/caracteristics.component';
import PropertyAreaForm from './components/newOwner/createProperty/area.component';
import PropertyRuralForm from './components/newOwner/createProperty/rural/rural.component';
import PropertyDocumentsForm from './components/newOwner/createProperty/documents.component';
import PropertyValueForm from './components/newOwner/createProperty/values.component';
import PropertyImagesForm from './components/newOwner/createProperty/images.component';
import { Redirect } from 'react-router-dom';
import FuseAddress from 'src/components/FuseAddress';
import { IMarker } from 'src/interfaces/maps';

const NewOwnerScreen: React.FC<IProps> = (props) => {
  const [checked, setChecked] = useState('');
  const [sending, setSending] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [zone, setZone] = useState<string>('');
  const [category, setCategory] = useState<string>('');
  const [marker, setMarker] = useState<IMarker>();

  const [redirect, setRedirect] = useState('');
  const { Option } = Select;
  const [form] = Form.useForm();

  const onSearchPerson = (
    value: string,
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.MouseEvent<HTMLElement, MouseEvent>
      | React.KeyboardEvent<HTMLInputElement>
      | undefined
  ) => {
    setLoading(true);
    const formattedZipcode = value.replace('-', '');
    if (formattedZipcode.length !== 8) {
      return setLoading(false);
    }
    fetch(`https://viacep.com.br/ws/${formattedZipcode}/json`)
      .then((res) =>
        res.json().then((data) => {
          if (data.erro === true) {
            setLoading(false);
            return notification.error({
              message:
                'CEP inválido, verifique se o CEP digitado está correto ou se ele existe.',
            });
          }
          form.setFieldsValue({
            address: {
              city: data.localidade,
              state: data.uf,
              neighborhood: data.bairro,
              street: data.logradouro,
              complement: data.complemento,
            },
          });
          setLoading(false);
        })
      )
      .catch((err) => {
        setLoading(false);
        notification.error({
          message: 'Insira o CEP para pesquisar.',
        });
      });
  };

  const onSearchCompany = (
    value: string,
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.MouseEvent<HTMLElement, MouseEvent>
      | React.KeyboardEvent<HTMLInputElement>
      | undefined
  ) => {
    setLoading(true);
    const formattedZipcode = value.replace('-', '');
    if (formattedZipcode.length !== 8) {
      return setLoading(false);
    }
    fetch(`https://viacep.com.br/ws/${formattedZipcode}/json`)
      .then((res) =>
        res.json().then((data) => {
          if (data.erro === true) {
            setLoading(false);
            return notification.error({
              message:
                'CEP inválido, verifique se o CEP digitado está correto ou se ele existe.',
            });
          }
          form.setFieldsValue({
            address: {
              city: data.localidade,
              state: data.uf,
              neighborhood: data.bairro,
              street: data.logradouro,
              complement: data.complemento,
            },
          });
          setLoading(false);
        })
      )
      .catch((err) => {
        setLoading(false);
        notification.error({
          message: 'Insira o CEP para pesquisar.',
        });
      });
  };

  const onChangeAddress = async (name: string) => {
    const addressFields = form.getFieldsValue(['property', 'address']);

    const numberField = form.getFieldValue(['property', 'address', 'number']);
    let formattedAddress;
    formattedAddress = `${addressFields.property.address.zipcode} ${addressFields.property.address.state} ${addressFields.property.address.city} ${addressFields.property.address.neighborhood} ${addressFields.property.address.street} ${addressFields.property.address.number}`;
    console.log(formattedAddress);

    if (numberField) {
      await FuseAddress(formattedAddress).then((res) => {
        form.setFieldsValue({
          property: {
            address: {
              latitude: res.lat,
              longitude: res.lng,
            },
          },
        });
        setMarker({
          lat: res.lat,
          lng: res.lng,
        });
      });
    }
  };

  const handleCheck = (e: RadioChangeEvent) => {
    setZone(e.target.value);
  };

  const onCategoryChange = (value: any, option: any) => {
    setCategory(value);
  };

  const onChange = (e: RadioChangeEvent) => {
    setChecked(e.target.value);
  };

  const onFinish = (values: any) => {
    const { property, ...all } = values;
    const { userId, ...data } = property;
    setSending(true);
    props
      .createOwner({ ...all })
      .then((res) => {
        props
          .createProperty({
            userId: res.id,
            ...data,
          })
          .then(() => {
            message.success('Proprietário Cadastrado');
            setRedirect('/painel/proprietarios/');
          })
          .catch((err) => {
            setSending(false);
          });
      })
      .catch((err) => {
        setSending(false);
      });
  };

  return (
    <>
      <Form layout="vertical" onFinish={onFinish} form={form}>
        {!!redirect && <Redirect to={redirect} />}
        <Card
          title="Cadastrar Proprietário"
          subtitle="Cadastre um novo proprietário (pessoa ou empresa)."
        >
          <Form.Item
            required
            rules={[
              {
                required: true,
                message: 'Selecione o tipo do Proprietário',
              },
            ]}
          >
            <Radio.Group onChange={onChange} value={checked}>
              <Radio value="person" checked={checked === 'person'}>
                Pessoa Física
              </Radio>
              <Radio value="company" checked={checked === 'company'}>
                Pessoa Jurídica (Empresa)
              </Radio>
            </Radio.Group>
          </Form.Item>

          {checked === 'person' ? (
            <PersonForm onSearch={onSearchPerson} loading={loading} />
          ) : undefined}
          {checked === 'company' ? (
            <CompanyForm onSearch={onSearchCompany} loading={loading} />
          ) : undefined}
        </Card>

        <Card
          title="Cadastrar Propriedade"
          subtitle="Cadastre a propriedade do Proprietário abaixo."
        >
          <PropertyAddressForm
            marker={marker}
            onChangeAddress={onChangeAddress}
            loading={loading}
          />
          <PropertyCategoryForm
            handleCheck={handleCheck}
            onCategoryChange={onCategoryChange}
            category={category}
          />
          {zone === 'Urbana' && category !== 'Terreno' ? (
            <PropertyCaracteristicsForm />
          ) : null}
          {zone === 'Rural' && category !== 'Terreno' ? (
            <PropertyRuralForm />
          ) : null}

          {zone === 'Urbana' || category === 'Terreno' ? (
            <PropertyAreaForm />
          ) : null}
          <PropertyValueForm />

          <PropertyDocumentsForm />

          <PropertyImagesForm />
        </Card>

        <FormFinish text="Cadastrar" sending={sending} />
      </Form>
    </>
  );
};

export default connect(undefined, { createOwner, createProperty })(
  NewOwnerScreen
);
