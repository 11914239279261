import React from 'react';

import { Container, Wrapper } from './caracteristics.styles';

interface IProps {
  title?: string;
  amount?: number;
  icon?: object;
}

const Caracteristics: React.FC<IProps> = (props) => {
  const { title, amount, icon } = props;

  return (
    <>
      <Container>
        <span>
          <h6>{title}</h6>
          {icon}
          {amount}
        </span>
      </Container>
    </>
  );
};

export default Caracteristics;
