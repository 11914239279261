import { IAppointment } from "src/interfaces/appointments";

export const FETCH_APPOINTMENTS = "FETCH_APPOINTMENTS";
export const FETCH_APPOINTMENT = "FETCH_APPOINTMENT";


export interface IReducerAppointmentState {
  appointment?: IAppointment;
  appointments?: IAppointment[];
}


export interface IFetchAppointments {
  type: typeof FETCH_APPOINTMENTS;
  payload: IAppointment[];
}

export interface IFetchAppointment {
  type: typeof FETCH_APPOINTMENT;
  payload: IAppointment;
}


export type AppointmentActionTypes = IFetchAppointment | IFetchAppointments;