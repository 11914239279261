import { Button, Col, Form, Row, InputNumber } from 'antd';
import React from 'react';
import { FaMinusCircle, FaPlusCircle } from 'react-icons/fa';
import { decorator } from 'src/utils';
import HeadquartersComponent from '../headquarters.component';

const ExtraHouses: React.FC = () => {
  const { List, Item } = Form;

  return (
    <List name={['property', 'extraHouses']}>
      {(fields, { add, remove }) => {
        return (
          <div>
            {fields.map((field, index) => (
              <div key={field.key} style={{ margin: '30px 0' }}>
                <h4>Casa Extra {index + 1}</h4>
                <Row gutter={16}>
                  <Col md={12} lg={4} sm={12} xs={24}>
                    <Item label="Quartos" name={[field.name, 'rooms']}>
                      <InputNumber />
                    </Item>
                  </Col>
                  <Col md={12} lg={4} sm={12} xs={24}>
                    <Item label="Suítes" name={[field.name, 'suites']}>
                      <InputNumber />
                    </Item>
                  </Col>
                  <Col md={12} lg={4} sm={12} xs={24}>
                    <Item label="Salas" name={[field.name, 'livingRooms']}>
                      <InputNumber />
                    </Item>
                  </Col>
                  <Col md={12} lg={4} sm={12} xs={24}>
                    <Item label="Banheiros" name={[field.name, 'bathrooms']}>
                      <InputNumber />
                    </Item>
                  </Col>
                  <Col md={24} lg={4} sm={24} xs={24}>
                    <Item label="Varandas" name={[field.name, 'balconies']}>
                      <InputNumber />
                    </Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col md={12} lg={4} sm={12} xs={24}>
                    <Item label="Cozinhas" name={[field.name, 'kitchens']}>
                      <InputNumber />
                    </Item>
                  </Col>
                  <Col md={12} lg={4} sm={12} xs={24}>
                    <Item label="Lavabos" name={[field.name, 'toilets']}>
                      <InputNumber />
                    </Item>
                  </Col>
                  <Col md={12} lg={4} sm={12} xs={24}>
                    <Item
                      label="Dependências"
                      name={[field.name, 'dependencies']}
                    >
                      <InputNumber />
                    </Item>
                  </Col>
                  <Col md={12} lg={4} sm={12} xs={24}>
                    <Item
                      label="Área Técnica"
                      name={[field.name, 'technicalAreas']}
                    >
                      <InputNumber />
                    </Item>
                  </Col>
                  <Col md={24} lg={4} sm={24} xs={24}>
                    <Item label="Garagens" name={[field.name, 'garages']}>
                      <InputNumber />
                    </Item>
                  </Col>
                  <Button
                    danger
                    className="btn-extra"
                    onClick={() => remove(field.name)}
                    icon={<FaMinusCircle />}
                  >
                    Remover
                  </Button>
                </Row>
              </div>
            ))}
            <Button onClick={() => add()} className="btn-add">
              <FaPlusCircle /> Casa Extra
            </Button>
          </div>
        );
      }}
    </List>
  );
};

export default ExtraHouses;
