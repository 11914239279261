import React, { useState } from 'react';
import { Button, Col, Divider, Form, Input, Radio, Row, Upload } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { FaFileUpload } from 'react-icons/fa';
import { RcFile, UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import { store } from 'src/store';
import {
  STORE_CAR,
  STORE_CCIR,
  STORE_ITR,
  STORE_REGISTRATION,
  STORE_SCRIPTURE,
  STORE_SUBSCRIPTION,
} from 'src/store/properties/types';

const DocumentsForm: React.FC = () => {
  const [checked, setChecked] = useState('');
  const [scripture, setScripture] = useState<UploadFile<any>[]>([]);
  const [registration, setRegistration] = useState<UploadFile<any>[]>([]);
  const [subscription, setSubscription] = useState<UploadFile<any>[]>([]);
  const [car, setCar] = useState<UploadFile<any>[]>([]);
  const [ccir, setCcir] = useState<UploadFile<any>[]>([]);
  const [itr, setItr] = useState<UploadFile<any>[]>([]);

  const { Item } = Form;
  const { Group } = Radio;

  const onCheck = (e: RadioChangeEvent) => {
    setChecked(e.target.value);
  };

  const beforeUpload = (file: RcFile) => {
    return false;
  };

  const onChangeScripture = ({ fileList }: UploadChangeParam) => {
    setScripture(fileList);
    store.dispatch({
      type: STORE_SCRIPTURE,
      payload: fileList[0].originFileObj,
    });
  };

  const onChangeRegistration = ({ fileList }: UploadChangeParam) => {
    setRegistration(fileList);
    store.dispatch({
      type: STORE_REGISTRATION,

      payload: fileList[0].originFileObj,
    });
  };

  const onChangeSubscription = ({ fileList }: UploadChangeParam) => {
    setSubscription(fileList);
    store.dispatch({
      type: STORE_SUBSCRIPTION,
      payload: fileList[0].originFileObj,
    });
  };

  const onChangeCar = ({ fileList }: UploadChangeParam) => {
    setCar(fileList);
    store.dispatch({
      type: STORE_CAR,
      payload: fileList[0].originFileObj,
    });
  };

  const onChangeCcir = ({ fileList }: UploadChangeParam) => {
    setCcir(fileList);
    store.dispatch({
      type: STORE_CCIR,
      payload: fileList[0].originFileObj,
    });
  };

  const onChangeItr = ({ fileList }: UploadChangeParam) => {
    setItr(fileList);
    store.dispatch({
      type: STORE_ITR,
      payload: fileList[0].originFileObj,
    });
  };

  return (
    <>
      <Divider />
      <h3>Documentos do Imóvel</h3>
      <Row gutter={16} justify="center">
        <Col md={12} lg={8} sm={24} xs={24}>
          <Item label="Escritura" name={['property', 'scripture']}>
            <Upload
              beforeUpload={beforeUpload}
              onChange={onChangeScripture}
              fileList={scripture}
              accept=".png,.jpg,.jpeg,.webp,.raw,.tiff,.pdf"
            >
              <Button icon={<FaFileUpload />}>Adicionar Documento</Button>
            </Upload>
          </Item>
        </Col>

        <Col md={12} lg={8} sm={24} xs={24}>
          <Item label="Matrícula (RGI)" name={['property', 'registration']}>
            <Upload
              beforeUpload={beforeUpload}
              onChange={onChangeRegistration}
              fileList={registration}
              accept=".png,.jpg,.jpeg,.webp,.raw,.tiff,.pdf"
            >
              <Button icon={<FaFileUpload />}>Adicionar Documento</Button>
            </Upload>
          </Item>
        </Col>

        <Col md={12} lg={8} sm={24} xs={24}>
          <Item
            label="Inscrição Municipal (IPTU)"
            name={['property', 'subscription']}
          >
            <Upload
              beforeUpload={beforeUpload}
              onChange={onChangeSubscription}
              fileList={subscription}
              accept=".png,.jpg,.jpeg,.webp,.raw,.tiff,.pdf"
            >
              <Button icon={<FaFileUpload />}>Adicionar Documento</Button>
            </Upload>
          </Item>
        </Col>
        <Col md={12} lg={8} sm={24} xs={24}>
          <Item label="CAR" name={['property', 'car']}>
            <Upload
              beforeUpload={beforeUpload}
              onChange={onChangeCar}
              fileList={car}
              accept=".png,.jpg,.jpeg,.webp,.raw,.tiff,.pdf"
            >
              <Button icon={<FaFileUpload />}>Adicionar Documento</Button>
            </Upload>
          </Item>
        </Col>

        <Col md={12} lg={8} sm={24} xs={24}>
          <Item label="CCIR" name={['property', 'ccir']}>
            <Upload
              beforeUpload={beforeUpload}
              onChange={onChangeCcir}
              fileList={ccir}
              accept=".png,.jpg,.jpeg,.webp,.raw,.tiff,.pdf"
            >
              <Button icon={<FaFileUpload />}>Adicionar Documento</Button>
            </Upload>
          </Item>
        </Col>

        <Col md={12} lg={8} sm={24} xs={24}>
          <Item label="ITR" name={['property', 'itr']}>
            <Upload
              fileList={itr}
              onChange={onChangeItr}
              beforeUpload={beforeUpload}
              accept=".png,.jpg,.jpeg,.webp,.raw,.tiff,.pdf"
            >
              <Button icon={<FaFileUpload />}>Adicionar Documento</Button>
            </Upload>
          </Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={6} lg={6} sm={12} xs={24}>
          <Item label="Financiamento">
            <Group onChange={onCheck}>
              <Radio value="yes" checked={checked === 'yes'}>
                Sim
              </Radio>
              <Radio value="no" checked={checked === 'no'}>
                Não
              </Radio>
            </Group>
          </Item>
        </Col>
        {checked === 'yes' ? (
          <>
            <Col md={6} lg={6} sm={12} xs={24}>
              <Item label="Banco" name={['property', 'bank']} preserve={false}>
                <Input />
              </Item>
            </Col>

            <Col md={6} lg={6} sm={12} xs={24}>
              <Item
                label="Saldo Devedor"
                name={['property', 'overbalance']}
                preserve={false}
              >
                <Input />
              </Item>
            </Col>
          </>
        ) : null}
      </Row>
    </>
  );
};

export default DocumentsForm;
