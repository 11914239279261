import { Dispatch } from 'react';
import {
  ICreatePropertyInput,
  IRemoveFileInput,
  IUpdatePropertyInput,
} from 'src/interfaces/property';
import PropertyServices from 'src/services/properties';
import { forms } from 'src/utils';
import { store } from '..';
import {
  CREATE_PROPERTY,
  FETCH_PROPERTIES,
  FETCH_PROPERTY,
  PropertyActionTypes,
  REMOVE_FILE,
  REMOVE_PROPERTY,
  UPDATE_PROPERTY,
  UPDATE_PROPERTY_STATUS,
} from './types';

export const fetchProperties =
  () => (dispatch: Dispatch<PropertyActionTypes>) => {
    return PropertyServices.fetchProperties()
      .then((res) => {
        dispatch({
          type: FETCH_PROPERTIES,
          payload: res,
        });
        return res;
      })
      .catch((err) => {
        throw Error;
      });
  };

export const fetchProperty =
  (id: string) => (dispatch: Dispatch<PropertyActionTypes>) => {
    return PropertyServices.fetchProperty(id).then((res) => {
      dispatch({
        type: FETCH_PROPERTY,
        payload: res,
      });
      return res;
    });
  };

export const createProperty =
  (input: ICreatePropertyInput) =>
  (dispatch: Dispatch<PropertyActionTypes>) => {
    input.images = store.getState().properties.images;
    input.scripture = store.getState().properties.scripture;
    input.registration = store.getState().properties.registration;
    input.subscription = store.getState().properties.subscription;
    input.itr = store.getState().properties.itr;
    input.car = store.getState().properties.car;
    input.ccir = store.getState().properties.ccir;
    input.values = store.getState().properties.values;
    input.area = store.getState().properties.area;

    console.log(input);

    return PropertyServices.createProperty(input)
      .then((res) => {
        dispatch({
          type: CREATE_PROPERTY,
          payload: res,
        });
        return res;
      })
      .catch((err) => {
        forms.errors(err);
        throw Error;
      });
  };

export const updateProperty =
  (input: IUpdatePropertyInput) =>
  (dispatch: Dispatch<PropertyActionTypes>) => {
    input.images = store.getState().properties.images!;
    input.scripture = store.getState().properties.scripture;
    input.registration = store.getState().properties.registration;
    input.subscription = store.getState().properties.subscription;
    input.itr = store.getState().properties.itr;
    input.car = store.getState().properties.car;
    input.ccir = store.getState().properties.ccir;

    input.values = store.getState().properties.values!;

    return PropertyServices.updateProperty(input)
      .then((res) => {
        dispatch({
          type: UPDATE_PROPERTY,
          payload: res,
        });
        return res;
      })
      .catch((err) => {
        forms.errors(err);
        throw Error;
      });
  };

export const removeProperty =
  (id: string) => (dispatch: Dispatch<PropertyActionTypes>) => {
    return PropertyServices.removeProperty(id).then((res) => {
      dispatch({
        type: REMOVE_PROPERTY,
      });
      return res;
    });
  };

export const removeFile =
  (input: IRemoveFileInput) => (dispatch: Dispatch<PropertyActionTypes>) => {
    return PropertyServices.removeFile(input).then((res) => {
      dispatch({
        type: REMOVE_FILE,
        payload: input.filePath,
      });
      return res;
    });
  };

export const updatePropertyStatus =
  (newStatus: string, id: string) =>
  (dispatch: Dispatch<PropertyActionTypes>) => {
    return PropertyServices.updatePropertyStatus(id, newStatus).then((res) => {
      dispatch({
        type: UPDATE_PROPERTY_STATUS,
        payload: res,
      });
      return res;
    });
  };
