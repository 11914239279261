import { Button, Divider, Form, notification, Select } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card } from 'src/components';

import { IReduxState } from 'src/store';
import { createProperty } from 'src/store/properties/actions';
import { fetchOwners } from 'src/store/owners/actions';

import AddressForm from './components/createProperty/address.component';
import CaracteristicsForm from './components/createProperty/caracteristics.component';
import CategoryForm from './components/createProperty/category.component';
import PriceForm from './components/createProperty/values.component';
import RuralForm from './components/createProperty/rural/rural.component';
import AreaForm from './components/createProperty/area.component';
import DocumentsForm from './components/createProperty/documents.component';
import ImagesForm from './components/createProperty/images.component';

import { IProps } from './properties.types';
import { Redirect } from 'react-router-dom';
import FuseAddress from 'src/components/FuseAddress';
import { IMarker } from 'src/interfaces/maps';

const CreatePropertyScreen: React.FC<IProps> = (props) => {
  const [sending, setSending] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [redirect, setRedirect] = useState('');
  const [category, setCategory] = useState('');
  const [marker, setMarker] = useState<IMarker>();

  const [zone, setZone] = useState('');

  const { owners } = props;
  const { Option } = Select;
  const [form] = Form.useForm();

  const onChange = async (name: string, value: string) => {
    const addressFields = form.getFieldsValue(['address']);

    let formattedAddress;

    formattedAddress = `${addressFields.address.zipcode} ${addressFields.address.state} ${addressFields.address.city} ${addressFields.address.neighborhood} ${addressFields.address.street} ${addressFields.address.number}`;

    if (name === 'number') {
      await FuseAddress(formattedAddress).then((res) => {
        form.setFieldsValue({
          address: {
            latitude: res.lat,
            longitude: res.lng,
          },
        });
        setMarker({
          lat: res.lat,
          lng: res.lng,
        });
      });
    }
  };

  const handleCheck = (e: RadioChangeEvent) => {
    setZone(e.target.value);
  };

  const onCategoryChange = (value: any, option: any) => {
    setCategory(value);
  };

  useEffect(() => {
    props.fetchOwners();
  }, []);

  const onFinish = (values: any) => {
    setSending(true);
    props
      .createProperty(values)
      .then((res) => {
        setSending(false);

        setRedirect(`/painel/imoveis/${res.id}`);
      })
      .catch(() => {
        setSending(false);
      });
  };

  return (
    <Card
      title="Cadastrar Imóvel"
      subtitle="Preencha o formulário abaixo para cadastrar seus imóveis."
    >
      {!!redirect && <Redirect to={redirect} />}
      <Form onFinish={onFinish} layout="vertical" form={form}>
        <Form.Item label="Proprietário" name="userId">
          <Select
            showSearch
            placeholder="Escolha o proprietário do Imóvel que será cadastrado"
            optionFilterProp="children"
            filterOption={(input, option) =>
              option!
                .label!.toString()
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {owners?.map((owner) => (
              <Option
                key={owner.id}
                value={owner.id}
                label={`${owner.companyName || owner.name} | ${
                  owner.owner?.cpf || owner.owner?.cnpj
                }`}
              >
                <strong> {owner.companyName || owner.name} </strong> |{' '}
                <i>{owner.owner?.cpf || owner.owner?.cnpj}</i>
              </Option>
            ))}
          </Select>
        </Form.Item>
        <AddressForm marker={marker} onChange={onChange} />

        <CategoryForm
          handleCheck={handleCheck}
          onCategoryChange={onCategoryChange}
          category={category}
        />
        {zone === 'Urbana' && category !== 'Terreno' ? (
          <CaracteristicsForm />
        ) : null}
        {zone === 'Rural' && category !== 'Terreno' ? <RuralForm /> : null}
        {zone === 'Urbana' || category === 'Terreno' ? <AreaForm /> : null}
        <PriceForm />
        <DocumentsForm />
        <ImagesForm />
        <Divider />
        <h3>Verifique todas as suas informações antes de prosseguir.</h3>
        <br />
        <Button htmlType="submit" loading={sending}>
          Avançar
        </Button>
      </Form>
    </Card>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  property: state.properties.property,
  owners: state.owners.owners,
});

export default connect(mapStateToProps, { createProperty, fetchOwners })(
  CreatePropertyScreen
);
