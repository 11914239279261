import { Table } from 'antd';
import React from 'react';
import { Card } from 'src/components';
import { table } from 'src/utils';

const RentedReportsScreen: React.FC = () => {
  const columns = [
    {
      title: 'Imóvel',
      key: 'type',
      dataIndex: 'type',
    },
    {
      title: 'Proprietário',
      key: 'owner',
      dataIndex: 'owner',
    },
    {
      title: 'Comprador',
      key: 'brokerName',
      dataIndex: 'brokerName',
    },
    {
      title: 'Preço de Locação',
      key: 'rentPrice',
      dataIndex: 'rentPrice',
    },
  ];

  return (
    <Card
      title="Relatórios de Locação"
      subtitle="Veja os imóveis alugados no sistema"
    >
      <Table {...table} columns={columns} scroll={{ x: 500 }} />
    </Card>
  );
};

export default RentedReportsScreen;
