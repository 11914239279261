import { Descriptions } from 'antd';
import React from 'react';
import { IAddress } from 'src/interfaces/misc';

interface IProps {
  address: IAddress;
}

const AddressData: React.FC<IProps> = (props) => {
  const { address } = props;
  const { Item } = Descriptions;

  return (
    <Descriptions title="Endereço do Imóvel" style={{ marginBottom: '35px' }}>
      <Item label="CEP"> {address?.zipcode} </Item>
      <Item label="Estado"> {address?.state} </Item>
      <Item label="Cidade"> {address?.city} </Item>
      <Item label="Bairro"> {address?.neighborhood} </Item>
      <Item label="Rua">
        {address.street}-{address.number}
      </Item>
      <Item label="Complemento">{address?.complement || 'Não Informado.'}</Item>
      <Item label="Condomínio">{address?.complex || 'Não Possuí.'}</Item>
      <Item label="Latitude">{address?.latitude || 'Não Informado.'}</Item>
      <Item label="Longitude">{address?.longitude || 'Não Informado.'}</Item>
    </Descriptions>
  );
};

export default AddressData;
