import { Descriptions } from 'antd';
import React from 'react';
import { Card } from 'src/components';

import { IProps } from './brokerData.types';

const BrokerData: React.FC<IProps> = (props) => {
  const { broker } = props;
  const { Item } = Descriptions;

  return (
    <Card
      title="Dados do Corretor(a)"
      subtitle="Veja as informações do corretor(a)."
    >
      <Descriptions title="Dados Básicos" column={2}>
        {broker?.companyName ? (
          <Item label="Nome da Empresa">{broker.companyName}</Item>
        ) : (
          <Item label="Nome do Corretor(a)">{broker?.name} </Item>
        )}
        <Item label="E-mail"> {broker?.email} </Item>
        <Item label="Data de Nascimento"> {broker?.birthdate} </Item>

        {broker?.broker?.cpf ? (
          <Item label="CPF"> {broker.broker?.cpf} </Item>
        ) : (
          <Item label="CNPJ"> {broker?.broker?.cnpj} </Item>
        )}
        <Item label="CRECI"> {broker?.broker?.creci} </Item>
        <Item label="Inscrição Estadual">
          {broker?.broker?.stateRegistration || 'Não Informado.'}
        </Item>
      </Descriptions>
      <br />
      <Descriptions title="Informações de Contato." column={2}>
        <Item label="Telefone"> {broker?.phone} </Item>

        <Item label="E-mail"> {broker?.email} </Item>
      </Descriptions>
      <br />
      <Descriptions title="Endereço" column={2}>
        <Item label="Endereço">
          {broker?.broker?.address.street} - {broker?.broker?.address.number} -{' '}
          {broker?.broker?.address.neighborhood}
        </Item>

        <Item label="Complemento">
          {broker?.broker?.address.complement || 'Não Informado.'}
        </Item>
        <Item label="Cidade">
          {broker?.broker?.address.city} - {broker?.broker?.address.state}
        </Item>
        <Item label="CEP">{broker?.broker?.address.zipcode}</Item>
      </Descriptions>
      <br />
      {broker?.broker?.status === 'approved' ? (
        <Descriptions title="Imóveis">
          <Item label="Imóveis adquiridos">
            {broker?.broker?.propertiesAcquired || '0'}
          </Item>
          <Item label="Propostas Feitas">{broker.broker.proposals || '0'}</Item>
        </Descriptions>
      ) : null}
    </Card>
  );
};

export default BrokerData;
