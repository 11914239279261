import styled from 'styled-components';

export const Wrapper = styled.div`
  border-top: 1px solid rgba(127.5, 127.5, 127.5, 0.1);
  padding: 15px;
  text-align: center;
  background-color: var(--app-terciary-color);
`;

export const Text = styled.p`
  font-size: 13px;
  margin: 0;
  color: var(--app-secondary-color);
`;
