import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import GoBack from 'src/components/GoBack/GoBack';
import PropertyData from './components/showProperty/propertyData.component';
import { message } from 'antd';
import { IReduxState, store } from 'src/store';
import { CLEAR_PROPERTY } from 'src/store/properties/types';
import { fetchProperty } from 'src/store/properties/actions';

import { IParams, IProps } from './components/showProperty/property.types';

const ShowPropertyScreen: React.FC<IProps> = (props) => {
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState('');
  const { property } = props;

  useEffect(() => {
    const id = (props.match.params as IParams).id;
    props
      .fetchProperty(id)
      .then((res) => {
        setLoading(false);
        document.title = `Imóvel - ${res.type}`;
      })
      .catch(() => {
        message.error('Propriedade não encontrada');
        setRedirect('/painel/imoveis/');
      });
    return () => {
      store.dispatch({
        type: CLEAR_PROPERTY,
      });
    };
  }, []);

  return (
    <>
      <GoBack />
      {!!property && <PropertyData property={property} />}
    </>
  );
};

// Alterar a parte de Visualização da Área do Imóvel (FEITO)
// Área Privativa em cima da Área do Lote (FEITO)
// Ao lado delas Características e Confrontações. (FEITO)
// Editar (EM ANDAMENTO)
// Consertar o Bug de visualização dos Imóveis. (EM ANDAMENTO)

const mapStateToProps = (state: IReduxState) => ({
  property: state.properties.property,
});

export default connect(mapStateToProps, { fetchProperty })(ShowPropertyScreen);
