import React, { Fragment } from 'react';
import { Col, Descriptions, Row, Tag } from 'antd';
import { FaBed, FaCar, FaCouch, FaShower } from 'react-icons/fa';
import { IoIosBed } from 'react-icons/io';
import { MdKitchen } from 'react-icons/md';

import Caracteristics from 'src/components/Caracteristics/caracteristics.component';

import { ICaracteristics, IRural } from 'src/interfaces/misc';
import { Title } from './property.styles';

interface IProps {
  caracteristics?: ICaracteristics;
  extraHouses?: ICaracteristics[];
  type: string;
  rural?: IRural;
}

const CaracteristicsData: React.FC<IProps> = (props) => {
  const { caracteristics, type, extraHouses, rural } = props;
  const { Item } = Descriptions;

  return (
    <>
      <Descriptions title="Características do Imóvel" />
      {type !== 'Terreno' ? (
        <>
          <Row gutter={16}>
            {caracteristics?.rooms && caracteristics.rooms >= 0 ? (
              <Col md={4} sm={12} xs={24}>
                <Caracteristics
                  title="Quartos"
                  amount={caracteristics?.rooms}
                  icon={<FaBed />}
                />
              </Col>
            ) : null}
            {caracteristics?.suites && caracteristics.suites >= 0 ? (
              <Col md={4} sm={12} xs={24}>
                <Caracteristics
                  title="Suites"
                  amount={caracteristics?.suites}
                  icon={<IoIosBed />}
                />
              </Col>
            ) : null}
            {caracteristics?.bathrooms && caracteristics.bathrooms ? (
              <Col md={4} sm={12} xs={24}>
                <Caracteristics
                  title="Banheiros"
                  amount={caracteristics?.bathrooms}
                  icon={<FaShower />}
                />
              </Col>
            ) : null}

            {caracteristics?.garages && caracteristics.livingRooms ? (
              <Col md={4} sm={12} xs={24}>
                <Caracteristics
                  title="Salas"
                  amount={caracteristics?.livingRooms}
                  icon={<FaCouch />}
                />
              </Col>
            ) : null}
            {caracteristics?.garages && caracteristics.garages >= 0 ? (
              <Col md={4} sm={12} xs={24}>
                <Caracteristics
                  title="Garagens"
                  amount={caracteristics?.garages}
                  icon={<FaCar />}
                />
              </Col>
            ) : null}
            {caracteristics?.kitchens && caracteristics.kitchens ? (
              <Col md={4} sm={12} xs={24}>
                <Caracteristics
                  title="Cozinhas"
                  amount={caracteristics?.kitchens}
                  icon={<MdKitchen />}
                />
              </Col>
            ) : null}
          </Row>
        </>
      ) : null}

      <Descriptions
        style={{ margin: '20px 0' }}
        layout="horizontal"
        size="small"
      >
        {caracteristics?.balconies ? (
          <Item label="Varandas">{caracteristics?.balconies}</Item>
        ) : null}
        {caracteristics?.balconies ? (
          <Item label="Área Técnica">{caracteristics?.technicalAreas}</Item>
        ) : null}
        {caracteristics?.toilets ? (
          <Item label="Lavabo"> {caracteristics?.toilets} </Item>
        ) : null}
        {caracteristics?.dependencies ? (
          <Item label="Dependências">{caracteristics?.dependencies}</Item>
        ) : null}
        {rural ? (
          <>
            <Item label="Hectares">
              {rural.acres?.toLocaleString('pt-BR', {
                style: 'decimal',
                minimumFractionDigits: 2,
              })}
            </Item>
            <Item label="Bretes">{rural.bretes}</Item>
            <Item label="Currais"> {rural.corrals} </Item>
            <Item label="Embarcadouros"> {rural.docks} </Item>
            <Item label="Tronco"> {rural.trunks} </Item>
            <Item label="Aguardas"> {rural.waits} </Item>
            <Item label="Balanças"> {rural.scales} </Item>
          </>
        ) : null}
      </Descriptions>

      <span>
        Outros: - {''}
        {caracteristics?.others?.map((other) => (
          <Tag key={other} color="#f0f0f0" style={{ color: '#000' }}>
            {other}
          </Tag>
        ))}
      </span>

      {extraHouses
        ? extraHouses.map((house, index) => (
            <Fragment key={index}>
              <Title style={{ margin: '25px 0' }}>
                Casa Extra - {index + 1}
              </Title>
              <Descriptions layout="horizontal" size="small">
                <Item label="Quartos">{house.rooms}</Item>
                <Item label="Suítes">{house.suites}</Item>
                <Item label="Banheiros">{house.bathrooms}</Item>
                <Item label="Salas">{house.livingRooms}</Item>

                <Item label="Garagem">{house.garages}</Item>
                <Item label="Cozinhas">{house.kitchens}</Item>

                <Item label="Varandas">{house.balconies}</Item>
                <Item label="Área Técnica">{house.technicalAreas}</Item>
                <Item label="Lavabos"> {house.toilets} </Item>
                <Item label="Dependências"> {house.dependencies} </Item>
              </Descriptions>
            </Fragment>
          ))
        : null}
      {rural ? (
        <Descriptions title="Características Rurais">
          <Item label="Hectáres">
            {rural.acres?.toLocaleString('pt-BR', {
              style: 'decimal',
              minimumFractionDigits: 2,
            })}
          </Item>
        </Descriptions>
      ) : null}
    </>
  );
};

export default CaracteristicsData;
