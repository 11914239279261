import React from 'react';
import { Spin, Row } from 'antd';

interface IProps {
  enabled: boolean;
}

const Loading: React.FC<IProps> = (props) => {
  return (
    <Row
      align="middle"
      justify="center"
      style={{ minHeight: '50vh', display: props.enabled ? 'flex' : 'none' }}
    >
      <Spin />
    </Row>
  );
};

export default Loading;
