import { IBroker } from 'src/interfaces/broker';
import { IUser } from 'src/interfaces/user';

export const CREATE_BROKER = 'CREATE_BROKER';
export const ADMIN_CREATE_BROKER = 'ADMIN_CREATE_BROKER';
export const UPDATE_BROKER_STATUS = 'UPDATE_BROKER_STATUS';
export const UPDATE_BROKER = 'UPDATE_BROKER';
export const REMOVE_BROKER = 'REMOVE_BROKER';
export const FETCH_BROKER = 'FETCH_BROKER';
export const FETCH_BROKERS = 'FETCH_BROKERS';
export const CLEAR_BROKERS = 'CLEAR_BROKERS';
export const CLEAR_BROKER = 'CLEAR_BROKER';

export interface IReducerBrokerState {
  broker?: IUser;
  brokers?: IUser[];
}

export interface ICreateBroker {
  type: typeof CREATE_BROKER;
  payload: IUser;
}

export interface IAdminCreateBroker {
  type: typeof ADMIN_CREATE_BROKER;
  payload: IUser;
}

export interface IUpdateBrokerStatus {
  type: typeof UPDATE_BROKER_STATUS;
  payload: IBroker;
}

export interface IUpdateBroker {
  type: typeof UPDATE_BROKER;
  payload: IUser;
}

export interface IRemoveBroker {
  type: typeof REMOVE_BROKER;
}

export interface IFetchBrokers {
  type: typeof FETCH_BROKERS;
  payload: IUser[];
}

export interface IFetchBroker {
  type: typeof FETCH_BROKER;
  payload: IUser;
}

export interface IClearBroker {
  type: typeof CLEAR_BROKER;
}

export interface IClearBrokers {
  type: typeof CLEAR_BROKERS;
}

export type BrokerActionTypes =
  | ICreateBroker
  | IAdminCreateBroker
  | IUpdateBroker
  | IUpdateBrokerStatus
  | IRemoveBroker
  | IFetchBroker
  | IFetchBrokers
  | IClearBroker
  | IClearBrokers;
