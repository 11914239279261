import styled from 'styled-components';

export const Logo = styled.img`
 
`;

export const Wrapper = styled.div`
  @media(max-width: 1131px) {
    display: none;
  }
`;

export default {
  header: {
    backgroundColor: '#1e1d1f',
  },
  icon: {
    marginRight: '10px',
    paddingBottom: '0px',
    verticalAlign: 'middle',
  },
  menuTitle: {
    fontSize: '24px',
    color: 'var(--app-terciary-color)',
  },
};
