import { IReducerOwnerState, OwnerActionTypes, FETCH_OWNERS, CREATE_OWNER, CLEAR_OWNERS, REMOVE_OWNER, CLEAR_OWNER, FETCH_OWNER, UPDATE_OWNER } from './types';

import storage from 'redux-persist/es/storage';
import { persistReducer } from 'redux-persist';

const initialState: IReducerOwnerState = {
  owner: undefined,
  owners: undefined,
};

const persistConfig = {
  storage,
  key: 'owners',
  whitelist: ['owners'],
};

const reducers = (state = initialState, action: OwnerActionTypes) => {
  switch (action.type) {
    case FETCH_OWNERS:
      return {
        ...state,
        owners: action.payload,
      };
    case CREATE_OWNER:
      return {
        ...state,
        owner: action.payload,
      }
    case REMOVE_OWNER:
      return {
        ...state,
        owner: undefined,
      }
    case CLEAR_OWNER:
      return {
        ...state,
        owner: undefined,
      }
    case FETCH_OWNER:
      return {
        ...state,
        owner: action.payload,
      }

    case UPDATE_OWNER:
      return {
        ...state,
        owner: action.payload,
      }
    case CLEAR_OWNERS:
      return {
        ...state,
        owners: undefined,
      }
    default:
      return state;
  }
};

export default persistReducer(persistConfig, reducers);
