import { Descriptions, Divider } from 'antd';
import React from 'react';

import { Card } from 'src/components';
import ImagesGallery from 'src/components/Carousel/carousel.component';
import styles, { Container } from './property.styles';

import Loading from 'src/components/Loading/loading.component';
import AreaData from './area.component';
import MainData from './info.component';
import AddressData from './address.component';
import CaracteristicsData from './caracteristics.component';
import ValuesData from './values.component';
import DocumentsData from './documents.component';

import { IProperty } from 'src/interfaces/property';

export interface IProps {
  property: IProperty;
}

const PropertyData: React.FC<IProps> = (props) => {
  const { property } = props;
  const { Item } = Descriptions;
  return (
    <Card
      title="Dados do imóvel"
      subtitle="Veja aqui os dados do imóvel"
      button={{
        icon: 'file-text',
        text: 'Imprimir',
        action: () => console.log('Hello World'),
      }}
    >
      {!property.images ? (
        <Loading enabled />
      ) : (
        <ImagesGallery images={property.images} />
      )}
      <h1 style={styles.h1}>{property.type}</h1>
      <Container>
        <MainData property={property} />

        <AddressData address={property.address} />

        <CaracteristicsData
          caracteristics={property.caracteristics}
          type={property.type}
          extraHouses={property.extraHouses}
          rural={property.rural}
        />

        <ValuesData values={property.values} />

        <AreaData area={property.area} />

        <DocumentsData
          scripture={property.scripture}
          registration={property.registration}
          subscription={property.subscription}
          car={property.car}
          itr={property.itr}
          ccir={property.ccir}
        />
      </Container>
    </Card>
  );
};

export default PropertyData;
