import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
  ComboboxOptionText,
} from '@reach/combobox';
import React from 'react';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';
import '@reach/combobox/styles.css';
import { FiSearch } from 'react-icons/fi';

import { SearchContainer } from './styles';

interface IProps {
  panTo: (latLng: google.maps.LatLngLiteral) => void;
}

const Search: React.FC<IProps> = (props) => {
  const { panTo } = props;

  const location: any = {
    lat: () => -21.20044,
    lng: () => -41.89732,
  };

  const {
    ready,
    value,
    suggestions: { data, status },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location,
      radius: 100 * 1000,
    },
    debounce: 300,
  });

  const onSelect = async (address: string) => {
    setValue(address, true);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      panTo({ lat, lng });
    } catch (error) {
      throw Error(error);
    }
  };

  return (
    <SearchContainer>
      <Combobox onSelect={(address) => onSelect(address)}>
        <ComboboxInput
          value={value}
          onChange={(e) => setValue(e.target.value)}
          disabled={!ready}
          placeholder="Pesquise no mapa..."
        />
        <ComboboxPopover>
          <ComboboxList>
            {status === 'OK' &&
              data.map(({ id, description }) => (
                <ComboboxOption
                  className="options"
                  key={id}
                  value={description}
                >
                  <ComboboxOptionText />
                </ComboboxOption>
              ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    </SearchContainer>
  );
};

export default Search;
