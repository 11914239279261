import { Dispatch } from "react";
import { appointmentService } from "src/services/appointments";
import { AppointmentActionTypes, FETCH_APPOINTMENT, FETCH_APPOINTMENTS } from "./types";





export const fetchAppointments = () => (dispatch: Dispatch<AppointmentActionTypes>) => {
  return appointmentService.appointments().then((res) => {
    dispatch({
      type: FETCH_APPOINTMENTS,
      payload: res,
    })
    return res;
  }).catch((err) => {
    throw Error(err);
  });
}

export const fetchAppointment = (id: string) => (dispatch: Dispatch<AppointmentActionTypes>) => {
  return appointmentService.appointment(id).then((res) => {
    dispatch({
      type: FETCH_APPOINTMENT,
      payload: res,
    })
    return res;
  }).catch((err) => {
    throw Error(err);
  })
}

