import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { notification, Table } from 'antd';

import { Card } from 'src/components';
import { table } from 'src/utils';
import TableActions from 'src/components/tableActions/tableActions.component';
import { IReduxState } from 'src/store';
import {
  fetchBrokers,
  removeBroker,
  updateBrokerStatus,
} from 'src/store/broker/actions';

import { IProps } from './components/types/brokers.types';

const PendentBrokersScreen: React.FC<IProps> = (props) => {
  const [loading, setLoading] = useState(true);

  const pendentBrokers = props.brokers?.filter(
    (broker) => broker.broker?.status === 'pendent'
  );
  useEffect(() => {
    props.fetchBrokers();
    setLoading(false);
  }, []);

  const dataSource =
    pendentBrokers &&
    pendentBrokers.map((user) => ({
      key: user.id,

      document: user.broker?.cpf || user.broker?.cnpj,
      name: user.name || user.companyName,
      creci: user.broker?.creci,
      email: user.email,
      cityAndState: `${user.broker?.address.city}-${user.broker?.address.state}`,
      status: user.broker?.status,
      broker: user.broker?.id,
    }));

  const remove = (id: string) => {
    props
      .removeBroker(id)
      .then(() => {
        notification.success({ message: 'Corretor rejeitado.' });
      })
      .finally(() => {
        setLoading(false);
        props.fetchBrokers();
      });
  };
  const updateStatus = (id: string, newStatus: string) => {
    props
      .updateBrokerStatus(id, newStatus)
      .then((res) => {
        console.log(res);
      })
      .finally(() => {
        props.fetchBrokers();
      });
  };

  const columns = [
    {
      title: 'Nome',
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: 'CPF/CNPJ',
      key: 'document',
      dataIndex: 'document',
    },
    {
      title: 'CRECI',
      key: 'creci',
      dataIndex: 'creci',
    },
    {
      title: 'Cidade-UF',
      key: 'cityAndState',
      dataIndex: 'cityAndState',
    },
    {
      title: 'Ações',
      dataIndex: 'actions',
      key: 'actions',
      render: (text: any, record: any) => (
        <>
          <TableActions
            view={{ link: `/corretores/${record.key}` }}
            reject={{ action: () => remove(record.key) }}
            approve={{
              action: () => updateStatus(record.broker, 'approved'),
            }}
          />
        </>
      ),
    },
  ];

  return (
    <Card
      title="Corretores Pendentes"
      subtitle="Aqui estão os corretores pendentes de aprovação."
    >
      {loading ? (
        <p>Carregando...</p>
      ) : (
        <Table dataSource={dataSource} {...table} columns={columns} />
      )}
    </Card>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  brokers: state.brokers.brokers,
});

export default connect(mapStateToProps, {
  fetchBrokers,
  removeBroker,
  updateBrokerStatus,
})(PendentBrokersScreen);
