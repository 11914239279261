import React, { useState } from 'react';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  notification,
  Row,
  Select,
} from 'antd';
import InputMask from 'react-input-mask';
import Modal from 'antd/lib/modal/Modal';

import { decorator, states } from 'src/utils';
import { IMarker } from 'src/interfaces/maps';
import Map from 'src/components/Maps/map.component';
import { FaMap } from 'react-icons/fa';

interface IProps {
  loading: boolean;
  marker: IMarker | undefined;
  onChangeAddress: (name: string) => void;
}

const AddressForm: React.FC<IProps> = (props) => {
  const { Item } = Form;
  const { Option } = Select;
  const [modal, setModal] = useState<boolean>(false);

  const { onChangeAddress, marker } = props;

  const center = {
    lat: -21.20044,
    lng: -41.89732,
  };

  const openModal = () => {
    setModal(true);
  };

  return (
    <>
      <h3>Endereço do Imóvel</h3>
      <Row gutter={16}>
        <Col md={6} lg={4} xs={24} sm={24}>
          <Item
            label="CEP"
            name={['property', 'address', 'zipcode']}
            rules={decorator.zipcode}
          >
            <InputMask
              mask="99999-999"
              onChange={(event) => onChangeAddress(event.target.name)}
            >
              {(inputProps: string) => (
                <Input {...inputProps} placeholder="Insira o CEP..." />
              )}
            </InputMask>
          </Item>
        </Col>
        <Col md={18} lg={5} xs={24} sm={24}>
          <Item
            label="Estado"
            name={['property', 'address', 'state']}
            rules={decorator.state}
          >
            <Select placeholder="Insira o estado...">
              {states.map((item) => (
                <Option key={item.key} index={item.key} value={item.uf}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>
        <Col md={12} lg={5} xs={24} sm={24}>
          <Item
            label="Cidade"
            name={['property', 'address', 'city']}
            rules={decorator.city}
          >
            <Input
              placeholder="Insira a cidade..."
              onChange={(event) => onChangeAddress(event.target.name)}
            />
          </Item>
        </Col>
        <Col md={12} lg={5} xs={24} sm={24}>
          <Item
            label="Bairro"
            name={['property', 'address', 'neighborhood']}
            rules={decorator.neighborhood}
          >
            <Input
              placeholder="Insira o bairro..."
              onChange={(event) => onChangeAddress(event.target.name)}
            />
          </Item>
        </Col>
        <Col md={24} lg={5} xs={24} sm={24}>
          <Item
            label="Rua"
            name={['property', 'address', 'street']}
            rules={decorator.street}
          >
            <Input
              placeholder="Insira a rua..."
              onChange={(event) => onChangeAddress(event.target.name)}
            />
          </Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={4} lg={4} xs={24} sm={24}>
          <Item
            label="Número"
            name={['property', 'address', 'number']}
            rules={decorator.streetNumber}
          >
            <Input
              placeholder="Insira o número..."
              onChange={(event) => onChangeAddress(event.target.name)}
            />
          </Item>
        </Col>
        <Col md={10} lg={10} xs={24} sm={24}>
          <Item
            label="Condomínio/Edifício"
            name={['property', 'address', 'complex']}
          >
            <Input placeholder="Insira o referencial do condomínio/edifício..." />
          </Item>
        </Col>
        <Col md={10} lg={10} xs={24} sm={24}>
          <Item
            label="Complemento"
            name={['property', 'address', 'complement']}
          >
            <Input placeholder="Insira o complemento do endereço..." />
          </Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={7} lg={4} xs={24} sm={24}>
          <Item label="Latitude" name={['property', 'address', 'latitude']}>
            <Input placeholder="Insira a latitude..." />
          </Item>
        </Col>

        <Col md={7} lg={4} xs={24} sm={24}>
          <Item label="Longitude" name={['property', 'address', 'longitude']}>
            <Input placeholder="Insira a longitude..." />
          </Item>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginBottom: '20PX' }}>
        <Col md={6} lg={4} xs={24} sm={24}>
          <Button className="btn-showMap" onClick={openModal} icon={<FaMap />}>
            Abrir o Mapa
          </Button>
        </Col>
      </Row>

      <Modal
        style={{
          top: 10,
        }}
        onCancel={() => setModal(false)}
        visible={modal}
        closable={false}
        width="100%"
        footer={
          <Button key="back" onClick={() => setModal(false)}>
            Sair
          </Button>
        }
      >
        <Map center={center} marker={marker} />
      </Modal>
      <Divider />
    </>
  );
};

export default AddressForm;
