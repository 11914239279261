import React, { useEffect, useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import { EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, message, Modal, Row } from 'antd';
import Upload, { RcFile, UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';

import { Card } from 'src/components';
import { IRemoveFileInput } from 'src/interfaces/property';
import { store } from 'src/store';
import { STORE_IMAGES } from 'src/store/properties/types';

import { ImageContainer, Actions, Image, ImageMask } from './update.styles';
import { IImage } from 'src/interfaces/misc';

interface IProps {
  images: IImage[];
  id: string;
  removeFile: (input: IRemoveFileInput) => Promise<boolean>;
}

const PropertyImagesForm: React.FC<IProps> = (props) => {
  const { images, id, removeFile } = props;
  const [imagesFiles, setImagesFiles] = useState<UploadFile<any>[]>();
  const [previewImage, setPreviewImage] = useState('');
  const [uploadedImage, setUploadedImage] = useState('');
  const [previewVisible, setPreviewVisible] = useState(false);
  const [visible, setVisible] = useState(false);
  const [previewImageTitle, setPreviewImageTitle] = useState('');

  useEffect(() => {
    return () => {
      store.dispatch({
        type: STORE_IMAGES,
        payload: undefined,
      });
    };
  }, []);

  const beforeUpload = (file: RcFile) => {
    return false;
  };

  const getBase64 = (file: File) => {
    return new Promise<any>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file!);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const onChangeImages = ({ fileList }: UploadChangeParam) => {
    setImagesFiles(fileList);
    const arrayFiles: any = [];
    fileList.forEach((file) => {
      arrayFiles.push(file.originFileObj);
    });
    store.dispatch({
      type: STORE_IMAGES,
      payload: arrayFiles,
    });
  };

  const onRemoveFile = (input: IRemoveFileInput) => {
    removeFile(input)
      .then((res) => {
        message.success('Arquivo Removido com sucesso!');
      })
      .catch((err) => {
        message.error('Arquivo não encontrado.');
      });
  };
  const showModal = async (image: string) => {
    setVisible(true);
    setUploadedImage(image);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const handleModalCancel = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    return setPreviewVisible(false);
  };

  const onPreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
      setPreviewImage(file.url || file.preview);
    }
    setPreviewVisible(true);
    setPreviewImageTitle(
      file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    );
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 0 }}>Adicionar Imagens</div>
    </div>
  );

  return (
    <Card
      title="Imagens do Imóvel"
      subtitle="Altere abaixo as imagens do imóvel"
    >
      <Row>
        <Col md={24}>
          <Form.Item label="Imagens" name="images" required={!images}>
            <Upload
              onChange={onChangeImages}
              multiple
              fileList={imagesFiles}
              listType="picture-card"
              onPreview={onPreview}
              beforeUpload={beforeUpload}
              accept=".png,.jpg,.jpeg,.webp,.raw,.tiff,.svg"
            >
              {uploadButton}
            </Upload>
          </Form.Item>
          <Modal
            visible={previewVisible}
            title={previewImageTitle}
            footer={null}
            onCancel={handleCancel}
          >
            <img src={previewImage} alt="Imagens" style={{ width: '100%' }} />
          </Modal>
        </Col>
      </Row>
      <Divider />
      <ImageContainer>
        {images.map((image) => (
          <ImageMask key={image.path}>
            <Image>
              <img src={image.path} />
            </Image>

            <Actions className="Actions">
              <Button
                className="view-button"
                onClick={() => showModal(image.path)}
                icon={<EyeOutlined />}
              >
                {''}
              </Button>

              <Modal
                title="Imagens"
                visible={visible}
                onOk={handleOk}
                onCancel={handleModalCancel}
                footer={null}
              >
                <img src={uploadedImage} alt="" style={{ width: '100%' }} />
              </Modal>

              <Button
                className="delete-button"
                icon={<FeatherIcon icon="trash" />}
                onClick={() =>
                  onRemoveFile({
                    filePath: image.path,
                    propertyId: id,
                  })
                }
              >
                {''}
              </Button>
            </Actions>
          </ImageMask>
        ))}
      </ImageContainer>
    </Card>
  );
};

export default PropertyImagesForm;

// Alterar a parte de cadastro para o admin, prevenindo possíveis golpes no sistema atrávés dos termos de uso.
