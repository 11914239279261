import { Col, Form, Input, Row, Select } from 'antd';
import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import { Card } from 'src/components';
import { IUser } from 'src/interfaces/user';
import { decorator, states } from 'src/utils';

interface IProps {
  broker?: IUser;
}

const CompanyUpdateForm: React.FC<IProps> = (props) => {
  const { broker } = props;
  const { Option } = Select;
  const [state, setState] = useState(broker?.broker?.address.state);

  const handleState = (e: any, option: any) => {
    setState(e);
  };

  return (
    <Card
      title="Atualizar Corretor(a)"
      subtitle="Altere os dados abaixo do(a) corretor(a)"
    >
      <Row gutter={16}>
        <Col md={12}>
          <Form.Item
            label="Nome da empresa"
            name="companyName"
            initialValue={broker?.companyName}
            rules={decorator.companyName}
          >
            <Input placeholder="Insira o nome da empresa..." />
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item
            label="E-mail"
            name="email"
            initialValue={broker?.email}
            rules={decorator.email}
          >
            <Input placeholder="Insira o e-mail da empresa..." />
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item
            label="Telefone"
            name="phone"
            initialValue={broker?.phone}
            rules={decorator.phone}
          >
            <InputMask mask="99 99999-9999">
              {(inputProps: string) => (
                <Input
                  {...inputProps}
                  placeholder="Insira o número de contato"
                />
              )}
            </InputMask>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={6}>
          <Form.Item
            label="Estado"
            name={['address', 'state']}
            initialValue={broker?.broker?.address.state}
            rules={decorator.state}
          >
            <Select onChange={handleState}>
              {states.map((item) => (
                <Option key={item.key} value={item.uf}>
                  {item.uf}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item
            name="creci"
            label="CRECI"
            initialValue={broker?.broker?.creci}
            rules={decorator.creci}
          >
            <InputMask mask={`${state}-999999/J`}>
              {(inputProps: string) => (
                <Input
                  {...inputProps}
                  placeholder="Insira o CRECI da empresa..."
                />
              )}
            </InputMask>
          </Form.Item>
        </Col>

        <Col md={6}>
          <Form.Item
            name="cnpj"
            label="CNPJ"
            rules={decorator.cnpj}
            initialValue={broker?.broker?.cnpj}
          >
            <InputMask mask="99.999.999/9999-99">
              {(inputProps: string) => (
                <Input
                  {...inputProps}
                  placeholder="Insira o CNPJ da empresa..."
                />
              )}
            </InputMask>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={6}>
          <Form.Item
            name={['address', 'zipcode']}
            initialValue={broker?.broker?.address.zipcode}
            label="CEP"
            rules={decorator.zipcode}
          >
            <InputMask mask="99999-999">
              {(inputProps: string) => (
                <Input {...inputProps} placeholder="Insira o CEP..." />
              )}
            </InputMask>
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item
            name={['address', 'city']}
            label="Cidade"
            rules={decorator.city}
            initialValue={broker?.broker?.address.city}
          >
            <Input placeholder="Insira a cidade da empresa..." />
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item
            name={['address', 'neighborhood']}
            label="Bairro"
            rules={decorator.neighborhood}
            initialValue={broker?.broker?.address.neighborhood}
          >
            <Input placeholder="Insira o bairro da empresa..." />
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item
            name={['address', 'street']}
            label="Rua"
            rules={decorator.street}
            initialValue={broker?.broker?.address.street}
          >
            <Input placeholder="Insira a rua da empresa..." />
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item
            name={['address', 'number']}
            label="Número"
            rules={decorator.streetNumber}
            initialValue={broker?.broker?.address.number}
          >
            <Input placeholder="Insira o número..." />
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item
            name={['address', 'complement']}
            label="Complemento"
            rules={decorator.complement}
            initialValue={broker?.broker?.address.complement}
          >
            <Input placeholder="(Opcional) Insira um complemento" />
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item label="Senha" name="password">
            <Input
              type="password"
              placeholder="Insira a senha do corretor..."
            />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

export default CompanyUpdateForm;
