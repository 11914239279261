import styled from 'styled-components';


export const MenuContainer = styled.div`
  width: 60px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  align-items: center;
  transition: 0.5s all;
  @media(max-width: 540px) {
    display: none;
  }
  span {
    transition: 0.5s all;
    font-size: 1.7em;
    color: var(--app-terciary-color);
    cursor: pointer; 
  }
`;

export const DrawerContainer = styled.div`
  float: left;
  padding: 10px;
  margin-right: 10px;
  transition: 0.5s all;
  @media(min-width: 1130px) {
    display: none;
  }
  span {
    transition: 0.5s all;
    font-size: 1.7em;
    color: var(--app-terciary-color);
    cursor: pointer; 
  }
`;

export const LogoContainer = styled.div`
  float: left;
  img {
    width: 100px;
    padding: 3px;
    object-fit: contain;
    position: relative;
    
  }
  a {
    color: var(--app-terciary-color);
  }
`;

export const LogoUnauthenticated = styled.div`
  width: 100%;
  text-align: center;
  img {
    width: 127px;
    top: 2px;
    position: relative;
  }
  
  a {
    color: var(--app-terciary-color);
  }
`;
