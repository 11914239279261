import React, { useEffect, useState } from 'react';
import { message, Table } from 'antd';
import { connect } from 'react-redux';

import { Card } from 'src/components';
import { table } from 'src/utils';
import TableActions from 'src/components/tableActions/tableActions.component';
import { IReduxState } from 'src/store';

import { fetchProperties, removeProperty } from 'src/store/properties/actions';
import { IProps } from './properties.types';

const PropertiesScreen: React.FC<IProps> = (props) => {
  const [loading, setLoading] = useState(true);
  const { properties } = props;

  const approvedProperties = properties?.filter(
    (property) => property.status === 'approved'
  );

  useEffect(() => {
    props.fetchProperties().catch((err) => {
      console.log(err);
    });
    setLoading(false);
  }, []);

  const remove = (id: string) => {
    props.removeProperty(id).then(() => {
      setLoading(true);
      props.fetchProperties();
      message.info('Imóvel Removido.');
      setLoading(false);
    });
  };

  const dataSource = approvedProperties?.map((property) => ({
    key: property.id,
    salePrice:
      property.values.salePrice?.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }) || 'Não Informado.',
    rentPrice:
      property.values.rentPrice?.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }) || 'Não Informado.',
    type: property.type,
    subtype: property.subtype,
    zone: property.zone,
  }));

  const columns = [
    {
      title: 'Preço de Venda',
      key: 'salePrice',
      dataIndex: 'salePrice',
    },
    {
      title: 'Preço de Locação',
      key: 'rentPrice',
      dataIndex: 'rentPrice',
    },
    {
      title: 'Zona',
      key: 'zone',
      dataIndex: 'zone',
    },
    {
      title: 'Tipo',
      key: 'type',
      dataIndex: 'type',
    },
    {
      title: 'Subtípo',
      key: 'subtype',
      dataIndex: 'subtype',
    },
    {
      title: 'Ações',
      key: 'actions',
      dataIndex: 'actions',
      render: (text: any, record: any) => (
        <TableActions
          edit={{ link: `/painel/imoveis/editar/${record.key}` }}
          view={{ link: `/painel/imoveis/${record.key}` }}
          remove={{ action: () => remove(record.key) }}
          loading={loading}
        />
      ),
    },
  ];

  return (
    <Card
      title="Imóveis"
      subtitle="Veja aqui as imóveis cadastradas no sistema."
    >
      {!!loading ? (
        <p>Carregando...</p>
      ) : (
        <Table dataSource={dataSource} {...table} columns={columns} />
      )}
    </Card>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  properties: state.properties.properties,
});

export default connect(mapStateToProps, { fetchProperties, removeProperty })(
  PropertiesScreen
);
