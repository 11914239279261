import styled from 'styled-components';


export const LogoTitle = styled.div`
  width: 100%;
  display: flex;
  padding-top: 10px;
  align-items: center;
  justify-content: center;

  img {
    width: 120px;
  }
`;

export const DrawerBody = styled.div`

  ul {
    li {
      
      display: flex;
      align-items: center;
      text-align: center;
      svg {
        font-size: 16px !important;
      }
      span {
        padding-left: 10px;
      }
    }
  }

`;