interface ITable {
  bordered: boolean;
  locale: any;
  size: 'small';
  color: 'black';
}

const table: ITable = {
  locale: {
    emptyText: 'Nenhum dado a ser exibido.',
  },
  size: 'small',
  bordered: true,
  color: 'black',
};

export default table;
