import React from 'react';

import { Wrapper, Text } from './styles';

const FooterComponent: React.FC = () => {
  return (
    <Wrapper>
      <Text>
        &copy; {new Date().getFullYear()} Embrane - Todos os direitos
        reservados.
      </Text>
    </Wrapper>
  );
};

export default FooterComponent;
