import { Col, Form, Input, Row, Select } from 'antd';
import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import { Card } from 'src/components';

import { IUser } from 'src/interfaces/user';
import { decorator, states } from 'src/utils';

export interface IProps {
  owner: IUser;
}

const CompanyUpdateForm: React.FC<IProps> = (props) => {
  const [phone, setPhone] = useState('');
  const [cep, setCep] = useState('');
  const { Option } = Select;
  const { owner } = props;

  const handleCep = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (value.replace(/ /g, '').length === 9) {
      setCep(value);
    }
  };

  const handlePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (value.replace(/ /g, '').length === 14) {
      setPhone('(99)99999-9999');
    }
  };

  return (
    <Card
      title="Atualizar Proprietário(a)"
      subtitle="Altere os dados do(a) proprietário(a) abaixo."
    >
      <Row gutter={16}>
        <Col md={12}>
          <Form.Item
            name="companyName"
            label="Nome da empresa"
            rules={decorator.companyName}
            initialValue={owner.companyName}
          >
            <Input placeholder="Insira o nome da empresa..." />
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item
            rules={decorator.email}
            name="email"
            label="E-mail"
            initialValue={owner.email}
          >
            <Input placeholder="Insira o e-mail da empresa..." />
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item
            rules={decorator.phone}
            name="phone"
            label="Telefone"
            initialValue={owner.phone}
          >
            <InputMask
              mask="99 99999-9999"
              value={phone}
              onChange={handlePhone}
              maskChar=""
            >
              {(inputProps: string) => (
                <Input
                  {...inputProps}
                  placeholder="Insira o telefone da empresa..."
                />
              )}
            </InputMask>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={12}>
          <Form.Item
            name="cnpj"
            label="CNPJ"
            rules={decorator.cnpj}
            initialValue={owner.owner?.cnpj}
          >
            <InputMask maskChar="" mask="99.999.999/9999-99">
              {(inputProps: string) => (
                <Input
                  {...inputProps}
                  placeholder="Insira o CNPJ da empresa..."
                />
              )}
            </InputMask>
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item
            label="Profissão"
            name="profession"
            initialValue={owner.owner?.profession}
          >
            <Input placeholder="Insira a profissão da empresa" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col md={2}>
          <Form.Item
            rules={decorator.state}
            name={['address', 'state']}
            label="Estado"
            initialValue={owner.owner?.address.state}
          >
            <Select placeholder="">
              {states.map((item) => (
                <Option key={item.key} value={item.uf}>
                  {item.uf}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col md={4}>
          <Form.Item
            name={['address', 'zipcode']}
            label="CEP"
            rules={decorator.zipcode}
            initialValue={owner.owner?.address.zipcode}
          >
            <InputMask
              maskChar=""
              mask="99999-999"
              value={cep}
              onChange={handleCep}
            >
              {(inputProps: string) => (
                <Input {...inputProps} placeholder="Insira o CEP..." />
              )}
            </InputMask>
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item
            name={['address', 'city']}
            label="Cidade"
            rules={decorator.city}
            initialValue={owner.owner?.address.city}
          >
            <Input placeholder="Insira a cidade da empresa..." />
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item
            name={['address', 'neighborhood']}
            rules={decorator.neighborhood}
            label="Bairro"
            initialValue={owner.owner?.address.neighborhood}
          >
            <Input placeholder="Insira o bairro da empresa..." />
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item
            name={['address', 'street']}
            label="Rua"
            rules={decorator.street}
            initialValue={owner.owner?.address.street}
          >
            <Input placeholder="Insira a rua da empresa..." />
          </Form.Item>
        </Col>
        <Col md={4}>
          <Form.Item
            name={['address', 'number']}
            rules={decorator.streetNumber}
            label="Número"
            initialValue={owner.owner?.address.number}
          >
            <Input placeholder="Insira o número..." />
          </Form.Item>
        </Col>
        <Col md={8}>
          <Form.Item
            name={['address', 'complement']}
            label="Complemento"
            rules={decorator.complement}
            initialValue={owner.owner?.address.complement}
          >
            <Input placeholder="(Opcional) Insira um complemento..." />
          </Form.Item>
        </Col>

        <Col md={12}>
          <Form.Item name="password" label="Senha" rules={decorator.password}>
            <Input
              type="password"
              placeholder="Insira a senha do proprietário..."
            />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

export default CompanyUpdateForm;
