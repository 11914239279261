import { Dispatch } from 'react';

import { IUser, ILogin, IAdmin } from 'src/interfaces/user';
import { history } from 'src/screens/app/app.component';

import { SIGN_IN, LOGOUT_USER, AuthActionTypes } from './types';
import AdminServices from 'src/services/admin';
import { forms } from 'src/utils';

export const adminLogin = (input: ILogin) => (
  dispatch: Dispatch<AuthActionTypes>
) => {
  return AdminServices.adminLogin(input)
    .then((res) => {
      dispatch({
        type: SIGN_IN,
        payload: res,
      });
      return res;
    })
    .catch((err) => {
      forms.errors(err);
      throw Error;
    });
};
