const subtypes1 = [
  { id: 'Novo' },
  { id: 'Lançamento' },
  { id: 'Breve Lançamento' },
  { id: 'Em Construção' },
  { id: 'Na planta' },
  { id: 'Reformado' },
  { id: '1º Utilização' },
];

const subtypes2 = [
  { id: 'Murado' },
  { id: 'Cercado' },
  { id: 'Topografia Alta' },
  { id: 'Topografia Baixa' },
  { id: 'Linear' },
  { id: 'Benfeitorias' },
  { id: ' Sem Benfeitorias' },
];

const subtypes3 = [{ id: 'Em Funcionamento' }, { id: 'Sem Atividades' }];

const propertyTypes = [
  { label: 'Apartamento' },
  { label: 'Apartamento Duplex' },
  { label: 'Apartamento Triplex' },
  { label: 'Cobertura Linear' },
  { label: 'Cobertura Duplex' },
  { label: 'Cobertura Triplex' },
  { label: 'Casa Linear' },
  { label: 'Casa Duplex' },
  { label: 'Casa Triplex' },
  { label: 'Terreno' },
  { label: 'Sítio' },
  { label: 'Fazenda' },
  { label: 'Pousada' },
  { label: 'Garagem' },
  { label: 'Loja Linear' },
  { label: 'Loja Duplex' },
  { label: 'Loja Triplex' },
  { label: 'Prédio' },
  { label: 'Flat' },
  { label: 'Área Comercial' },
  { label: 'Área Industrial' },
  { label: 'Condomínio' },
  { label: 'Industria' },
  { label: 'Ilha' },
  { label: 'Hotel' },
  { label: 'Loft' },
];

const types: any = {
  Apartamento: subtypes1,
  'Apartamento Duplex': subtypes1,
  'Apartamento Triplex': subtypes1,
  'Cobertura Linear': subtypes1,
  'Cobertura Duplex': subtypes1,
  'Cobertura Triplex': subtypes1,
  'Casa Linear': subtypes1,
  'Casa Duplex': subtypes1,
  'Casa Triplex': subtypes1,
  Terreno: subtypes2,
  Sítio: subtypes2,
  Fazenda: subtypes2,
  Pousada: subtypes1,
  Garagem: subtypes1,
  'Loja Linear': subtypes1,
  'Loja Duplex': subtypes1,
  'Loja Triplex': subtypes1,
  Prédio: subtypes1,
  Flat: subtypes1,
  'Área Comercial': subtypes2,
  'Área Industrial': subtypes2,
  Condomínio: subtypes2,
  Industria: subtypes3,
  Ilha: subtypes2,
  Hotel: subtypes3,
  Loft: subtypes1,
};

export default {
  types,
  propertyTypes,
};
