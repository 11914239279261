import React from 'react';
import { Col, Descriptions, Row } from 'antd';

import styles, {
  AreaContainer,
  Confrontations,
  ConfrontationsTitle,
} from './property.styles';
import { IArea } from 'src/interfaces/misc';

export interface IProps {
  area: IArea;
}

const AreaData: React.FC<IProps> = (props) => {
  const { Item } = Descriptions;
  const { area } = props;

  return (
    <>
      {area ? (
        <>
          <h1 style={styles.title}>Área do Imóvel</h1>
          <Row>
            <Col md={12} xs={24} sm={24}>
              <AreaContainer>
                {area.privateArea ? (
                  <span>
                    Área Privativa:{' '}
                    {area.privateArea.toLocaleString('pt-BR', {
                      style: 'decimal',
                      minimumFractionDigits: 2,
                    })}{' '}
                    m³
                  </span>
                ) : null}

                {area.lotArea ? (
                  <span>
                    Área do Lote: {''}
                    {area.lotArea.toLocaleString('pt-BR', {
                      style: 'decimal',
                      minimumFractionDigits: 2,
                    })}
                    {''} m²
                  </span>
                ) : null}
              </AreaContainer>
            </Col>
            <Col md={12} xs={24}>
              <Confrontations>
                <ConfrontationsTitle>
                  Características e Confrontações
                </ConfrontationsTitle>
                {area.frontLot ? (
                  <span>
                    Frente: {''}
                    {area.frontLot.toLocaleString('pt-BR', {
                      style: 'decimal',
                      minimumFractionDigits: 2,
                    })}
                    {''}m
                  </span>
                ) : null}
                {area.leftLot ? (
                  <span>
                    Lote Direito:{' '}
                    {area.rightLot?.toLocaleString('pt-BR', {
                      style: 'decimal',
                      minimumFractionDigits: 2,
                    })}
                    {''} m
                  </span>
                ) : null}
                {area.rightLot ? (
                  <span>
                    Lote Esquerdo: {''}{' '}
                    {area.rightLot?.toLocaleString('pt-BR', {
                      style: 'decimal',
                      minimumFractionDigits: 2,
                    })}
                    {''} m
                  </span>
                ) : null}
                {area.backyard ? (
                  <span>
                    Fundos:{' '}
                    {area.backyard.toLocaleString('pt-BR', {
                      style: 'decimal',
                      minimumFractionDigits: 2,
                    })}{' '}
                    m
                  </span>
                ) : null}
              </Confrontations>
            </Col>
          </Row>
        </>
      ) : null}
    </>
  );
};

export default AreaData;
