import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  notification,
  Row,
  Select,
  Tooltip,
} from 'antd';

import { QuestionCircleOutlined } from '@ant-design/icons';
import { FaMap } from 'react-icons/fa';
import InputMask from 'react-input-mask';

import { decorator, states } from 'src/utils';
import Map from 'src/components/Maps/map.component';
import Modal from 'antd/lib/modal/Modal';
import { getGeocode, getLatLng } from 'use-places-autocomplete';
import Geocode from 'react-geocode';
import { IAddress } from 'src/interfaces/misc';
import { IMarker } from 'src/interfaces/maps';

interface IProps {
  onChange: (name: string, value: string) => void;
  marker: IMarker | undefined;
}

const AddressForm: React.FC<IProps> = (props) => {
  const { Item } = Form;
  const { Option } = Select;

  const center = {
    lat: -21.20044,
    lng: -41.89732,
  };

  const { onChange, marker } = props;
  const [modal, setModal] = useState<boolean>(false);

  const openModal = () => {
    setModal(true);
  };

  return (
    <>
      <Tooltip
        placement="right"
        title="Preencha o endereço do imóvel e depois visualize no mapa para confirmar a localização."
      >
        <span
          style={{
            color: 'rgba(0, 0, 0, 0.85)',
            fontWeight: 500,
            fontSize: '1.17em',
          }}
        >
          Endereço do Imóvel{' '}
          <QuestionCircleOutlined
            style={{ fontSize: '14px', marginBottom: '10px' }}
          />
        </span>
      </Tooltip>

      <Modal
        style={{
          top: 10,
        }}
        onCancel={() => setModal(false)}
        visible={modal}
        closable={false}
        width="100%"
        footer={
          <Button key="back" onClick={() => setModal(false)}>
            Sair
          </Button>
        }
      >
        <Map center={center} marker={marker} />
      </Modal>

      <Row gutter={16}>
        <Col md={6} lg={4} xs={24} sm={24}>
          <Item
            label="CEP"
            name={['address', 'zipcode']}
            rules={decorator.zipcode}
          >
            <InputMask
              mask="99999-999"
              name="zipcode"
              onChange={(event) =>
                onChange(event.target.name, event.target.value)
              }
            >
              {(inputProps: string) => (
                <Input {...inputProps} placeholder="Insira o CEP..." />
              )}
            </InputMask>
          </Item>
        </Col>
        <Col md={18} lg={5} xs={24} sm={24}>
          <Item
            label="Estado"
            name={['address', 'state']}
            rules={decorator.state}
          >
            <Select placeholder="Insira o estado...">
              {states.map((item) => (
                <Option key={item.key} index={item.key} value={item.uf}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>
        <Col md={12} lg={5} xs={24} sm={24}>
          <Item
            label="Cidade"
            name={['address', 'city']}
            rules={decorator.city}
          >
            <Input
              placeholder="Insira a cidade..."
              name="city"
              onChange={(event) =>
                onChange(event.target.name, event.target.value)
              }
            />
          </Item>
        </Col>
        <Col md={12} lg={5} xs={24} sm={24}>
          <Item
            label="Bairro"
            name={['address', 'neighborhood']}
            rules={decorator.neighborhood}
          >
            <Input
              placeholder="Insira o bairro..."
              name="neighborhood"
              onChange={(event) =>
                onChange(event.target.name, event.target.value)
              }
            />
          </Item>
        </Col>
        <Col md={24} lg={5} xs={24} sm={24}>
          <Item
            label="Rua"
            name={['address', 'street']}
            rules={decorator.street}
          >
            <Input
              placeholder="Insira a rua..."
              name="street"
              onChange={(event) =>
                onChange(event.target.name, event.target.value)
              }
            />
          </Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={4} lg={4} xs={24} sm={24}>
          <Item
            label="Número"
            name={['address', 'number']}
            rules={decorator.streetNumber}
          >
            <Input
              placeholder="Insira o número..."
              name="number"
              onChange={(event) =>
                onChange(event.target.name, event.target.value)
              }
            />
          </Item>
        </Col>
        <Col md={10} lg={10} xs={24} sm={24}>
          <Item label="Condomínio/Edifício" name={['address', 'complex']}>
            <Input placeholder="Insira o referencial do condomínio/edifício..." />
          </Item>
        </Col>
        <Col md={10} lg={10} xs={24} sm={24}>
          <Item label="Complemento" name={['address', 'complement']}>
            <Input placeholder="Insira o complemento do endereço..." />
          </Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={7} lg={4} xs={24} sm={24}>
          <Item label="Latitude" name={['address', 'latitude']}>
            <Input placeholder="Insira a latitude..." />
          </Item>
        </Col>

        <Col md={7} lg={4} xs={24} sm={24}>
          <Item label="Longitude" name={['address', 'longitude']}>
            <Input placeholder="Insira a longitude..." />
          </Item>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginBottom: '20px' }}>
        <Col md={6} lg={4} xs={24} sm={24}>
          <Button className="btn-showMap" onClick={openModal} icon={<FaMap />}>
            Abrir o Mapa
          </Button>
        </Col>
      </Row>

      <Divider />
    </>
  );
};

export default AddressForm;
