import { Col, Descriptions } from 'antd';
import React from 'react';
import { IValues } from 'src/interfaces/misc';

interface IProps {
  values: IValues;
  bank?: string;
  overbalance?: string;
}

const ValuesData: React.FC<IProps> = (props) => {
  const { values, bank, overbalance } = props;
  const { Item } = Descriptions;
  return (
    <Descriptions
      title="Valores e taxas do Imóvel"
      style={{ margin: '35px 0' }}
      layout="horizontal"
      size="small"
    >
      <Item label="Preço de venda">
        {values.salePrice?.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })}
      </Item>
      <Item label="IPTU Anual">
        {values.annualIptu?.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })}
      </Item>
      {values.communitySalePrice ? (
        <Item label="Taxa de venda do Condomínio">
          {values.communitySalePrice.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }) || 'Não Possuí.'}
        </Item>
      ) : null}

      {values.saleExtraTaxes ? (
        <Item label="Taxas de Venda">
          {values.saleExtraTaxes.toLocaleString('pt-BR', {
            currency: 'BRL',
            style: 'currency',
          }) || 'Não Possuí.'}
        </Item>
      ) : null}
      {values.rentPrice ? (
        <Item label="Preço de Locação">
          {values.rentPrice?.toLocaleString('pt-BR', {
            currency: 'BRL',
            style: 'currency',
          }) || 'Não Possuí.'}
        </Item>
      ) : null}
      {values.monthlyIptu ? (
        <Item label="IPTU Mensal">
          {values.monthlyIptu?.toLocaleString('pt-BR', {
            currency: 'BRL',
            style: 'currency',
          })}
        </Item>
      ) : null}
      {values.communityRentPrice ? (
        <Item label="Valor de locação do Condomínio">
          {values.communityRentPrice.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }) || 'Não Possuí.'}
        </Item>
      ) : null}
      {values.rentExtraTaxes ? (
        <Item label="Taxas de Locação">
          {values.rentExtraTaxes.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })}
        </Item>
      ) : null}
      {bank ? <Item label="Banco">{bank}</Item> : null}
      {overbalance ? <Item label="Saldo Devedor">{overbalance}</Item> : null}
    </Descriptions>
  );
};

export default ValuesData;
