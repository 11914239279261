import { combineReducers, Reducer } from 'redux';
import storage from 'redux-persist/lib/storage';

import { LOGOUT_USER } from './auth/types';
import configReducer from './config/reducers';
import authReducer from './auth/reducers';
import brokersReducer from './broker/reducers';
import ownersReducer from './owners/reducers';
import propertiesReducer from './properties/reducers';
import appointmentsReducer from './appointments/reducer';

export default combineReducers({
  config: configReducer,
  auth: authReducer,
  brokers: brokersReducer,
  appointments: appointmentsReducer,
  owners: ownersReducer,
  properties: propertiesReducer,
});