import { Button, Col, Form, Input, InputNumber, Row, Select } from 'antd';
import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import { Card } from 'src/components';
import { ICaracteristics } from 'src/interfaces/misc';
import { decorator, others } from 'src/utils';

interface IProps {
  caracteristics?: ICaracteristics;
}

const PropertyDataForm: React.FC<IProps> = (props) => {
  const { caracteristics } = props;
  const { Option } = Select;

  return (
    <Card
      title="Características do Imóvel"
      subtitle="Altere abaixo as características do imóvel."
    >
      <Row gutter={8}>
        <Col md={4} xs={24}>
          <Form.Item
            label="Quartos"
            name={['caracteristics', 'rooms']}
            initialValue={caracteristics?.rooms}
          >
            <InputNumber />
          </Form.Item>
        </Col>
        <Col md={4} xs={24}>
          <Form.Item
            label="Suítes"
            name={['caracteristics', 'suites']}
            initialValue={caracteristics?.suites}
          >
            <InputNumber />
          </Form.Item>
        </Col>
        <Col md={4} xs={24}>
          <Form.Item
            label="Salas"
            name={['caracteristics', 'livingRooms']}
            initialValue={caracteristics?.livingRooms}
          >
            <InputNumber />
          </Form.Item>
        </Col>
        <Col md={4} xs={24}>
          <Form.Item
            label="Banheiros"
            name={['caracteristics', 'bathrooms']}
            initialValue={caracteristics?.bathrooms}
          >
            <InputNumber />
          </Form.Item>
        </Col>
        <Col md={4} xs={24}>
          <Form.Item
            label="Varandas"
            name={['caracteristics', 'balconies']}
            initialValue={caracteristics?.balconies}
          >
            <InputNumber />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col md={4} xs={24}>
          <Form.Item
            label="Cozinhas"
            name={['caracteristics', 'kitchens']}
            initialValue={caracteristics?.kitchens}
          >
            <InputNumber />
          </Form.Item>
        </Col>
        <Col md={4} xs={24}>
          <Form.Item
            label="Lavabos"
            name={['caracteristics', 'toilets']}
            initialValue={caracteristics?.toilets}
          >
            <InputNumber />
          </Form.Item>
        </Col>
        <Col md={4} xs={24}>
          <Form.Item
            label="Dependências"
            name={['caracteristics', 'dependencies']}
            initialValue={caracteristics?.dependencies}
          >
            <InputNumber />
          </Form.Item>
        </Col>
        <Col md={4} xs={24}>
          <Form.Item
            label="Área Técnica"
            name={['caracteristics', 'technicalAreas']}
            initialValue={caracteristics?.technicalAreas}
          >
            <InputNumber />
          </Form.Item>
        </Col>
        <Col md={4} xs={24}>
          <Form.Item
            label="Garagens"
            name={['caracteristics', 'garages']}
            initialValue={caracteristics?.garages}
          >
            <InputNumber />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col md={24} xs={24}>
          <Form.Item
            label="Outras características"
            name={['caracteristics', 'others']}
            initialValue={caracteristics?.others}
          >
            <Select
              mode="multiple"
              allowClear
              placeholder="Selecione outras características"
            >
              {others.map((other) => (
                <Option key={other.id} value={other.name}>
                  {other.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col md={24} xs={24}>
          <Form.Item label="Descrição">
            <Input.TextArea rows={4} />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

export default PropertyDataForm;
