import { Form, Input, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import packageJson from '../../../package.json';
import { Redirect } from 'react-router-dom';

import GoBack from 'src/components/GoBack/GoBack';
import { IReduxState, store } from 'src/store';
import { fetchBroker, updateBroker } from 'src/store/broker/actions';
import { CLEAR_BROKER } from 'src/store/broker/types';

import { IParams, IProps } from './components/types/updateBroker.types';
import PersonUpdateForm from './components/editBroker/person.update.form';
import { FormFinish } from 'src/components';
import Loading from 'src/components/Loading/loading.component';
import CompanyUpdateForm from './components/editBroker/company.update.form';

const UpdateBrokerScreen: React.FC<IProps> = (props) => {
  const [redirect, setRedirect] = useState('');
  const [loading, setLoading] = useState(true);
  const [sending, setSending] = useState(false);
  const { broker } = props;

  useEffect(() => {
    const id = (props.match.params as IParams).id;
    props
      .fetchBroker(id)
      .then((res) => {
        document.title = `Corretor(a): ${res.name || res.companyName} | ${
          packageJson.displayName
        }`;
        setLoading(false);
      })
      .catch(() => {
        message.error('Corretor não encontrado.');
        setRedirect('/corretores/');
      });
    return () => {
      store.dispatch({
        type: CLEAR_BROKER,
      });
      document.title = 'Embrane | Admin';
    };
  }, []);

  const onFinish = (values: any) => {
    setSending(true);
    values.userId = broker?.id;
    props
      .updateBroker(values)
      .then((res) => {
        message.success('Corretor atualizado com sucesso!');
        setSending(false);
        setRedirect(`/corretores/${res.id}`);
      })
      .catch(() => {
        setSending(false);
      });
  };

  return (
    <>
      {!!redirect && <Redirect to={redirect} />}

      {!!broker && !loading ? (
        <>
          <GoBack />
          <Form onFinish={onFinish} layout="vertical">
            {broker.broker?.cpf ? (
              <PersonUpdateForm broker={broker} />
            ) : (
              <CompanyUpdateForm broker={broker} />
            )}
            <FormFinish text="Atualizar" sending={sending} />
          </Form>
        </>
      ) : (
        <Loading enabled={loading} />
      )}
    </>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  broker: state.brokers.broker,
});

export default connect(mapStateToProps, { fetchBroker, updateBroker })(
  UpdateBrokerScreen
);
