import { Col, Form, Input, Row, Select } from 'antd';
import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import { Card } from 'src/components';
import { IUser } from 'src/interfaces/user';
import { decorator, states } from 'src/utils';

interface IProps {
  broker?: IUser;
}

const PersonUpdateForm: React.FC<IProps> = (props) => {
  const { broker } = props;
  const { Option } = Select;
  const [phone, setPhone] = useState('');
  const [birthdate, setBirthdate] = useState('');
  const [state, setState] = useState(broker?.broker?.address.state);

  const handlePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value.replace(/ /g, '').length >= 14) {
      setPhone('(99) 99999-9999');
    }
  };

  const handleBirthdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (value.replace(/ /g, '').length === 10) {
      setBirthdate('99/99/9999');
    }
  };

  const handleState = (e: any, option: any) => {
    setState(e);
  };

  return (
    <Card
      title="Atualizar Corretor(a)"
      subtitle="Altere os dados do corretor a baixo."
    >
      <Row gutter={16}>
        <Col md={10}>
          <Form.Item
            label="Nome"
            name="name"
            initialValue={broker?.name}
            rules={decorator.name}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item
            label="E-mail"
            name="email"
            initialValue={broker?.email}
            rules={decorator.email}
          >
            <Input placeholder="Insira o e-mail..." />
          </Form.Item>
        </Col>
        <Col md={4}>
          <Form.Item
            rules={decorator.phone}
            name="phone"
            initialValue={broker?.phone}
            label="Telefone"
          >
            <InputMask
              mask="99 99999-9999"
              value={phone}
              onChange={handlePhone}
            >
              {(inputProps: any) => <Input {...inputProps} />}
            </InputMask>
          </Form.Item>
        </Col>
        <Col md={4}>
          <Form.Item
            label="Data de nascimento"
            name="birthdate"
            rules={decorator.birthdate}
            initialValue={broker?.birthdate}
          >
            <InputMask
              mask="99/99/9999"
              value={birthdate}
              onChange={handleBirthdate}
            >
              {(inputProps: string) => (
                <Input
                  {...inputProps}
                  placeholder="Insira a data de nascimento"
                />
              )}
            </InputMask>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={4}>
          <Form.Item
            label="Estado"
            rules={decorator.state}
            name={['address', 'state']}
            initialValue={broker?.broker?.address.state}
          >
            <Select onChange={handleState}>
              {states.map((item) => (
                <Option key={item.key} value={item.uf}>
                  {item.uf}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item
            label="CRECI"
            name="creci"
            initialValue={broker?.broker?.creci}
            rules={decorator.creci}
          >
            <InputMask mask={`${state}-999999/O`}>
              {(inputProps: string) => (
                <Input {...inputProps} placeholder="Insira o CRECI..." />
              )}
            </InputMask>
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item
            name="cpf"
            label="CPF"
            initialValue={broker?.broker?.cpf}
            rules={decorator.cpf}
          >
            <InputMask mask="999.999.999-99">
              {(inputProps: string) => (
                <Input {...inputProps} placeholder="Insira o CPF..." />
              )}
            </InputMask>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={4}>
          <Form.Item
            name={['address', 'zipcode']}
            initialValue={broker?.broker?.address.zipcode}
            label="CEP"
            rules={decorator.zipcode}
          >
            <InputMask mask="99999-999">
              {(inputProps: string) => (
                <Input {...inputProps} placeholder="Insira o CEP" />
              )}
            </InputMask>
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item
            name={['address', 'city']}
            label="Cidade"
            rules={decorator.city}
            initialValue={broker?.broker?.address.city}
          >
            <Input placeholder="Insira a cidade..." />
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item
            name={['address', 'neighborhood']}
            rules={decorator.neighborhood}
            label="Bairro"
            initialValue={broker?.broker?.address.neighborhood}
          >
            <Input placeholder="Insira o bairro..." />
          </Form.Item>
        </Col>
        <Col md={8}>
          <Form.Item
            label="Rua"
            name={['address', 'street']}
            rules={decorator.street}
            initialValue={broker?.broker?.address.street}
          >
            <Input placeholder="Insira a rua..." />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={4}>
          <Form.Item
            name={['address', 'number']}
            initialValue={broker?.broker?.address.number}
            rules={decorator.streetNumber}
            label="Número"
          >
            <Input placeholder="Insira o número..." />
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item
            name={['address', 'complement']}
            initialValue={broker?.broker?.address.complement}
            label="Complemento"
          >
            <Input placeholder="(Opcional) Insira um complemento" />
          </Form.Item>
        </Col>
        <Col md={8}>
          <Form.Item label="Senha" name="password">
            <Input placeholder="Insira a senha do corretor..." />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

export default PersonUpdateForm;
