import React from 'react';
import { FiCompass } from 'react-icons/fi';
import { LocateContainer } from './styles';

interface IProps {
  panTo: (latLng: google.maps.LatLngLiteral) => void;
}

const Locate: React.FC<IProps> = (props) => {
  const { panTo } = props;

  return (
    <LocateContainer>
      <button
        className="locate"
        onClick={() => {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              panTo({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              });
            },
            () => null
          );
        }}
      >
        <FiCompass />
      </button>
    </LocateContainer>
  );
};

export default Locate;
