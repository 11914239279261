import { Dispatch } from 'react';
import {
  BrokerActionTypes,
  FETCH_BROKERS,
  FETCH_BROKER,
  CREATE_BROKER,
  UPDATE_BROKER_STATUS,
  REMOVE_BROKER,
  UPDATE_BROKER,
  ADMIN_CREATE_BROKER,
} from './types';
import BrokerServices from 'src/services/brokers';
import { ICreateBrokerInput, IUpdateBrokerInput } from 'src/interfaces/broker';
import { forms } from 'src/utils';

export const fetchBrokers = () => (dispatch: Dispatch<BrokerActionTypes>) => {
  return BrokerServices.fetchBrokers()
    .then((res) => {
      dispatch({
        type: FETCH_BROKERS,
        payload: res,
      });
      return res;
    })
    .catch((err) => {
      throw Error;
    });
};

export const fetchBroker = (id: string) => (
  dispatch: Dispatch<BrokerActionTypes>
) => {
  return BrokerServices.fetchBroker(id).then((res) => {
    dispatch({
      type: FETCH_BROKER,
      payload: res,
    });
    return res;
  });
};

export const createBroker = (input: ICreateBrokerInput) => (
  dispatch: Dispatch<BrokerActionTypes>
) => {
  return BrokerServices.createBroker(input)
    .then((res) => {
      dispatch({
        type: CREATE_BROKER,
        payload: res,
      });
      return res;
    })
    .catch((err) => {
      forms.errors(err);
      throw Error;
    });
};

// export const adminCreateBroker = (input: ICreateBrokerInput) => (
//   dispatch: Dispatch<BrokerActionTypes>
// ) => {
//   return BrokerServices.adminCreateBroker(input)
//     .then((res) => {
//       dispatch({
//         type: ADMIN_CREATE_BROKER,
//         payload: res,
//       });
//       return res;
//     })
//     .catch((err) => {
//       forms.errors(err);
//       throw Error;
//     });
// };

export const updateBrokerStatus = (brokerId: string, newStatus: string) => (
  dispatch: Dispatch<BrokerActionTypes>
) => {
  return BrokerServices.updateBrokerStatus(brokerId, newStatus).then((res) => {
    dispatch({
      type: UPDATE_BROKER_STATUS,
      payload: res,
    });
    return res;
  });
};

export const removeBroker = (userId: string) => (
  dispatch: Dispatch<BrokerActionTypes>
) => {
  return BrokerServices.removeBroker(userId).then((res) => {
    dispatch({
      type: REMOVE_BROKER,
    });
    return res;
  });
};

export const updateBroker = (input: IUpdateBrokerInput) => (
  dispatch: Dispatch<BrokerActionTypes>
) => {
  return BrokerServices.updateBroker(input)
    .then((res) => {
      dispatch({
        type: UPDATE_BROKER,
        payload: res,
      });
      return res;
    })
    .catch((err) => {
      forms.errors(err);
      throw Error;
    });
};
