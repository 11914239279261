import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import MainScreen from 'src/screens/main/main.component';
import NotFound from 'src/screens/error/404/404.component';

import { IProps } from './client.type';
import BrokersScreen from 'src/screens/brokers/brokers.component';
import NewBrokerScreen from 'src/screens/brokers/new.broker.component';
import PendentsBrokersScreen from 'src/screens/brokers/pendentsBroker.component';
import OwnersScreen from 'src/screens/owners/owners.component';
import NewOwnerScreen from 'src/screens/owners/new.owner.component';
import PropertiesScreen from 'src/screens/properties/properties.component';
import NewPropertyScreen from 'src/screens/properties/create.property.component';
import AppointmentsScreen from 'src/screens/appointments/appointments.component';
import SoldReportsScreen from 'src/screens/reports/sold.reports.component';
import PendentPropertiesScreen from 'src/screens/properties/pendent.properties.component';
import ShowBrokerScreen from 'src/screens/brokers/showBroker.component';
import UpdateBrokerScreen from 'src/screens/brokers/update.broker.component';
import showOwnerScreen from 'src/screens/owners/showOwner.component';
import UpdateOwnerScreen from 'src/screens/owners/update.owner.component';
import ShowPropertyScreen from 'src/screens/properties/show.property.component';
import UpdatePropertyScreen from 'src/screens/properties/update.property.component';
import RentedReportsScreen from 'src/screens/reports/rented.reports.component';

export default class ClientRoutes extends React.PureComponent<IProps> {
  private ClientRoute = ({ component: Component, ...rest }: any) => {
    const hasAccess = !!this.props.user;

    return (
      <Route
        {...rest}
        render={(propsComponent) => {
          return hasAccess ? (
            <Component {...propsComponent} />
          ) : (
            <Redirect to={{ pathname: '/' }} />
          );
        }}
      />
    );
  };

  public render() {
    const { user } = this.props;
    const ClientRoute = this.ClientRoute;

    return (
      <Switch>
        <ClientRoute path="/painel/" exact component={MainScreen} />
        <ClientRoute
          path="/painel/corretores"
          exact
          component={BrokersScreen}
        />
        <ClientRoute
          path="/painel/corretores/novo"
          exact
          component={NewBrokerScreen}
        />

        <ClientRoute
          path="/painel/corretores/pendentes"
          exact
          component={PendentsBrokersScreen}
        />
        <ClientRoute
          path="/painel/corretores/:id"
          exact
          component={ShowBrokerScreen}
        />

        <ClientRoute
          path="/painel/corretores/editar/:id"
          exact
          component={UpdateBrokerScreen}
        />
        <ClientRoute
          path="/painel/proprietarios/"
          exact
          component={OwnersScreen}
        />
        <ClientRoute
          path="/painel/proprietarios/novo"
          exact
          component={NewOwnerScreen}
        />

        <ClientRoute
          path="/painel/proprietarios/editar/:id"
          exact
          component={UpdateOwnerScreen}
        />

        <ClientRoute
          path="/painel/proprietarios/:id"
          exact
          component={showOwnerScreen}
        />

        <ClientRoute
          path="/painel/imoveis/"
          exact
          component={PropertiesScreen}
        />

        <ClientRoute
          path="/painel/imoveis/novo"
          exact
          component={NewPropertyScreen}
        />

        <ClientRoute
          path="/painel/imoveis/pendentes/"
          exact
          component={PendentPropertiesScreen}
        />

        <ClientRoute
          path="/painel/imoveis/editar/:id"
          exact
          component={UpdatePropertyScreen}
        />

        <ClientRoute
          path="/painel/imoveis/:id"
          exact
          component={ShowPropertyScreen}
        />

        <ClientRoute
          path="/painel/agendamentos/"
          exact
          component={AppointmentsScreen}
        />

        <ClientRoute
          path="/painel/relatorios/vendidos/"
          exact
          component={SoldReportsScreen}
        />

        <ClientRoute
          path="/painel/relatorios/alugados"
          exact
          component={RentedReportsScreen}
        />

        {user && <ClientRoute component={NotFound} />}
      </Switch>
    );
  }
}
