import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Menu } from 'antd';
import { Redirect, Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';

import { LOGOUT_USER } from 'src/store/auth/types';
import { store, IReduxState } from 'src/store';
import ImageLogo from 'src/assets/images/logo.png';

import styles, { Logo, Wrapper } from './menu.styles';
import { IProps } from './menu';
import { FaAngleDown, FaArrowDown } from 'react-icons/fa';

const MenuComponent: React.FC<IProps> = (props) => {
  const [redirect, setRedirect] = useState('');
  const { SubMenu } = Menu;
  const current: [] = [];

  const handleLogout = () => {
    store.dispatch({
      type: LOGOUT_USER,
    });
    setRedirect('/');
  };

  return (
    <>
      {redirect && <Redirect to={redirect} />}

      {!!props.admin ? (
        <Menu mode="horizontal" theme="dark" defaultSelectedKeys={current}>
          <SubMenu
            key="100"
            title={<span style={styles.menuTitle}>Corretores</span>}
          >
            <Menu.Item key="101">
              <Link to="/painel/corretores">Corretores</Link>
            </Menu.Item>
            <Menu.Item key="102">
              <Link to="/painel/corretores/novo">Novo Corretor</Link>
            </Menu.Item>
            <Menu.Item key="103">
              <Link to="/painel/corretores/pendentes">Pendentes</Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key="200"
            title={<span style={styles.menuTitle}>Proprietários</span>}
          >
            <Menu.Item key="201">
              <Link to="/painel/proprietarios/">Proprietários</Link>
            </Menu.Item>
            <Menu.Item key="202">
              <Link to="/painel/proprietarios/novo">Novo Proprietário</Link>
            </Menu.Item>
          </SubMenu>
          <SubMenu
            key="300"
            title={<span style={styles.menuTitle}>Imóveis</span>}
          >
            <Menu.Item key="301">
              <Link to="/painel/imoveis">Imóveis </Link>
            </Menu.Item>
            <Menu.Item key="302">
              <Link to="/painel/imoveis/novo">Novo Imóvel</Link>
            </Menu.Item>
            <Menu.Item key="303">
              <Link to="/painel/imoveis/pendentes">Pendentes</Link>
            </Menu.Item>
          </SubMenu>

          <SubMenu
            className="antDotsMenu"
            key="400"
            title={<span style={styles.menuTitle}>Relatórios</span>}
          >
            <Menu.Item key="501">
              <Link to="/painel/relatorios/vendidos">Imóveis vendidos</Link>
            </Menu.Item>
            <Menu.Item key="5002">
              <Link to="/painel/relatorios/alugados">Imóveis alocados</Link>
            </Menu.Item>
          </SubMenu>
          {/* <Menu.Item
                key="600"
                style={{ paddingBottom: '3px' }}
                icon={<FeatherIcon icon="bell" style={styles.icon} />}
              /> */}

          <Menu.Item key="500">
            <Link
              to="/painel/agendamentos"
              style={{
                fontSize: '22px',
                color: '#333333',
                marginBottom: '3px',
                marginLeft: '15px',
                marginRight: '15px',
              }}
            >
              Agendamentos
            </Link>
          </Menu.Item>

          <Menu.Item
            key="999"
            style={{ paddingBottom: '3px' }}
            onClick={handleLogout}
            icon={<FeatherIcon icon="log-out" style={styles.icon} />}
          >
            Sair
          </Menu.Item>
        </Menu>
      ) : null}
    </>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  admin: state.auth.user,
});

export default connect(mapStateToProps)(MenuComponent);
