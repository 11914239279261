import gql from 'graphql-tag';
import { ILogin } from 'src/interfaces/user';
import client from './client';

const AdminServices = {
  adminLogin: (input: ILogin) =>
    client
      .mutate({
        mutation: gql`
          mutation adminLogin($input: LoginInput!) {
            adminLogin(input: $input) {
              id
              role
              name
              email
              token
            }
          }
        `,
        variables: { input },
      })
      .then((res) => res.data.adminLogin),
};

export default AdminServices;
