import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import packageJson from '../../../package.json';
import { Redirect } from 'react-router-dom';

import { Card } from 'src/components';

import { message, Skeleton } from 'antd';
import { IReduxState, store } from 'src/store';
import { CLEAR_BROKER } from 'src/store/broker/types';
import { fetchBroker } from 'src/store/broker/actions';
import { IParams, IProps } from './components/types/broker.types';
import BrokerData from './components/showBroker/brokerData.component';
import GoBack from 'src/components/GoBack/GoBack';

const ShowBrokerScreen: React.FC<IProps> = (props) => {
  const [redirect, setRedirect] = useState('');
  const [loading, setLoading] = useState(true);
  const { broker } = props;
  useEffect(() => {
    const id = (props.match.params as IParams).id;

    props
      .fetchBroker(id)
      .then((res) => {
        document.title = `Corretor(a): ${res.name || res.companyName} | ${
          packageJson.displayName
        }`;
        setLoading(false);
      })
      .catch(() => {
        message.error('Corretor não encontrado.');
        setRedirect('/corretores/');
      });
    return () => {
      store.dispatch({
        type: CLEAR_BROKER,
      });
      document.title = 'Embrane | Admin';
    };
  }, []);

  return (
    <>
      {!!redirect && <Redirect to={redirect} />}

      {!!broker && !loading ? (
        <>
          <GoBack />
          <BrokerData broker={broker} />
        </>
      ) : (
        <Card title="Dados do Corretor">
          <Skeleton active paragraph={{ rows: 6 }} />
        </Card>
      )}
    </>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  broker: state.brokers.broker,
});

export default connect(mapStateToProps, { fetchBroker })(ShowBrokerScreen);
