import React, { useState } from 'react';
import { connect } from 'react-redux';

import { adminLogin } from 'src/store/auth/actions';

import { IProps } from './auth.type';
import { Form, Input, Button, Row, Col } from 'antd';
import { Title } from './auth.styles';
import { Card } from 'src/components';
import { decoratorEmail, decoratorPassword } from './utils/decorator';

const AuthScreen: React.FC<IProps> = (props) => {
  const onFinish = (values: any) => {
    setLoading(true);
    return props
      .adminLogin(values)
      .then((res) => {
        setLoading(true);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const [loading, setLoading] = useState(false);

  return (
    <>
      <Row justify="center" align="middle">
        <Col md={12}>
          <Title>Administrador</Title>
          <Card>
            <Form layout="vertical" onFinish={onFinish}>
              <Form.Item
                name="email"
                label="E-mail"
                rules={decoratorEmail}
                required
              >
                <Input placeholder="Insira um e-mail..." />
              </Form.Item>
              <Form.Item
                name="password"
                label="Senha"
                rules={decoratorPassword}
                required
              >
                <Input
                  name="password"
                  placeholder="Insira uma senha..."
                  type="password"
                />
              </Form.Item>
              <Row justify="center" align="middle">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn-success"
                  loading={loading}
                >
                  Entrar
                </Button>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default connect(undefined, { adminLogin })(AuthScreen);
