import { Col, Form, Input, InputNumber, Row } from 'antd';
import React from 'react';
import { Card } from 'src/components';
import { IArea } from 'src/interfaces/misc';
import { decorator } from 'src/utils';

interface IProps {
  area: IArea;
}

const PropertyAreaForm: React.FC<IProps> = (props) => {
  const { area } = props;
  return (
    <Card
      title="Área do Imóvel"
      subtitle="Altere abaixo as informações referentes a área do imóvel."
    >
      <Row gutter={8}>
        <Col md={4} xs={24}>
          <Form.Item
            label="Área Privativa m²"
            name={['area', 'privateArea']}
            initialValue={area.privateArea}
          >
            <InputNumber placeholder="Insira o tamanho da área privativa..." />
          </Form.Item>
        </Col>
        <Col md={4} xs={24}>
          <Form.Item
            label="Área do Lote m³"
            name={['area', 'lotArea']}
            initialValue={area.lotArea}
          >
            <InputNumber placeholder="Insira o tamanho da área do lote..." />
          </Form.Item>
        </Col>
        <Col md={4} xs={24}>
          <Form.Item
            label="Frente"
            name={['area', 'frontLot']}
            initialValue={area.frontLot}
          >
            <InputNumber placeholder="Insira o tamanho da frente..." />
          </Form.Item>
        </Col>
        <Col md={4} xs={24}>
          <Form.Item
            label="Lote direito"
            name={['area', 'rightLot']}
            initialValue={area.rightLot}
          >
            <InputNumber placeholder="Insira o tamanho do lote direito" />
          </Form.Item>
        </Col>
        <Col md={4} xs={24}>
          <Form.Item
            label="Lote esquerdo"
            name={['area', 'leftLot']}
            initialValue={area.leftLot}
          >
            <InputNumber placeholder="Insira o tamanho do lote esquerdo..." />
          </Form.Item>
        </Col>
        <Col md={4} xs={24}>
          <Form.Item
            label="Fundos"
            name={['area', 'backyard']}
            initialValue={area.backyard}
          >
            <InputNumber placeholder="Insira o tamanho dos fundos...." />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col md={24} xs={24}>
          <Form.Item
            label="Outras especificações"
            name={['area', 'otherMeasures']}
            initialValue={area.otherMeasures}
          >
            <Input.TextArea
              rows={4}
              placeholder="Insira as outras especificações..."
            />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

export default PropertyAreaForm;
