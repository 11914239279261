import { Col, Form, Input, Radio, Row, Select } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { SelectValue } from 'antd/lib/select';
import React, { useState } from 'react';
import { decorator, propertyTypes } from 'src/utils';

interface IProps {
  handleCheck: (e: RadioChangeEvent) => void;
  onCategoryChange: (value: any, option: any) => void;
  category: string;
}

const CategoryForm: React.FC<IProps> = (props) => {
  const [checked, setChecked] = useState('');
  const [column, setColumn] = useState('');
  const [availability, setAvailability] = useState('');

  const { handleCheck, onCategoryChange, category } = props;
  const { Item } = Form;
  const { Option } = Select;
  const { Group } = Radio;

  const selected: [] = propertyTypes.types[category];

  return (
    <>
      <h3>Tipo do Imóvel</h3>
      <Row gutter={16}>
        <Col md={6} lg={6} sm={12} xs={24}>
          <Item label="Zona do Imóvel" name="zone" rules={decorator.zone}>
            <Group onChange={handleCheck}>
              <Radio value="Urbana" checked={checked === 'Urbana'}>
                Urbana
              </Radio>
              <Radio value="Rural" checked={checked === 'Rural'}>
                Rural
              </Radio>
            </Group>
          </Item>
        </Col>
        <Col md={6} lg={6} sm={12} xs={24}>
          <Item
            label="Tipo do Imóvel"
            name="type"
            rules={decorator.propertyType}
          >
            <Select onChange={onCategoryChange} value={category}>
              {propertyTypes.propertyTypes.map((type) => (
                <Option key={type.label} value={type.label}>
                  {type.label}
                </Option>
              ))}
            </Select>
          </Item>
        </Col>
        <Col md={6} lg={6} sm={12} xs={24}>
          <Item
            label="Subtípo do Imóvel"
            name="subtype"
            rules={decorator.propertyType}
            preserve={false}
          >
            <Select disabled={!category}>
              {selected &&
                selected.map((item: any) => (
                  <Option key={item.id} value={item.id}>
                    {item.id}
                  </Option>
                ))}
            </Select>
          </Item>
        </Col>
        <Col md={6} lg={6} sm={12} xs={24}>
          <Item label="Construtora" name="builder">
            <Input placeholder="Insira a Construtora..." />
          </Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={6} lg={6} sm={12} xs={24}>
          <Item
            label="Disponibilidade"
            name="availability"
            rules={decorator.availability}
          >
            <Group>
              <Radio value="available" checked={availability === 'available'}>
                Disponível
              </Radio>
              <Radio value="rented" checked={availability === 'rented'}>
                Alugado
              </Radio>
            </Group>
          </Item>
        </Col>

        {category === 'Apartamento' ||
        category === 'Apartamento Duplex' ||
        category === 'Apartamento Triplex' ||
        category === 'Cobertura Linear' ||
        category === 'Cobertura Duplex' ||
        category === 'Cobertura Triplex' ||
        category === 'Flat' ||
        category === 'Loft' ? (
          <Col md={6} lg={6} sm={12} xs={24}>
            <Item label="Colunas" name="columns" rules={decorator.columns}>
              <Group>
                <Radio value="Frente" checked={column === 'Frente'}>
                  Frente
                </Radio>
                <Radio value="Meio" checked={column === 'Meio'}>
                  Meio
                </Radio>
                <Radio value="Fundos" checked={column === 'Fundos'}>
                  Fundos
                </Radio>
              </Group>
            </Item>
          </Col>
        ) : null}
        {category === 'Apartamento' ||
        category === 'Apartamento Duplex' ||
        category === 'Apartamento Triplex' ||
        category === 'Cobertura Linear' ||
        category === 'Cobertura Duplex' ||
        category === 'Cobertura Triplex' ||
        category === 'Flat' ||
        category === 'Loft' ||
        category === 'Loja Linear' ||
        category === 'Loja Duplex' ||
        category === 'Loja Triplex' ? (
          <Col md={6} lg={6} sm={12} xs={24}>
            <Item
              label="Unidade"
              name="apNumber"
              rules={decorator.apartmentNumber}
            >
              <Input placeholder="Número do Apartamento" />
            </Item>
          </Col>
        ) : null}
      </Row>
    </>
  );
};

export default CategoryForm;
