import {
  IReducerBrokerState,
  BrokerActionTypes,
  FETCH_BROKER,
  FETCH_BROKERS,
  CREATE_BROKER,
  REMOVE_BROKER,
  UPDATE_BROKER,
  CLEAR_BROKERS,
  CLEAR_BROKER,
  UPDATE_BROKER_STATUS,
} from './types';

import storage from 'redux-persist/es/storage';
import { persistReducer } from 'redux-persist';

const initialState: IReducerBrokerState = {
  broker: undefined,
  brokers: undefined,
};

const persistConfig = {
  storage,
  key: 'brokers',
  whitelist: ['brokers'],
};

const reducers = (state = initialState, action: BrokerActionTypes) => {
  switch (action.type) {
    case FETCH_BROKER:
      return {
        ...state,
        broker: action.payload,
      };
    case FETCH_BROKERS:
      return {
        ...state,
        brokers: action.payload,
      };
    case CREATE_BROKER:
      return {
        ...state,
        broker: action.payload,
      };
    case REMOVE_BROKER:
      return {
        ...state,
        broker: undefined,
      };

    case CLEAR_BROKER:
      return {
        ...state,
        broker: undefined,
      }
    case CLEAR_BROKERS:
      return {
        ...state,
        brokers: undefined,
      }
    case UPDATE_BROKER:
      return {
        ...state,
        broker: action.payload,
      };

    case UPDATE_BROKER_STATUS:
      return {
        ...state,
      }
    default:
      return state;
  }
};

export default persistReducer(persistConfig, reducers);
