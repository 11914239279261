import styled from "styled-components";


export const Wrapper = styled.div`
  
`;

export const Container = styled.div`
  
  width: 80px;
  span {
    h6 {
      width: 100%;
      border-bottom: 1px solid #000;
      border-end-end-radius: initial;
      text-align: center;
    }
    background: rgba( 255, 255, 255, 0.8);
    width: 120px;
    height: 100px;
    display: grid;
    place-items: center;
    font-size: 18px;
    border-radius: 5%;
    border: 1px solid #000;
  }
  margin-bottom: 20px;
  
  svg {
    color:  rgba(0, 0, 0, 1);
    width: 32px;
    height: 32px;
  }
`;