import React from 'react';
import { Descriptions } from 'antd';

import { IProperty } from 'src/interfaces/property';

interface IProps {
  property: IProperty;
}

const MainData: React.FC<IProps> = (props) => {
  const { property } = props;
  const { Item } = Descriptions;
  return (
    <Descriptions
      title="Informações do Imóvel"
      style={{ marginBottom: '35px' }}
    >
      <Item label="Zona"> {property.zone} </Item>
      <Item label="Tipo do Imóvel"> {property.type} </Item>
      <Item label="Subtípo do Imóvel"> {property.subtype} </Item>
      {property.columns ? (
        <Item label="Colunas"> {property.columns || 'Não Possuí.'} </Item>
      ) : null}
      {property.builder ? (
        <Item label="Construtora">{property.builder || 'Não Informado.'} </Item>
      ) : null}
    </Descriptions>
  );
};

export default MainData;
