import gql from 'graphql-tag';
import client from './client.auth';
import { ICreateBrokerInput, IUpdateBrokerInput } from 'src/interfaces/broker';

const BrokerServices = {
  fetchBrokers: () =>
    client
      .query({
        query: gql`
          query brokers {
            me {
              id
              admin {
                id
                brokers {
                  id
                  name
                  companyName
                  email
                  birthdate
                  phone
                  broker {
                    id
                    status
                    stateRegistration
                    creci
                    cpf
                    cnpj
                    address {
                      state
                      street
                      city
                      neighborhood
                      number
                      zipcode
                      complement
                    }
                  }
                }
              }
            }
          }
        `,
      })
      .then((res) => res.data.me.admin.brokers),

  fetchBroker: (id: string) =>
    client
      .query({
        query: gql`
          query broker($id: ID!) {
            me {
              id
              admin {
                id
                broker(id: $id) {
                  id
                  name
                  companyName
                  email
                  birthdate
                  phone
                  broker {
                    id
                    cpf
                    cnpj
                    address {
                      state
                      street
                      city
                      neighborhood
                      number
                      zipcode
                      complement
                    }
                    status
                    stateRegistration
                    creci
                    proposals
                    propertiesAcquired
                  }
                }
              }
            }
          }
        `,
        variables: { id },
      })
      .then((res) => res.data.me.admin.broker),

  createBroker: (input: ICreateBrokerInput) =>
    client
      .mutate({
        mutation: gql`
          mutation createBroker($input: CreateBrokerInput!) {
            createBroker(input: $input) {
              id
              name
              companyName
              email
              birthdate
              phone
              broker {
                id
                cpf
                cnpj
                creci
                status
                stateRegistration
                address {
                  zipcode
                  state
                  city
                  neighborhood
                  street
                  number
                  complement
                }
              }
              createdAt
              updatedAt
            }
          }
        `,
        variables: { input },
      })
      .then((res) => res.data.createBroker),

  updateBroker: (input: IUpdateBrokerInput) =>
    client
      .mutate({
        mutation: gql`
          mutation updateBroker($input: UpdateBrokerInput!) {
            updateBroker(input: $input) {
              id
              name
              companyName
              email

              birthdate
              phone
              broker {
                id
                creci
                cpf
                cnpj
                status
                stateRegistration
                address {
                  zipcode
                  state
                  city
                  neighborhood
                  street
                  number
                  complement
                }
                updatedAt
              }
            }
          }
        `,
        variables: { input },
      })
      .then((res) => res.data.updateBroker),

  removeBroker: (userId: string) =>
    client
      .mutate({
        mutation: gql`
          mutation removeBroker($userId: ID!) {
            removeBroker(userId: $userId)
          }
        `,
        variables: { userId },
      })
      .then((res) => res.data.removeBroker),

  updateBrokerStatus: (id: string, newStatus: string) =>
    client
      .mutate({
        mutation: gql`
          mutation updateBrokerStatus($newStatus: String!, $id: ID!) {
            updateBrokerStatus(newStatus: $newStatus, id: $id) {
              id
              status
            }
          }
        `,
        variables: { id, newStatus },
      })
      .then((res) => res.data.updateBrokerStatus),
};

export default BrokerServices;
