import {
  CLEAR_PROPERTY,
  CREATE_PROPERTY,
  FETCH_PROPERTIES,
  FETCH_PROPERTY,
  IReducerPropertyState,
  PropertyActionTypes,
  REMOVE_FILE,
  REMOVE_PROPERTY,
  STORE_AREA,
  STORE_CAR,
  STORE_CCIR,
  STORE_IMAGES,
  STORE_ITR,
  STORE_REGISTRATION,
  STORE_SCRIPTURE,
  STORE_SUBSCRIPTION,
  STORE_VALUES,
  UPDATE_PROPERTY,
} from './types';
import storage from 'redux-persist/es/storage';
import { persistReducer } from 'redux-persist';

const initalState: IReducerPropertyState = {
  property: undefined,
  properties: [],
  values: undefined,
};

const persistConfig = {
  storage,
  key: 'properties',
  whitelist: ['properties'],
};

const reducers = (state = initalState, action: PropertyActionTypes) => {
  switch (action.type) {
    case FETCH_PROPERTIES:
      return {
        ...state,
        properties: action.payload,
      };
    case CREATE_PROPERTY:
      return {
        ...state,
        property: action.payload,
      };
    case FETCH_PROPERTY:
      return {
        ...state,
        property: action.payload,
      };
    case CLEAR_PROPERTY:
      return {
        ...state,
        property: undefined,
      };
    case REMOVE_PROPERTY:
      return {
        ...state,
      };

    case UPDATE_PROPERTY:
      return {
        ...state,
        property: action.payload,
      };

    case REMOVE_FILE:
      const property = state.property!;
      const { images, ...data } = property;
      const newImages = property!.images.filter(
        (image) => image.path !== action.payload
      );
      property.images = images;
      return {
        ...state,
        property: {
          images: newImages,
          ...data,
        },
      };

    case STORE_VALUES:
      return {
        ...state,
        values: action.payload,
      };
    case STORE_AREA:
      return {
        ...state,
        area: action.payload,
      };
    case STORE_SCRIPTURE:
      return {
        ...state,
        scripture: action.payload,
      };
    case STORE_REGISTRATION:
      return {
        ...state,
        registration: action.payload,
      };
    case STORE_SUBSCRIPTION:
      return {
        ...state,
        subscription: action.payload,
      };
    case STORE_CAR:
      return {
        ...state,
        car: action.payload,
      };
    case STORE_CCIR:
      return {
        ...state,
        ccir: action.payload,
      };
    case STORE_ITR:
      return {
        ...state,
        itr: action.payload,
      };
    case STORE_IMAGES:
      return {
        ...state,
        images: action.payload,
      };
    default:
      return state;
  }
};

export default persistReducer(persistConfig, reducers);
