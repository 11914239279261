import { UploadOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, Input, Radio, Row, Upload } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import {
  RcFile,
  UploadChangeParam,
  UploadFile,
} from 'antd/lib/upload/interface';
import { MdBrokenImage } from 'react-icons/md';
import React, { useState } from 'react';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import { Card } from 'src/components';
import { store } from 'src/store';

import {
  STORE_SCRIPTURE,
  STORE_SUBSCRIPTION,
  STORE_REGISTRATION,
  STORE_CAR,
  STORE_CCIR,
  STORE_ITR,
} from 'src/store/properties/types';
import styles, {
  ImageMask,
  ImageNotFound,
  NotFound,
} from '../showProperty/property.styles';

interface IProps {
  scripture?: string;
  registration?: string;
  subscription?: string;
  car?: string;
  ccir?: string;
  itr?: string;
  bank?: string;
  overbalance?: string;
}

const PropertyDocumentsForm: React.FC<IProps> = (props) => {
  const {
    scripture,
    registration,
    subscription,
    car,
    ccir,
    itr,
    bank,
    overbalance,
  } = props;

  const [checked, setChecked] = useState(false);
  const [scriptureFile, setScriptureFile] = useState<UploadFile<any>[]>([]);
  const [registrationFile, setRegistrationFile] = useState<UploadFile<any>[]>(
    []
  );
  const [subscriptionFile, setSubscriptionFile] = useState<UploadFile<any>[]>(
    []
  );
  const [carFile, setCarFile] = useState<UploadFile<any>[]>([]);
  const [ccirFile, setCcirFile] = useState<UploadFile<any>[]>([]);
  const [itrFile, setItrFile] = useState<UploadFile<any>[]>([]);

  const { Group } = Radio;

  const onCheck = (e: CheckboxChangeEvent) => {
    setChecked(e.target.value);
  };

  const beforeUpload = (file: RcFile) => {
    return false;
  };

  const onChangeScripture = ({ fileList }: UploadChangeParam) => {
    setScriptureFile(fileList.slice(-1));
    store.dispatch({
      type: STORE_SCRIPTURE,
      payload: fileList[0]?.originFileObj,
    });
  };

  const onChangeRegistration = ({ fileList }: UploadChangeParam) => {
    setRegistrationFile(fileList.slice(-1));

    store.dispatch({
      type: STORE_REGISTRATION,
      payload: fileList[0]?.originFileObj,
    });
  };

  const onChangeSubscription = ({ fileList }: UploadChangeParam) => {
    setSubscriptionFile(fileList);

    store.dispatch({
      type: STORE_SUBSCRIPTION,
      payload: fileList[0]?.originFileObj,
    });
  };

  const onChangeCar = ({ fileList }: UploadChangeParam) => {
    setCarFile(fileList);

    store.dispatch({
      type: STORE_CAR,
      payload: fileList[0]?.originFileObj,
    });
  };

  const onChangeCcir = ({ fileList }: UploadChangeParam) => {
    setCcirFile(fileList);
    store.dispatch({
      type: STORE_CCIR,
      payload: fileList[0]?.originFileObj,
    });
  };

  const onChangeItr = ({ fileList }: UploadChangeParam) => {
    setItrFile(fileList);

    store.dispatch({
      type: STORE_ITR,
      payload: fileList[0]?.originFileObj,
    });
  };

  return (
    <Card
      title="Documentos"
      subtitle="Visualize e altere os documentos do imóvel abaixo."
    >
      <Row gutter={16} justify="center">
        <Col md={8} sm={24} xs={24} className="center-col">
          <Form.Item label="Escritura" name="scripture">
            <Upload
              onChange={onChangeScripture}
              fileList={scriptureFile}
              beforeUpload={beforeUpload}
              accept=".png,.jpg,.jpeg,.webp,.raw,.tiff,.pdf"
            >
              <Button icon={<UploadOutlined />}>Adicionar Documento</Button>
            </Upload>
          </Form.Item>
          {scripture?.includes('.pdf') ? (
            <>
              <h1>Escritura</h1>
              <a target="_blank" rel="noopener noreferrer" href={scripture}>
                Visualizar Escritura
              </a>
            </>
          ) : (
            <>
              {!scripture ? (
                <>
                  <NotFound>
                    <h1>Escritura</h1>
                  </NotFound>
                  <ImageNotFound>
                    <MdBrokenImage />
                  </ImageNotFound>
                </>
              ) : (
                <ImageMask>
                  <img src={scripture} alt="Escritura" />
                </ImageMask>
              )}
            </>
          )}
        </Col>
        <Col md={8} sm={24} xs={24} className="center-col">
          <Form.Item label="Matrícula (RGI) " name="registration">
            <Upload
              onChange={onChangeRegistration}
              fileList={registrationFile}
              beforeUpload={beforeUpload}
              accept=".png,.jpg,.jpeg,.webp,.raw,.tiff,.pdf"
            >
              <Button icon={<UploadOutlined />}> Adicionar Documento </Button>
            </Upload>
          </Form.Item>
          {registration?.includes('.pdf') ? (
            <>
              <h1>Matrícula (RGI)</h1>
              <a target="_blank" rel="noopener noreferrer" href={registration}>
                Visualizar Escritura
              </a>
            </>
          ) : (
            <>
              {!registration ? (
                <>
                  <NotFound>
                    <h1>Matrícula (RGI)</h1>
                  </NotFound>
                  <ImageNotFound>
                    <MdBrokenImage />
                  </ImageNotFound>
                </>
              ) : (
                <ImageMask>
                  <img src={registration} alt="Matrícula" />
                </ImageMask>
              )}
            </>
          )}
        </Col>
        <Col md={8} sm={24} xs={24} className="center-col">
          <Form.Item label="Inscrição Municipal (IPTU)" name="subscription">
            <Upload
              onChange={onChangeSubscription}
              fileList={subscriptionFile}
              beforeUpload={beforeUpload}
              accept=".png,.jpg,.jpeg,.webp,.raw,.tiff,.pdf"
            >
              <Button icon={<UploadOutlined />}> Adicionar Documento</Button>
            </Upload>
          </Form.Item>
          {subscription?.includes('.pdf') ? (
            <>
              <h1>Inscrição Municipal</h1>
              <a target="_blank" rel="noopener noreferrer" href={subscription}>
                Visualizar Inscrição
              </a>
            </>
          ) : (
            <>
              {!subscription ? (
                <>
                  <NotFound>
                    <h1>Inscrição Municipal</h1>
                  </NotFound>
                  <ImageNotFound>
                    <MdBrokenImage />
                  </ImageNotFound>
                </>
              ) : (
                <ImageMask>
                  <img src={subscription} alt="Inscrição Municipal" />
                </ImageMask>
              )}
            </>
          )}
        </Col>
      </Row>
      <Row gutter={8}>
        <Col md={8} sm={24} xs={24} className="center-col">
          <Form.Item label="CAR" name="car">
            <Upload
              onChange={onChangeCar}
              fileList={carFile}
              beforeUpload={beforeUpload}
              accept=".png,.jpg,.jpeg,.webp,.raw,.tiff,.pdf"
            >
              <Button icon={<UploadOutlined />}> Adicionar Documento</Button>
            </Upload>
          </Form.Item>
          {car?.includes('.pdf') ? (
            <>
              <h1>CAR</h1>
              <a target="_blank" rel="noopener noreferrer" href={car}>
                visualizar CAR
              </a>
            </>
          ) : (
            <>
              {!car ? (
                <>
                  <NotFound>
                    <h1>CAR</h1>
                  </NotFound>
                  <ImageNotFound>
                    <MdBrokenImage />
                  </ImageNotFound>
                </>
              ) : (
                <ImageMask>
                  <img src={car} alt="CAR" />
                </ImageMask>
              )}
            </>
          )}
        </Col>
        <Col md={8} sm={24} xs={24} className="center-col">
          <Form.Item label="ITR" name="itr">
            <Upload
              onChange={onChangeItr}
              fileList={itrFile}
              beforeUpload={beforeUpload}
              accept=".png,.jpg,.jpeg,.webp,.raw,.tiff,.pdf"
            >
              <Button icon={<UploadOutlined />}> Adicionar Documento</Button>
            </Upload>
          </Form.Item>
          {itr?.includes('.pdf') ? (
            <>
              <h1>ITR</h1>
              <a target="_blank" rel="noopener noreferrer" href={itr}>
                Visualizar ITR
              </a>
            </>
          ) : (
            <>
              {!itr ? (
                <>
                  <NotFound>
                    <h1>ITR</h1>
                  </NotFound>
                  <ImageNotFound>
                    <MdBrokenImage />
                  </ImageNotFound>
                </>
              ) : (
                <ImageMask>
                  <img src={itr} alt="ITR" />
                </ImageMask>
              )}
            </>
          )}
        </Col>
        <Col md={8} sm={24} xs={24} className="center-col">
          <Form.Item label="CCIR" name="ccir">
            <Upload
              onChange={onChangeCcir}
              fileList={ccirFile}
              beforeUpload={beforeUpload}
              accept=".png,.jpg,.jpeg,.webp,.raw,.tiff,.pdf"
            >
              <Button icon={<UploadOutlined />}> Adicionar Documento </Button>
            </Upload>
          </Form.Item>
          {ccir?.includes('.pdf') ? (
            <>
              <h1>CCIR</h1>
              <a target="_blank" rel="noopener noreferrer" href={ccir}>
                Visualizar CCIR
              </a>
            </>
          ) : (
            <>
              {!ccir ? (
                <>
                  <NotFound>
                    <h1>CCIR</h1>
                  </NotFound>
                  <ImageNotFound>
                    <MdBrokenImage />
                  </ImageNotFound>
                </>
              ) : (
                <ImageMask>
                  <img src={ccir} alt="CCIR" />
                </ImageMask>
              )}
            </>
          )}
        </Col>
      </Row>
      <Row gutter={8} style={{ marginTop: '35px' }}>
        <Col md={4}>
          <p
            style={{ fontSize: '12px', marginTop: '5px', marginBottom: '15px' }}
          >
            Financiamento
          </p>
          <Group onChange={onCheck} value={checked}>
            <Radio value={true} checked={!checked}>
              Sim
            </Radio>
            <Radio checked={!checked} value={false}>
              Não
            </Radio>
          </Group>
        </Col>
        {checked ? (
          <>
            <Col md={10} xs={24}>
              <Form.Item label="Banco" name="bank" initialValue={bank}>
                <Input placeholder="Insira o nome do banco..." />
              </Form.Item>
            </Col>
            <Col md={10} xs={24}>
              <Form.Item
                label="Saldo Devedor"
                name="overbalance"
                initialValue={overbalance}
              >
                <Input placeholder="Insira o saldo devedor..." />
              </Form.Item>
            </Col>
          </>
        ) : null}
      </Row>
    </Card>
  );
};

export default PropertyDocumentsForm;
