import React from 'react';
import FeatherIcon from 'feather-icons-react';

import styles, { Wrapper, Button } from './tableActions.styles';
import { IProps } from './tableActions.type';
import { Popconfirm, Tooltip } from 'antd';
import { Link } from 'react-router-dom';

const TableActions: React.FC<IProps> = (props) => {
  const { edit, loading, view, remove, approve, reject, popup } = props;
  return (
    <Wrapper style={{ alignItems: 'center ', alignContent: 'center' }}>
      {!!approve && (
        <Button onClick={approve.action}>
          {!!loading ? (
            <FeatherIcon
              icon={approve.iconDisabled || 'check-circle'}
              style={styles.approveIconDisabled}
            />
          ) : (
            <Tooltip overlay="Aprovar">
              <FeatherIcon icon="check-circle" style={styles.approveIcon} />
            </Tooltip>
          )}
        </Button>
      )}

      {!!view && (
        <Button>
          {!!loading ? (
            <FeatherIcon
              icon={view.iconDisabled || 'eye-off'}
              style={styles.eyeIconDisabled}
            />
          ) : (
            <Tooltip overlay="Ver">
              <Link to={view.link}>
                <FeatherIcon icon={view.icon || 'eye'} style={styles.eyeIcon} />
              </Link>
            </Tooltip>
          )}
        </Button>
      )}

      {!!edit && (
        <Button>
          {!!loading ? (
            <FeatherIcon
              icon={edit.iconDisabled || 'edit'}
              style={styles.editIconDisabled}
            />
          ) : (
            <Tooltip overlay="Editar">
              <Link to={edit.link}>
                <FeatherIcon icon="edit" style={styles.editIcon} />
              </Link>
            </Tooltip>
          )}
        </Button>
      )}

      {!!reject && (
        <Button onClick={reject.action}>
          {!!loading ? (
            <FeatherIcon icon={reject.iconDisabled || 'reject'} />
          ) : (
            <Tooltip overlay="Rejeitar">
              <FeatherIcon icon="x-circle" style={styles.rejectIcon} />
            </Tooltip>
          )}
        </Button>
      )}

      {!!remove && (
        <Button onClick={remove.action}>
          {!!loading ? (
            <FeatherIcon
              icon={remove.iconDisabled || 'delete'}
              style={styles.trashIconDisabled}
            />
          ) : (
            <Tooltip overlay="Deletar">
              <FeatherIcon icon="trash-2" style={styles.trashIcon} />
            </Tooltip>
          )}
        </Button>
      )}

      {!!popup && (
        <Popconfirm
          title={popup.title}
          onConfirm={popup.action}
          onCancel={popup.cancel}
          okText="Sim"
          cancelText="Não"
          autoAdjustOverflow
          placement="bottomRight"
        >
          {!!loading ? (
            <FeatherIcon
              icon={popup.iconDisabled || 'delete'}
              style={styles.trashIconDisabled}
            />
          ) : (
            <FeatherIcon icon="trash-2" style={styles.trashIcon} />
          )}
        </Popconfirm>
      )}
    </Wrapper>
  );
};

export default TableActions;
