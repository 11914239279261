import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card } from 'src/components';
import { Table } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { table } from 'src/utils';
import { IAppointment } from 'src/interfaces/appointments';
import { IReduxState } from 'src/store';
import { fetchAppointments } from 'src/store/appointments/actions';
import TableActions from 'src/components/tableActions/tableActions.component';

interface IProps {
  appointments?: IAppointment[];
  fetchAppointments: () => Promise<IAppointment[]>;
}

const AppointmentScreen: React.FC<IProps> = (props) => {
  const { appointments } = props;
  const [loading, setLoading] = useState<boolean>(false);

  const loadingIcon = <LoadingOutlined spin />;

  useEffect(() => {
    setLoading(true);
    props.fetchAppointments().then(() => {
      setLoading(false);
    });
  }, [appointments]);

  const dataSource =
    appointments &&
    appointments.map((appointment) => ({
      key: appointment.id,
      date: new Date(appointment.appointmentDate).toLocaleDateString('pt-BR'),
      hour: appointment.appointmentHour.substring(
        0,
        appointment.appointmentHour.length - 3
      ),
      propertyType: appointment.property.type,
      address: `${appointment.property.address.street}
      ${appointment.property.address.number}, 
      ${appointment.property.address.neighborhood}`,
    }));

  const columns = [
    {
      title: 'Data do Agendamento',
      key: 'date',
      dataIndex: 'date',
    },
    {
      title: 'Hora do Agendamento',
      key: 'hour',
      dataIndex: 'hour',
    },
    {
      title: 'Endereço',
      key: 'address',
      dataIndex: 'address',
    },

    {
      title: 'Tipo do Imóvel',
      key: 'propertyType',
      dataIndex: 'propertyType',
    },
    {
      title: ' Ações',
      key: 'actions',
      dataIndex: 'actions',
      render: (text: string, record: any) => (
        <>
          <TableActions
            view={{ link: `/painel/agendamentos/` }}
            loading={loading}
          />
        </>
      ),
    },
  ];

  return (
    <Card
      title="Agendamentos"
      subtitle="Veja aqui os agendamentos recebidas de cada imóvel."
    >
      <Table
        scroll={{ x: 500 }}
        dataSource={dataSource}
        {...table}
        columns={columns}
        loading={{ spinning: loading, indicator: loadingIcon }}
      />
    </Card>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  appointments: state.appointments.appointments,
});

export default connect(mapStateToProps, { fetchAppointments })(
  AppointmentScreen
);
