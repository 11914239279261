import { IArea, IValues } from 'src/interfaces/misc';
import { IProperty } from 'src/interfaces/property';

export const CREATE_PROPERTY = 'CREATE_PROPERTY';
export const FETCH_PROPERTY = 'FETCH_PROPERTY';
export const FETCH_PROPERTIES = 'FETCH_PROPERTIES';
export const REMOVE_PROPERTY = 'REMOVE_PROPERTY';
export const CLEAR_PROPERTY = 'CLEAR_PROPERTY';
export const UPDATE_PROPERTY_STATUS = 'UPDATE_PROPERTY_STATUS';
export const UPDATE_PROPERTY = 'UPDATE_PROPERTY';
export const REMOVE_FILE = 'REMOVE_FILE';

export const STORE_VALUES = 'STORE_VALUES';
export const STORE_AREA = 'STORE_AREA';
export const STORE_IMAGES = 'STORE_IMAGES';
export const STORE_FILES = 'STORE_FILES';
export const STORE_SCRIPTURE = 'STORE_SCRIPTURE';
export const STORE_REGISTRATION = 'STORE_REGISTRATION';
export const STORE_SUBSCRIPTION = 'STORE_SUBSCRIPTION';
export const STORE_CAR = 'STORE_CAR';
export const STORE_CCIR = 'STORE_CCIR';
export const STORE_ITR = 'STORE_ITR';

export interface IReducerPropertyState {
  property?: IProperty;
  properties?: IProperty[];
  values?: IValues;
  area?: IArea;
  images?: any[];
  scripture?: any;
  registration?: any;
  subscription?: any;
  car?: any;
  ccir?: any;
  itr?: any;
}

export interface ICreateProperty {
  type: typeof CREATE_PROPERTY;
  payload: IProperty;
}

export interface IFetchProperty {
  type: typeof FETCH_PROPERTY;
  payload: IProperty;
}

export interface IFetchProperties {
  type: typeof FETCH_PROPERTIES;
  payload: IProperty[];
}

export interface IClearProperty {
  type: typeof CLEAR_PROPERTY;
}

export interface IRemoveProperty {
  type: typeof REMOVE_PROPERTY;
}

export interface IUpdatePropertyStatus {
  type: typeof UPDATE_PROPERTY_STATUS;
  payload: IProperty;
}

export interface IUpdateProperty {
  type: typeof UPDATE_PROPERTY;
  payload: IProperty;
}

export interface IRemoveFile {
  type: typeof REMOVE_FILE;
  payload: string;
}

export interface IStoreValues {
  type: typeof STORE_VALUES;
  payload: IValues;
}

export interface IStoreArea {
  type: typeof STORE_AREA;
  payload: IArea;
}

export interface IStoreImages {
  type: typeof STORE_IMAGES;
  payload: any;
}

export interface IStoreScripture {
  type: typeof STORE_SCRIPTURE;
  payload: any;
}

export interface IStoreRegistration {
  type: typeof STORE_REGISTRATION;
  payload: any;
}

export interface IStoreSubscription {
  type: typeof STORE_SUBSCRIPTION;
  payload: any;
}

export interface IStoreCar {
  type: typeof STORE_CAR;
  payload: any;
}

export interface IStoreCcir {
  type: typeof STORE_CCIR;
  payload: any;
}

export interface IStoreItr {
  type: typeof STORE_ITR;
  payload: any;
}

export type PropertyActionTypes =
  | ICreateProperty
  | IFetchProperties
  | IFetchProperty
  | IClearProperty
  | IRemoveProperty
  | IUpdatePropertyStatus
  | IUpdateProperty
  | IRemoveFile
  | IStoreValues
  | IStoreArea
  | IStoreImages
  | IStoreScripture
  | IStoreRegistration
  | IStoreSubscription
  | IStoreCar
  | IStoreCcir
  | IStoreItr;
