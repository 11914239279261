import { Table } from 'antd';
import React from 'react';
import { Card } from 'src/components';
import { table } from 'src/utils';

const SoldReportsScreen: React.FC = () => {
  const columns = [
    {
      title: 'Imóvel',
      key: 'type',
      dataIndex: 'type',
    },
    {
      title: 'Proprietário',
      key: 'owner',
      dataIndex: 'owner',
    },
    {
      title: 'Comprador',
      key: 'brokerName',
      dataIndex: 'brokerName',
    },
    {
      title: 'Preço de Venda',
      key: 'salePrice',
      dataIndex: 'salePrice',
    },
  ];

  return (
    <Card
      title="Imóveis Vendidos"
      subtitle="Veja aqui os imóveis vendidos no sistema"
    >
      <Table columns={columns} {...table} scroll={{ x: 500 }} />
    </Card>
  );
};

export default SoldReportsScreen;
