import React, { useState } from 'react';
import { Link, Router } from 'react-router-dom';

import { createBrowserHistory } from 'history';
import { Layout } from 'antd';

import { Network } from 'src/components';
import SW from './components/sw/sw.component';

import Menu from './components/menu/menu.component';
import { Container } from './app.styles';
import FooterComponent from './components/footer/footer.component';
import Routes from './routes/routes.component';

import ImageLogo from 'src/assets/images/logo.png';
import DrawerComponent from './components/drawer/drawer.component';
import HeaderComponent from './components/header/header.component';

export const history = createBrowserHistory();

const App: React.FC = () => {
  const { Header, Footer, Content } = Layout;
  const [visible, setVisible] = useState<boolean>(false);

  const onCloseDrawer = () => {
    setVisible(false);
  };

  const onOpenDrawer = () => {
    setVisible(true);
  };

  return (
    <Router history={history}>
      <Layout>
        <Header>
          <DrawerComponent onClose={onCloseDrawer} visible={visible} />
          <HeaderComponent setVisible={onOpenDrawer} />
          <Menu />
        </Header>
        <Layout
          style={{
            minHeight: 'auto',
          }}
        >
          <Content>
            <Container>
              <Routes />
              <SW />
            </Container>
            <Network />
          </Content>
        </Layout>
        <FooterComponent />
      </Layout>
    </Router>
  );
};

export default process.env.NODE_ENV === 'production'
  ? App
  : require('react-hot-loader').hot(module)(App);
