import React, { Fragment } from 'react';
import { Button, Row } from 'antd';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';

import { IProps } from './card';
import {
  Container,
  CardHeader,
  Title,
  Subtitle,
  CardBody,
} from './card.styles';

const CardComponent: React.FC<IProps> = (props) => {
  const { title, subtitle, children, button } = props;

  return (
    <Container>
      {!!title && (
        <CardHeader>
          <Row align="middle" justify="space-between">
            <Title>{title}</Title>
            {!!button && (
              <Fragment>
                {!!button.link && (
                  <Link to={button.link}>
                    <Button size="small">
                      <FeatherIcon icon={button.icon} style={{ margin: 0 }} />
                      {button.text}
                    </Button>
                  </Link>
                )}
                {!!button.action && (
                  <Button size="small" onClick={button.action}>
                    <FeatherIcon icon={button.icon} />
                    {button.text}
                  </Button>
                )}
              </Fragment>
            )}
          </Row>
          {!!subtitle && <Subtitle>{subtitle}</Subtitle>}
        </CardHeader>
      )}

      <CardBody>{children && children}</CardBody>
    </Container>
  );
};

export default CardComponent;
