import { message, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { table } from 'src/utils';
import { Card } from 'src/components';
import { IReduxState } from 'src/store';
import {
  fetchProperties,
  updatePropertyStatus,
  removeProperty,
} from 'src/store/properties/actions';

import { IProps } from './properties.types';
import TableActions from 'src/components/tableActions/tableActions.component';

const PendentPropertiesScreen: React.FC<IProps> = (props) => {
  const [loading, setLoading] = useState(true);
  const { properties } = props;

  const pendentProperties = properties?.filter(
    (property) => property.status === 'pendent'
  );

  useEffect(() => {
    props.fetchProperties().catch(() => {
      return message.error(
        'Ocorreu um erro ao carregar as propriedades, tente novamente mais tarde ou contate o suporte.'
      );
    });
    setLoading(false);
  }, []);

  const updateStatus = (id: string, newStatus: string) => {
    props
      .updatePropertyStatus(newStatus, id)
      .then(() => {
        message.info('A propriedade foi atualizada.');
      })
      .finally(() => {
        props.fetchProperties();
      });
  };

  const remove = (id: string) => {
    props
      .removeProperty(id)
      .then((res) => {
        message.info('A propriedade foi removida.');
      })
      .finally(() => {
        props.fetchProperties();
      });
  };

  const dataSource =
    pendentProperties &&
    pendentProperties.map((property) => ({
      key: property.id,
      salePrice:
        property.values.salePrice?.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }) || 'Não Informado.',
      rentPrice:
        property.values.rentPrice?.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }) || 'Não Informado.',
      type: property.type,
      subtype: property.subtype,
      zone: property.zone,
      state: property.address.state,
    }));

  const columns = [
    {
      title: 'Preço de venda',
      key: 'salePrice',
      dataIndex: 'salePrice',
    },
    {
      title: 'Preço de Locação',
      key: 'rentPrice',
      dataIndex: 'rentPrice',
    },
    {
      title: 'Tipo do Imóvel',
      key: 'type',
      dataIndex: 'type',
    },
    {
      title: 'Subtípo do Imóvel',
      key: 'subtype',
      dataIndex: 'subtype',
    },
    {
      title: 'Zona',
      key: 'zone',
      dataIndex: 'zone',
    },
    {
      title: 'Estado',
      key: 'state',
      dataIndex: 'state',
    },
    {
      title: 'Ações',
      key: 'actions',
      datIndex: 'actions',
      render: (text: any, record: any) => (
        <TableActions
          view={{ link: `/painel/imoveis/${record.key}` }}
          approve={{ action: () => updateStatus(record.key, 'approved') }}
          reject={{ action: () => remove(record.key) }}
        />
      ),
    },
  ];

  return (
    <Card
      title="Imóveis Pendentes"
      subtitle="Aprove aqui as imóveis pendentes."
    >
      {loading ? (
        <p>Carregando...</p>
      ) : (
        <Table dataSource={dataSource} {...table} columns={columns} />
      )}
    </Card>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  properties: state.properties.properties,
});

export default connect(mapStateToProps, {
  fetchProperties,
  updatePropertyStatus,
  removeProperty,
})(PendentPropertiesScreen);
