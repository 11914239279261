import styled from "styled-components";



export const GoBackButton = styled.p`
  display: flex;
  margin-top: 20px;
  font-size: 16px;
  transition: 0.2s all;
  color: #c0c0c0;
  cursor: pointer;
  svg { 
    margin-right: 10px;
  }
  &:hover {
    color: rgba(255, 255, 255,  1);
  }
`;