import React from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineMenu } from 'react-icons/ai';
import { connect } from 'react-redux';

import {
  DrawerContainer,
  LogoContainer,
  LogoUnauthenticated,
} from './header.styles';

import { IUser } from 'src/interfaces/user';
import { IReduxState } from 'src/store';

import ImageLogo from 'src/assets/images/logo.png';
import Logo from 'src/assets/images/logo_PNG.png';
// import Logo from 'src/assets/images/logo2.png';
// import LogoPng from 'src/assets/images/logo_PNG.png';

interface IProps {
  auth?: IUser;
  collapsed?: boolean;
  setVisible: () => void;
}

const HeaderComponent: React.FC<IProps> = (props) => {
  const { auth, setVisible } = props;

  return (
    <>
      {auth ? (
        <>
          <DrawerContainer>
            <span
              role="img"
              arial-label="menu"
              className="anticon anticon-menu"
              tabIndex={-1}
            >
              {React.createElement(AiOutlineMenu, {
                onClick: setVisible,
              })}
            </span>
          </DrawerContainer>
          <LogoContainer>
            <Link to="/">
              <img src={Logo} alt="" />
            </Link>
          </LogoContainer>
        </>
      ) : (
        <LogoUnauthenticated>
          <Link to="/">
            <img src={ImageLogo} alt="" />
          </Link>
        </LogoUnauthenticated>
      )}
    </>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  auth: state.auth.user,
});

export default connect(mapStateToProps)(HeaderComponent);
