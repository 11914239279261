import { Divider, Drawer, Menu } from 'antd';
import React from 'react';
import {
  FaBuilding,
  FaCalendarCheck,
  FaDownload,
  FaFileContract,
  FaHandHolding,
  FaHome,
  FaPersonBooth,
  FaPlus,
  FaRegBuilding,
  FaRegFileAlt,
  FaStopwatch,
  FaUser,
  FaUserAlt,
  FaUserClock,
  FaUserFriends,
  FaUserMd,
  FaUserPlus,
  FaWarehouse,
} from 'react-icons/fa';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { IUser } from 'src/interfaces/user';
import { IReduxState } from 'src/store';
import { DrawerBody, LogoTitle } from './drawer.styles';

import ImageLogo from 'src/assets/images/logo.png';
import Logo from 'src/assets/images/logo_PNG.png';

interface IProps {
  visible: boolean;
  onClose: () => void;
  auth?: IUser;
}

const DrawerComponent: React.FC<IProps> = (props) => {
  const { onClose, visible, auth } = props;

  const { Item } = Menu;
  return (
    <>
      {!!auth ? (
        <Drawer
          width={250}
          closable={false}
          placement="left"
          visible={visible}
          onClose={onClose}
        >
          <LogoTitle>
            <img src={Logo} alt="" />
          </LogoTitle>
          <Divider style={{ width: '100%' }} />

          <DrawerBody>
            <Menu mode="inline">
              <Item key="0" icon={<FaHome />}>
                <Link to="/painel">Início</Link>
              </Item>
              <p
                style={{
                  paddingTop: '30px',
                  color: 'rgb(5, 0, 195)',
                }}
              >
                Corretores:
              </p>

              <Item key="1" icon={<FaUserFriends />}>
                <Link to="/painel/corretores">Corretores</Link>
              </Item>
              <Item key="2" icon={<FaUserPlus />}>
                <Link to="/painel/corretores/novo">Novo Corretor</Link>
              </Item>
              <Item key="3" icon={<FaUserClock />}>
                <Link to="/painel/corretores/pendentes">Pendentes</Link>
              </Item>
              <p
                style={{
                  paddingTop: '30px',
                  color: 'rgb(5, 0, 195)',
                }}
              >
                Proprietários:
              </p>

              <Item key="4" icon={<FaUserFriends />}>
                <Link to="/painel/proprietarios">Proprietários</Link>
              </Item>
              <Item key="5" icon={<FaUserPlus />}>
                <Link to="/painel/proprietarios/novo">Novo Proprietário</Link>
              </Item>
              <p
                style={{
                  paddingTop: '30px',
                  color: 'rgb(5, 0, 195)',
                }}
              >
                Imóveis:
              </p>

              <Item key="6" icon={<FaBuilding />}>
                <Link to="/painel/imoveis/">Imóveis</Link>
              </Item>

              <Item key="7" icon={<FaPlus />}>
                <Link to="/painel/imoveis/novo">Novo Imóvel</Link>
              </Item>

              <Item key="8" icon={<FaRegBuilding />}>
                <Link to="/painel/imoveis/pendentes"> Imóveis Pendentes</Link>
              </Item>
              <p
                style={{
                  paddingTop: '30px',
                  color: 'rgb(5, 0, 195)',
                }}
              >
                Agendamentos:
              </p>

              <Item key="9" icon={<FaCalendarCheck />}>
                <Link to="/painel/agendamentos">Agendamentos</Link>
              </Item>
              <p
                style={{
                  paddingTop: '30px',
                  color: 'rgb(5, 0, 195)',
                }}
              >
                Relatórios:
              </p>
              <Item key="10" icon={<FaFileContract />}>
                <Link to="/painel/relatorios/vendidos">Vendidos</Link>
              </Item>

              <Item key="11" icon={<FaRegFileAlt />}>
                <Link to="/painel/relatorios/alugados">Alugados</Link>
              </Item>
            </Menu>
          </DrawerBody>
        </Drawer>
      ) : null}
    </>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  auth: state.auth.user,
});

export default connect(mapStateToProps)(DrawerComponent);
