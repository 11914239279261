import { Col, Divider, Form, Input, InputNumber, Radio, Row } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import { decorator } from 'src/utils';
import HeadquartersComponent from '../headquarters.component';
import ExtraHouses from './extraHouses.component';

interface IProps {
  acres?: number;
}

export const RuralForm: React.FC = () => {
  const [acres, setAcres] = useState<number | undefined>();
  const [checked, setChecked] = useState('');
  const { Item } = Form;
  const { Group } = Radio;

  const onCheck = (e: RadioChangeEvent) => {
    setChecked(e.target.value);
  };

  return (
    <>
      <Divider />
      <h3>Características Rurais</h3>
      <Row gutter={16}>
        <Col md={24} lg={12} sm={24} xs={24}>
          <Item
            label="Hectáres"
            name={['property', 'rural', 'acres']}
            rules={decorator.acres}
          >
            <NumberFormat
              thousandSeparator={'.'}
              decimalSeparator={','}
              suffix={' m³'}
              onValueChange={(value) => setAcres(value.floatValue)}
              decimalScale={2}
              customInput={Input}
            />
          </Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col md={8} lg={4} sm={12} xs={24}>
          <Item label="Embarcadouros" name={['property', 'rural', 'docks']}>
            <InputNumber />
          </Item>
        </Col>

        <Col md={8} lg={4} sm={12} xs={24}>
          <Item label="Currais" name={['property', 'rural', 'corrals']}>
            <InputNumber />
          </Item>
        </Col>

        <Col md={8} lg={4} sm={12} xs={24}>
          <Item label="Troncos" name={['property', 'rural', 'trunks']}>
            <InputNumber />
          </Item>
        </Col>

        <Col md={8} lg={4} sm={12} xs={24}>
          <Item label="Bretes" name={['property', 'rural', 'bretes']}>
            <InputNumber />
          </Item>
        </Col>

        <Col md={8} lg={4} sm={12} xs={24}>
          <Item label="Aguardas" name={['property', 'rural', 'waits']}>
            <InputNumber />
          </Item>
        </Col>

        <Col md={8} lg={4} sm={12} xs={24}>
          <Item label="Balanças" name={['property', 'rural', 'scales']}>
            <InputNumber />
          </Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Item label="Casa sede?">
          <Group onChange={onCheck} value={checked}>
            <Radio value="yes" checked={checked === 'yes'}>
              Sim
            </Radio>
            <Radio value="no" checked={checked === 'no'}>
              Não
            </Radio>
          </Group>
        </Item>
      </Row>
      {checked === 'yes' ? <HeadquartersComponent /> : null}
      <ExtraHouses />
    </>
  );
};

export default RuralForm;
