import React from 'react';
import { Col, Form, Input, Row, Select } from 'antd';
import InputMask from 'react-input-mask';

import { IAddress } from 'src/interfaces/misc';
import { Card } from 'src/components';
import { decorator, states } from 'src/utils';

interface IProps {
  address: IAddress;
}

const PropertyAddressForm: React.FC<IProps> = (props) => {
  const { address } = props;
  const { Option } = Select;

  return (
    <Card
      title="Endereço do Imóvel"
      subtitle="Altere o endereço do imóvel caso necessário."
    >
      <Row gutter={8}>
        <Col md={4}>
          <Form.Item
            label="CEP"
            name={['address', 'zipcode']}
            rules={decorator.zipcode}
            initialValue={address.zipcode}
          >
            <InputMask maskChar="" mask="99999-999">
              {(inputProps: string) => (
                <Input {...inputProps} placeholder="Insira o CEP..." />
              )}
            </InputMask>
          </Form.Item>
        </Col>
        <Col md={2}>
          <Form.Item
            label="Estado"
            name={['address', 'state']}
            rules={decorator.state}
            initialValue={address.state}
          >
            <Select>
              {states &&
                states.map((state) => (
                  <Option key={state.key} value={state.uf}>
                    {state.uf}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item
            label="Cidade"
            name={['address', 'city']}
            rules={decorator.city}
            initialValue={address.city}
          >
            <Input placeholder="Insira a cidade..." />
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item
            label="Bairro"
            name={['address', 'neighborhood']}
            rules={decorator.neighborhood}
            initialValue={address.neighborhood}
          >
            <Input placeholder="Insira o bairro..." />
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item
            label="Rua"
            name={['address', 'street']}
            rules={decorator.street}
            initialValue={address.street}
          >
            <Input placeholder="Insira a rua..." />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col md={4}>
          <Form.Item
            label="Número"
            name={['address', 'number']}
            rules={decorator.streetNumber}
            initialValue={address.number}
          >
            <Input placeholder="Insira o número..." />
          </Form.Item>
        </Col>
        <Col md={8}>
          <Form.Item
            label="Complemento"
            name={['address', 'complement']}
            rules={decorator.complement}
            initialValue={address.complement}
          >
            <Input placeholder="Insira o complemento..." />
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item
            label="Condomínio/Edifício"
            name={['address', 'complex']}
            rules={decorator.complex}
            initialValue={address.complex}
          >
            <Input placeholder="Insira o nome do condomínio/edifício..." />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col md={6}>
          <Form.Item
            label="Latitude"
            name={['address', 'latitude']}
            rules={decorator.latitude}
            initialValue={address.latitude}
          >
            <Input placeholder="Insira a latitude do imóvel..." />
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item
            label="Longitude"
            name={['address', 'longitude']}
            rules={decorator.longitude}
            initialValue={address.longitude}
          >
            <Input placeholder="Insira a longitude do imóvel..." />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

export default PropertyAddressForm;
