import styled from 'styled-components';

export const ImageContainer = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row wrap;
`;

export const ImageMask = styled.div`
 display: block;
 margin: 25px 15px;
  font-size: 0;
  position: relative;
    &:hover {
      img {
        transition: 0.3s all;
        filter: brightness(50%);

      }
    }
  
  padding: 10px;
  transition: 0.3s all;
  border: 1px solid #fff;
  &:hover > .Actions {
    transition: 0.3s all;
    opacity: 1;
  }
  .Actions {
    opacity: 0;
  }

`;

export const Image = styled.span`
  display: block;
  width: 100%;
  height: 100%;
  transition: 0.2s all;

  img {
    width: 110px;
    height: 100px;
    object-fit: cover;  
  }
`;

export const Actions = styled.span`
  display: inline-block;
  position: absolute;
  
    top: 40%;
    left: 20%;

   .delete-button {
     color: #ef5350;
   }
   
   .view-button {
     color: #fff;
   }
   
  button {
    transition: 0.2s all;
    color: #000;
    background: none;
    border: none;
    padding: 0;
    margin: 0 9px ;
    width: 20px;
    height: 20px;
    &:hover, &:focus, &::after {
      background: none;
      
      border: none;
      border-radius: none;
      border-color: none;
      box-shadow: none;
    }
    
  }
`;