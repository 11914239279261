import { IUser } from 'src/interfaces/user';

export const FETCH_OWNERS = 'FETCH_OWNERS';
export const FETCH_OWNER = 'FETCH_OWNER';
export const CREATE_OWNER = 'CREATE_OWNER';
export const ADMIN_CREATE_OWNER = 'ADMIN_CREATE_OWNER';
export const REMOVE_OWNER = 'REMOVE_OWNER';
export const CLEAR_OWNERS = 'CLEAR_OWNERS';
export const CLEAR_OWNER = 'CLEAR_OWNER';
export const UPDATE_OWNER = 'UPDATE_OWNER';

export interface IReducerOwnerState {
  owner?: IUser;
  owners?: IUser[];
}

export interface IFetchOwners {
  type: typeof FETCH_OWNERS;
  payload: IUser[];
}

export interface IFetchOwner {
  type: typeof FETCH_OWNER;
  payload: IUser;
}

export interface ICreateOwner {
  type: typeof CREATE_OWNER;
  payload: IUser;
}

export interface IAdminCreateOwner {
  type: typeof ADMIN_CREATE_OWNER;
  payload: IUser;
}

export interface IClearOwners {
  type: typeof CLEAR_OWNERS;
}

export interface IRemoveOwner {
  type: typeof REMOVE_OWNER;
}

export interface IUpdateOwner {
  type: typeof UPDATE_OWNER;
  payload: IUser;
}

export interface IClearOwner {
  type: typeof CLEAR_OWNER;
}

export type OwnerActionTypes =
  | IFetchOwners
  | IClearOwners
  | IClearOwner
  | IFetchOwner
  | ICreateOwner
  | IAdminCreateOwner
  | IUpdateOwner
  | IRemoveOwner;
