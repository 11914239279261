import { Checkbox, Col, Form, Input, InputNumber, Row } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';

import { Card } from 'src/components';
import { IValues } from 'src/interfaces/misc';
import { store } from 'src/store';
import { STORE_VALUES } from 'src/store/properties/types';

import { decorator } from 'src/utils';

interface IProps {
  values: IValues;
}

const PropertyValuesForm: React.FC<IProps> = (props) => {
  const { values } = props;

  const [sale, setSale] = useState(!!values.salePrice);
  const [rent, setRent] = useState(!!values.rentPrice);
  const [newSalePrice, setSalePrice] = useState<number | undefined>();
  const [newCommunitySalePrice, setCommunitySalePrice] = useState<
    number | undefined
  >();
  const [newAnnualIptu, setAnnualIptu] = useState<number | undefined>();
  const [newRentPrice, setRentPrice] = useState<number | undefined>();
  const [newCommunityRentPrice, setCommunityRentPrice] = useState<
    number | undefined
  >();
  const [newMonthlyIptu, setMonthlyIptu] = useState<number | undefined>();

  const newValues = {
    salePrice: newSalePrice,
    communitySalePrice: newCommunitySalePrice,
    annualIptu: newAnnualIptu,
    rentPrice: newRentPrice,
    communityRentPrice: newCommunityRentPrice,
    monthlyIptu: newMonthlyIptu,
  };

  useEffect(() => {
    store.dispatch({
      type: STORE_VALUES,
      payload: newValues,
    });
    return () => {
      store.dispatch({
        type: STORE_VALUES,
        payload: {},
      });
    };
  }, [newValues]);

  const onChangeSale = (e: CheckboxChangeEvent) => {
    setSale(e.target.checked);
  };

  const onChangeRent = (e: CheckboxChangeEvent) => {
    setRent(e.target.checked);
  };

  return (
    <Card
      title="Valores do Imóvel"
      subtitle="Altere abaixo as informações referentes aos valores do imóvel"
    >
      <Row gutter={8} style={{ marginBottom: '15px' }}>
        <Col md={3}>
          <Checkbox onChange={onChangeSale} checked={sale}>
            Venda
          </Checkbox>
        </Col>
      </Row>
      {sale ? (
        <>
          <Row gutter={8}>
            <Col md={8}>
              <Form.Item
                label="Valor de Venda"
                name={['values', 'salePrice']}
                initialValue={values.salePrice}
              >
                <NumberFormat
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  prefix={'R$ '}
                  onValueChange={(value) => {
                    setSalePrice(value.floatValue);
                  }}
                  customInput={Input}
                />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item
                label="Valor do Condomínio"
                name={['values', 'communitySalePrice']}
                initialValue={values.communitySalePrice}
              >
                <NumberFormat
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  prefix={'R$ '}
                  onValueChange={(value) => {
                    setCommunitySalePrice(value.floatValue);
                  }}
                  displayType="input"
                  customInput={Input}
                />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item
                label="IPTU (Anual)"
                name={['values', 'annualIptu']}
                initialValue={values.annualIptu}
              >
                <NumberFormat
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  onValueChange={(value) => setAnnualIptu(value.floatValue)}
                  prefix={'R$ '}
                  displayType="input"
                  className="number-format"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col md={24}>
              <Form.Item
                label="Outras Taxas"
                name={['values', 'saleExtraTaxes']}
                initialValue={values.saleExtraTaxes}
              >
                <Input placeholder="Insira outras taxas de venda..." />
              </Form.Item>
            </Col>
          </Row>
        </>
      ) : null}

      <Row gutter={8} style={{ marginBottom: '15px' }}>
        <Col md={3}>
          <Checkbox onChange={onChangeRent} checked={rent}>
            Locação
          </Checkbox>
        </Col>
      </Row>
      {rent ? (
        <>
          <Row gutter={8}>
            <Col md={8}>
              <Form.Item
                label="Valor de Locação"
                name={['values', 'rentPrice']}
                rules={decorator.propertyPrice}
                initialValue={values.rentPrice}
              >
                <NumberFormat
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  onValueChange={(value) => setRentPrice(value.floatValue)}
                  prefix={'R$ '}
                  displayType="input"
                  className="number-format"
                />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item
                label="Valor do Condomínio"
                name={['values', 'communityRentPrice']}
                initialValue={values.communityRentPrice}
              >
                <NumberFormat
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  onValueChange={(value) =>
                    setCommunityRentPrice(value.floatValue)
                  }
                  prefix={'R$ '}
                  displayType="input"
                  className="number-format"
                />
              </Form.Item>
            </Col>
            <Col md={8}>
              <Form.Item
                label="IPTU (Mensal)"
                name={['values', 'monthlyIptu']}
                initialValue={values.monthlyIptu}
              >
                <NumberFormat
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  onValueChange={(value) => setMonthlyIptu(value.floatValue)}
                  prefix={'R$ '}
                  displayType="input"
                  className="number-format"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={8}>
            <Col md={24}>
              <Form.Item
                label="Outras Taxas"
                name={['values', 'rentExtraTaxes']}
                initialValue={values.rentExtraTaxes}
              >
                <Input placeholder="Insira as outras taxas do imóvel..." />
              </Form.Item>
            </Col>
          </Row>
        </>
      ) : null}
    </Card>
  );
};

export default PropertyValuesForm;
