import { Descriptions, Table } from 'antd';
import React from 'react';
import { Card } from 'src/components';
import TableActions from 'src/components/tableActions/tableActions.component';
import { IUser } from 'src/interfaces/user';
import { maritalStatus, table } from 'src/utils';

export interface IProps {
  owner: IUser;
}

const OwnerData: React.FC<IProps> = (props) => {
  const { owner } = props;
  const { Item } = Descriptions;

  const dataSource = owner.owner?.properties.map((property) => ({
    key: property.id,
    salePrice:
      property.values.salePrice?.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }) || 'Não Informado.',
    rentPrice:
      property.values.rentPrice?.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }) || 'Não Informado.',
    type: property.type,
    subtype: property.subtype,
  }));

  const columns = [
    {
      title: 'Preço de Venda',
      key: 'salePrice',
      dataIndex: 'salePrice',
    },
    {
      title: 'Preço de Locação',
      key: 'rentPrice',
      dataIndex: 'rentPrice',
    },
    {
      title: 'Tipo do Imóvel',
      key: 'type',
      dataIndex: 'type',
    },
    {
      title: 'Subtípo',
      key: 'subtype',
      dataIndex: 'subtype',
    },
    {
      title: 'Ações',
      key: 'actions',
      dataIndex: 'actions',
      render: (text: any, record: any) => (
        <TableActions view={{ link: `/painel/imoveis/${record.key}` }} />
      ),
    },
  ];

  return (
    <>
      <Card
        title="Proprietário(a)"
        subtitle="Veja aqui as informações do(a) Proprietário(a)"
      >
        <Descriptions title="Dados básicos" column={3}>
          {owner.name ? (
            <>
              <Item label="Nome"> {owner.name} </Item>
              <Item label="CPF"> {owner.owner?.cpf} </Item>
              <Item label="Data de nascimento"> {owner.birthdate} </Item>
              <Item label="Estado Civíl">
                {
                  maritalStatus.find(
                    (item) => item.value === owner.owner?.maritalStatus
                  )?.name
                }
              </Item>
              <Item label="Inscrição Estadual">
                {owner.owner?.stateRegistration || 'Não Informado.'}
              </Item>
            </>
          ) : (
            <>
              <Item label="Nome da empresa"> {owner.companyName} </Item>
              <Item label="CNPJ"> {owner.owner?.cnpj} </Item>
              <Item label="Inscrição Estadual">
                {owner.owner?.stateRegistration || 'Não Informado.'}
              </Item>
            </>
          )}
        </Descriptions>
        <br />
        <Descriptions title="Informações de Contato" column={2}>
          <Item label="E-mail"> {owner.email} </Item>
          <Item label="Telefone"> {owner.phone} </Item>
        </Descriptions>
        <br />
        <Descriptions title="Endereço">
          <Item label="Rua">{owner.owner?.address.street} </Item>
          <Item label="Número">{owner.owner?.address.number}</Item>
          <Item label="Bairro">{owner.owner?.address.neighborhood}</Item>
          <Item label="Complemento">
            {owner.owner?.address.complement || 'Não Informado.'}
          </Item>
          <Item label="Cidade">{owner.owner?.address.city}</Item>
          <Item label="Estado">{owner.owner?.address.state}</Item>
          <Item label="CEP">{owner.owner?.address.zipcode}</Item>
        </Descriptions>
      </Card>
      <Card
        title="Imóveis"
        subtitle="Veja aqui os imóveis do(a) proprietário(a) cadastrados no sistema."
      >
        <Table
          dataSource={dataSource}
          {...table}
          columns={columns}
          scroll={{ x: 500 }}
        />
      </Card>
    </>
  );
};

export default OwnerData;
