import { Form, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { FormFinish } from 'src/components';
import GoBack from 'src/components/GoBack/GoBack';
import Loading from 'src/components/Loading/loading.component';
import { IReduxState, store } from 'src/store';
import { fetchOwner, updateOwner } from 'src/store/owners/actions';
import { CLEAR_OWNER } from 'src/store/owners/types';
import CompanyUpdateForm from './components/updateOwner/companyUpdate.form';
import PersonUpdateForm from './components/updateOwner/personUpdate.form';

import { IParams, IProps } from './components/updateOwner/updateOwner.types';

const UpdateOwnerScreen: React.FC<IProps> = (props) => {
  const { owner } = props;
  const [sending, setSending] = useState(false);
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState('');

  useEffect(() => {
    const id = (props.match.params as IParams).id;

    props
      .fetchOwner(id)
      .then((res) => {
        document.title = `Proprietário(a): ${res.name || res.companyName}`;
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        message.error('Proprietário não encontrado.');
        setRedirect('/painel/proprietarios');
      });
    return () => {
      store.dispatch({
        type: CLEAR_OWNER,
      });
      document.title = 'Embrane | Admin';
    };
  }, []);

  const onFinish = (values: any) => {
    setSending(true);
    props
      .updateOwner(values)
      .then((res) => {
        setSending(false);
        setRedirect(`/proprietarios/${res.id}`);
      })
      .catch(() => {
        setSending(false);
      });
  };

  return (
    <>
      {!!redirect && <Redirect to={redirect} />}

      {!!owner && !loading ? (
        <>
          <GoBack />
          <Form layout="vertical" onFinish={onFinish}>
            {owner.owner?.cpf ? (
              <PersonUpdateForm owner={owner} />
            ) : (
              <CompanyUpdateForm owner={owner} />
            )}
            <FormFinish text="Atualizar" sending={sending} />
          </Form>
        </>
      ) : (
        <Loading enabled={loading} />
      )}
    </>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  owner: state.owners.owner,
});

export default connect(mapStateToProps, { fetchOwner, updateOwner })(
  UpdateOwnerScreen
);
