import { Col, Form, Input, Row, Select } from 'antd';
import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import { Card } from 'src/components';
import { IUser } from 'src/interfaces/user';
import { decorator, maritalStatus, states } from 'src/utils';

export interface IProps {
  owner: IUser;
}

const PersonUpdateForm: React.FC<IProps> = (props) => {
  const { Option } = Select;
  const [state, setState] = useState(false);
  const [cep, setCep] = useState('');
  const { owner } = props;

  const maskCep = '99.999-999';

  const handleCep = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (value.replace(/ /g, '').length === 9) {
      setCep(value);
    }
  };

  return (
    <Card
      title="Atualizar Proprietário(a)"
      subtitle="Altere os dados do(a) proprietário(a) abaixo."
    >
      <Row gutter={8}>
        <Col md={12}>
          <Form.Item
            label="Nome"
            name="name"
            initialValue={owner.name}
            rules={decorator.name}
          >
            <Input placeholder="Insira o nome..." />
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item
            label="E-mail"
            name="email"
            initialValue={owner.email}
            rules={decorator.email}
          >
            <Input placeholder="Insira o e-mail..." />
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item
            label="Telefone"
            name="phone"
            initialValue={owner.phone}
            rules={decorator.phone}
          >
            <InputMask maskChar="" mask="99 99999-9999">
              {(inputProps: string) => (
                <Input {...inputProps} placeholder="Insira o Telefone..." />
              )}
            </InputMask>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col md={6}>
          <Form.Item
            label="Data de Nascimento"
            name="birthdate"
            rules={decorator.birthdate}
            initialValue={owner.birthdate}
          >
            <InputMask maskChar="" mask="99/99/9999">
              {(inputProps: string) => (
                <Input
                  {...inputProps}
                  placeholder="Insira a data de nascimento..."
                />
              )}
            </InputMask>
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item
            label="Estado Civil"
            name="maritalStatus"
            rules={decorator.maritalStatus}
            initialValue={owner.owner?.maritalStatus}
          >
            <Select placeholder="Insira o Estado Civil...">
              {maritalStatus.map((item) => (
                <Option key={item.value} value={item.value}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item
            label="Profissão"
            name="profession"
            rules={decorator.profession}
            initialValue={owner.owner?.profession}
          >
            <Input placeholder="Insira a profissão..." />
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item
            label="CPF"
            name="cpf"
            initialValue={owner.owner?.cpf}
            rules={decorator.cpf}
          >
            <InputMask maskChar="" mask="999.999.999-99">
              {(inputProps: string) => (
                <Input {...inputProps} placeholder="Insira o CPF..." />
              )}
            </InputMask>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col md={4}>
          <Form.Item
            label="CEP"
            name={['address', 'zipcode']}
            rules={decorator.zipcode}
            initialValue={owner.owner?.address.zipcode}
          >
            <InputMask maskChar="" mask={maskCep} onChange={handleCep}>
              {(inputProps: string) => (
                <Input {...inputProps} placeholder="Insira o CEP..." />
              )}
            </InputMask>
          </Form.Item>
        </Col>
        <Col md={2}>
          <Form.Item
            label="Estado"
            name={['address', 'state']}
            rules={decorator.state}
            initialValue={owner.owner?.address.state}
          >
            <Select>
              {states.map((item) => (
                <Option key={item.key} value={item.uf}>
                  {item.uf}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item
            label="Cidade"
            name={['address', 'city']}
            rules={decorator.city}
            initialValue={owner.owner?.address.city}
          >
            <Input placeholder="Insira a cidade..." />
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item
            label="Bairro"
            name={['address', 'neighborhood']}
            rules={decorator.neighborhood}
            initialValue={owner.owner?.address.neighborhood}
          >
            <Input placeholder="Insira o bairro..." />
          </Form.Item>
        </Col>

        <Col md={6}>
          <Form.Item
            label="Rua"
            name={['address', 'street']}
            rules={decorator.street}
            initialValue={owner.owner?.address.street}
          >
            <Input placeholder="Insira a rua..." />
          </Form.Item>
        </Col>
        <Col md={4}>
          <Form.Item
            label="Número"
            name={['address', 'number']}
            rules={decorator.streetNumber}
            initialValue={owner.owner?.address.number}
          >
            <Input placeholder="Insira o número..." />
          </Form.Item>
        </Col>
        <Col md={8}>
          <Form.Item
            label="Complemento"
            name={['address', 'complement']}
            rules={decorator.complement}
            initialValue={owner.owner?.address.complement}
          >
            <Input placeholder="(Opcional) Insira um complemento..." />
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item label="Senha" name="password" rules={decorator.password}>
            <Input placeholder="Insira a senha" type="password" />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

export default PersonUpdateForm;
