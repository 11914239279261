import { Rule } from 'antd/lib/form';

const name: Rule[] = [
  {
    required: true,
    message: 'Campo obrigatório.',
  },
  {
    type: 'string',
  },
];

const companyName: Rule[] = [
  {
    required: true,
    message: 'Campo obrigatório.',
  },
];

const password: Rule[] = [
  {
    required: true,
    message: 'Campo obrigatório.',
  },
  {
    type: 'string',
    min: 6,
    message: 'A senha deve ter no mínimo seis caracteres',
  },
];

const email: Rule[] = [
  {
    required: true,
    message: 'Campo obrigatório.',
  },
  {
    type: 'email',
    message: 'Por favor insira um e-mail válido.',
  },
];

const phone: Rule[] = [
  {
    required: true,
    message: 'Campo obrigatório.',
  },
];

const birthdate: Rule[] = [
  {
    required: true,
    message: 'Campo obrigatório.',
  },
];

const maritalStatus: Rule[] = [
  {
    required: true,
    message: 'Campo obrigatório.',
  },
];

const profession: Rule[] = [
  {
    required: true,
    message: 'Campo obrigatório.',
  },
  {
    type: 'string',
  },
];

const state: Rule[] = [
  {
    required: true,
    message: 'Campo obrigatório',
  },
];

const creci: Rule[] = [
  {
    required: true,
    message: 'Campo obrigatório.',
  },
];

const cpf: Rule[] = [
  {
    required: true,
    message: 'Campo obrigatório.',
  },
];

const cnpj: Rule[] = [
  {
    required: true,
    message: 'Campo obrigatório.',
  },
];

const zipcode: Rule[] = [
  {
    required: true,
    message: 'Campo Obrigatório.',
  },
  {
    type: 'string',
  },
];

const city: Rule[] = [
  {
    required: true,
    message: 'Campo obrigatório',
  },
];

const neighborhood: Rule[] = [
  {
    required: true,
    message: ' Campo obrigatório.',
  },
];

const street: Rule[] = [
  {
    required: true,
    message: 'Campo obrigatório.',
  },
];

const streetNumber: Rule[] = [
  {
    required: true,
    message: 'Campo obrigatório.',
  },
];

const complement: Rule[] = [
  {
    required: false,
  },
];

const complex: Rule[] = [
  {
    required: false,
  },
];

const latitude: Rule[] = [
  {
    required: false,
  },
];

const longitude: Rule[] = [
  {
    required: false,
  },
];

const zone: Rule[] = [
  {
    required: true,
    message: 'Campo obrigatório',
  },
];

const propertyPrice: Rule[] = [
  {
    required: true,
    message: 'Campo obrigatório.',
  },
];

const rooms: Rule[] = [
  {
    required: true,
    message: 'Campo obrigatório.',
  },
  {
    type: 'integer',
    message: 'Insira um valor inteiro.',
  },
];

const suites: Rule[] = [
  {
    required: true,
    message: 'Campo obrigatório',
  },
  {
    type: 'integer',
    message: 'Por favor insira um valor inteiro.',
  },
];

const livingRooms: Rule[] = [
  {
    required: true,
    message: 'Campo obrigatório',
  },
  {
    type: 'integer',
    message: 'Por favor insira um valor inteiro.',
  },
];

const bathrooms: Rule[] = [
  {
    required: true,
    message: 'Campo obrigatório',
  },
  {
    type: 'integer',
    message: 'Por favor insira um valor inteiro.',
  },
];

const balconies: Rule[] = [
  {
    required: true,
    message: 'Campo obrigatório',
  },
  {
    type: 'integer',
    message: 'Por favor insira um valor inteiro.',
  },
];

const kitchens: Rule[] = [
  {
    required: true,
    message: 'Campo obrigatório',
  },
  {
    type: 'integer',
    message: 'Por favor insira um valor inteiro.',
  },
];

const toilets: Rule[] = [
  {
    required: true,
    message: 'Campo obrigatório',
  },
  {
    type: 'integer',
    message: 'Por favor insira um valor inteiro.',
  },
];

const dependencies: Rule[] = [
  {
    required: true,
    message: 'Campo obrigatório',
  },
  {
    type: 'integer',
    message: 'Por favor insira um valor inteiro.',
  },
];

const technicalAreas: Rule[] = [
  {
    required: true,
    message: 'Campo obrigatório',
  },
  {
    type: 'integer',
    message: 'Por favor insira um valor inteiro.',
  },
];

const garages: Rule[] = [
  {
    required: true,
    message: 'Campo obrigatório',
  },
  {
    type: 'integer',
    message: 'Por favor insira um valor inteiro.',
  },
];

const rural: Rule[] = [
  { required: true, message: 'Campo obrigatório.' },
  { type: 'number', message: 'Por favor insira um número exato.' },
];

const acres: Rule[] = [
  {
    required: true,
    message: 'Campo Obrigatório.',
  },
];

const propertyType: Rule[] = [
  {
    required: true,
    message: 'Campo obrigatório.',
  },
];

const propertySubType: Rule[] = [
  {
    required: true,
    message: 'Campo obrigatório.',
  },
];

const columns: Rule[] = [
  {
    required: true,
    message: 'Campo obrigatório.',
  },
];

const area: Rule[] = [
  {
    required: true,
    message: 'Campo obrigatório.',
  },
  {
    type: 'number',
    message: 'Por favor insira um valor exato.',
  },
];

const exclusivity: Rule[] = [
  {
    required: true,
    message: 'Campo obrigatório.',
  },
];

const deadline: Rule[] = [
  {
    required: true,
    message: 'Campo obrigatório',
  },
];

const observation: Rule[] = [
  {
    required: false,
  },
];

const propertyDocuments: Rule[] = [
  {
    required: true,
    message: 'Por favor selecione um documento.',
  },
];

const images: Rule[] = [
  {
    required: true,
    message: 'Selecione as imagens do seu imóvel.',
  },
];

const headquarters: Rule[] = [
  {
    required: true,
    message: 'Campo obrigatório.',
  },
];

const apartmentNumber: Rule[] = [
  {
    required: true,
    message: 'Por favor forneça o número do seu apartamento',
  },
];

const availability: Rule[] = [
  {
    required: true,
    message: 'Especifique a disponibilidade do imóvel',
  },
];

const decorator = {
  birthdate,
  zipcode,
  headquarters,
  city,
  companyName,
  complement,
  latitude,
  longitude,
  cpf,
  cnpj,
  creci,
  email,
  name,
  profession,
  complex,
  maritalStatus,
  password,
  phone,
  propertyPrice,
  state,
  rural,
  rooms,
  suites,
  livingRooms,
  bathrooms,
  balconies,
  kitchens,
  toilets,
  dependencies,
  technicalAreas,
  garages,
  neighborhood,
  street,
  streetNumber,
  zone,
  propertyType,
  propertySubType,
  columns,
  area,
  exclusivity,
  deadline,
  observation,
  propertyDocuments,
  images,
  acres,
  apartmentNumber,
  availability,
};

export default decorator;
