import { IUser } from 'src/interfaces/user';

export const SIGN_IN = 'SIGN_IN';
export const FETCH_DATA = 'FETCH_DATA';
export const LOGOUT_USER = 'LOGOUT_USER';

export interface IReducerAuthState {
  user?: IUser;
}

export interface ISignIn {
  type: typeof SIGN_IN;
  payload: IUser;
}

export interface IFetchData {
  type: typeof FETCH_DATA;
}

export interface ILogoutUser {
  type: typeof LOGOUT_USER;
}

export type AuthActionTypes = ISignIn | IFetchData | ILogoutUser;
