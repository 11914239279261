import React from 'react';
import { connect } from 'react-redux';

import { IProps } from './main.type';
import { Card } from 'src/components';
import { IReduxState } from 'src/store';
import MapComponent from 'src/components/Maps/map.component';

const MainScreen: React.FC<IProps> = (props) => {
  return (
    <Card title="Informações">
      <p>
        Olá <b>{props.user!.name}!</b> Seja bem-vindo(a) ao sistema de
        administração da <b>Embrane.</b>
      </p>
    </Card>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(MainScreen);
