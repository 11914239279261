import React, { useState } from 'react';
import { Row, Col, Form, Input, Select } from 'antd';
import { decorator } from 'src/utils';
import InputMask from 'react-input-mask';
import { states } from 'src/utils/';

interface IProps {
  onSearch: (value: string, event: any) => void;
  loading: boolean;
}

const PersonForm: React.FC<IProps> = (props) => {
  const [phone, setPhone] = useState('');
  const [birthdate, setBirthdate] = useState('');
  const [creci, setCreci] = useState('');
  const [state, setState] = useState('');
  const [cep, setCep] = useState('');

  const maskCep = '99999-999';

  const { onSearch, loading } = props;

  const { Option } = Select;
  const { Search } = Input;

  const handleCep = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (value.replace(/ /g, '').length === 9) {
      setCep(value);
    }
  };

  const handleState = (e: any, option: any) => {
    setState(e);
  };

  const handleBirthdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (value.replace(/ /g, '').length === 10) {
      setBirthdate('99/99/9999');
    }
  };

  const handlePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (value.replace(/ /g, '').length === 14) {
      setPhone('99 99999-9999');
    }
  };
  return (
    <>
      <Row gutter={8}>
        <Col md={10}>
          <Form.Item name="name" label="Nome" rules={decorator.name} required>
            <Input placeholder="Insira o nome completo..." />
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item
            rules={decorator.email}
            name="email"
            label="E-mail"
            required
          >
            <Input placeholder="Insira o e-mail..." />
          </Form.Item>
        </Col>
        <Col md={4}>
          <Form.Item
            rules={decorator.phone}
            name="phone"
            label="Telefone"
            required
          >
            <InputMask
              mask="99 99999-9999"
              value={phone}
              onChange={handlePhone}
              maskChar=""
            >
              {(inputProps: string) => (
                <Input {...inputProps} placeholder="Insira o telefone..." />
              )}
            </InputMask>
          </Form.Item>
        </Col>
        <Col md={4}>
          <Form.Item name="birthdate" label="Data de Nascimento">
            <InputMask
              mask="99/99/9999"
              value={birthdate}
              onChange={handleBirthdate}
              maskChar=""
            >
              {(inputProps: string) => (
                <Input
                  {...inputProps}
                  placeholder="Insira a data de nascimento..."
                />
              )}
            </InputMask>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col md={4}>
          <Form.Item
            name={['address', 'state']}
            rules={decorator.state}
            label="Estado"
            required
          >
            <Select onChange={handleState} className="select">
              {states.map((item) => (
                <Option key={item.key} value={item.uf}>
                  {item.uf}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item
            name="creci"
            label="CRECI"
            rules={decorator.creci}
            required
          >
            <InputMask mask={`${state}-999999/O`} maskChar="" value={creci}>
              {(inputProps: string) => (
                <Input {...inputProps} placeholder="Insira o CRECI..." />
              )}
            </InputMask>
          </Form.Item>
        </Col>

        <Col md={6}>
          <Form.Item name="cpf" label="CPF" rules={decorator.cpf} required>
            <InputMask maskChar="" mask="999.999.999-99">
              {(inputProps: string) => (
                <Input {...inputProps} placeholder="Insira o CPF..." />
              )}
            </InputMask>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col md={4}>
          <Form.Item
            name={['address', 'zipcode']}
            label="CEP"
            rules={decorator.zipcode}
            required
          >
            <InputMask
              maskChar=""
              mask={maskCep}
              value={cep}
              onChange={handleCep}
            >
              {(inputProps: string) => (
                <Search
                  onSearch={onSearch}
                  loading={loading}
                  {...inputProps}
                  placeholder="Insira o CEP..."
                />
              )}
            </InputMask>
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item
            name={['address', 'city']}
            label="Cidade"
            rules={decorator.city}
            required
          >
            <Input placeholder="Insira a cidade..." />
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item
            name={['address', 'neighborhood']}
            rules={decorator.neighborhood}
            label="Bairro"
            required
          >
            <Input placeholder="Insira o bairro..." />
          </Form.Item>
        </Col>
        <Col md={4}>
          <Form.Item
            name={['address', 'street']}
            label="Rua"
            rules={decorator.street}
            required
          >
            <Input placeholder="Insira a rua..." />
          </Form.Item>
        </Col>
        <Col md={4}>
          <Form.Item
            name={['address', 'number']}
            rules={decorator.streetNumber}
            label="Número"
            required
          >
            <Input placeholder="Insira o número..." />
          </Form.Item>
        </Col>
        <Col md={10}>
          <Form.Item name={['address', 'complement']} label="Complemento">
            <Input placeholder="(Opcional) Insira um complemento..." />
          </Form.Item>
        </Col>

        <Col md={6}>
          <Form.Item
            name="password"
            label="Senha"
            rules={decorator.password}
            required
          >
            <Input
              type="password"
              placeholder="Insira a senha do corretor..."
            />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default PersonForm;
