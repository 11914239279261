import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Table, notification } from 'antd';

import { Card } from 'src/components';
import TableActions from 'src/components/tableActions/tableActions.component';
import { table } from 'src/utils';
import { fetchBrokers, removeBroker } from 'src/store/broker/actions';
import { IReduxState } from 'src/store';

import { IProps } from './components/types/brokers.types';

const BrokersScreen: React.FC<IProps> = (props) => {
  const [loading, setLoading] = useState(true);
  const { brokers } = props;

  const approvedUsers = brokers?.filter(
    (broker) => broker.broker?.status === 'approved'
  );

  useEffect(() => {
    props.fetchBrokers();
    setLoading(false);
  }, []);

  const remove = (userId: string) => {
    props
      .removeBroker(userId)
      .then((res) => {
        notification.success({ message: 'Corretor removido com sucesso.' });
      })
      .finally(() => {
        setLoading(false);
        props.fetchBrokers();
      });
  };

  const dataSource =
    approvedUsers &&
    approvedUsers.map((user) => ({
      key: user.id,
      names: user.companyName || user.name,
      creci: user.broker?.creci,
      document: user.broker?.cpf || user.broker?.cnpj,
      email: user.email,
      state: `${user.broker?.address.city}-${user.broker?.address.state}`,
    }));

  const columns = [
    {
      title: 'Nome/Empresa',
      key: 'names',
      dataIndex: 'names',
    },
    {
      title: 'CPF/CNPJ',
      key: 'document',
      dataIndex: 'document',
    },
    {
      title: 'CRECI',
      key: 'creci',
      dataIndex: 'creci',
    },

    {
      title: 'Cidade-UF',
      key: 'state',
      dataIndex: 'state',
    },
    {
      title: 'Ações',
      dataIndex: 'actions',
      key: 'actions',
      render: (text: any, record: any) => (
        <>
          <TableActions
            loading={loading}
            view={{ link: `/painel/corretores/${record.key}` }}
            edit={{ link: `/painel/corretores/editar/${record.key}` }}
            remove={{ action: () => remove(record.key) }}
          />
        </>
      ),
    },
  ];

  return (
    <Card title="Corretores" subtitle="Veja aqui os corretores cadastrados.">
      {loading ? (
        <p>Carregando...</p>
      ) : (
        <Table dataSource={dataSource} {...table} columns={columns} />
      )}
    </Card>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  brokers: state.brokers.brokers,
});

export default connect(mapStateToProps, { fetchBrokers, removeBroker })(
  BrokersScreen
);
