import styled from 'styled-components';

export const LocateContainer = styled.div`
  position: absolute;
  z-index: 1000;
  bottom: 45%;
  right: 40px;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    color: #666666;
    border-radius: 8px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
    width: 29px;
    height: 29px;
    font-size: 18px;
    overflow: hidden;
    cursor: pointer;
    outline: none;
    border: none;
    transition: 0.18s ease-out;
    &:hover {
      color: var(--app-terciary-color);
    }
    &:focus,
    &:active {
      color: #5896e4;
    }
  }
`;
