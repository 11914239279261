import React, { useCallback } from 'react';
import FeatherIcons from 'feather-icons-react';
import { useHistory } from 'react-router-dom';
import { GoBackButton } from './styles';

const GoBack: React.FC = () => {
  const history = useHistory();

  const handleGoBack = useCallback(() => history.goBack(), [history]);

  return (
    <GoBackButton onClick={handleGoBack}>
      <FeatherIcons icon="arrow-left-circle" />
      Voltar
    </GoBackButton>
  );
};

export default GoBack;
