import { Rule } from 'antd/lib/form';

export const decoratorEmail: Rule[] = [
  {
    required: true,
    message: 'Campo Obrigatório',
  },
  {
    type: 'email',
    message: 'Por favor insira um e-mail válido.',
  },
];

export const decoratorPassword: Rule[] = [
  {
    required: true,
    message: 'Campo Obrigatório',
  },
];
