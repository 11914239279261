import React, { useState } from 'react';
import { Col, Form, Input, Radio, Row, Select } from 'antd';
import InputMask from 'react-input-mask';
import { decorator, maritalStatus, states } from 'src/utils';

interface IProps {
  onSearch: (value: string, event: any) => void;
  loading: boolean;
}

const PersonForm: React.FC<IProps> = (props) => {
  const { Option } = Select;
  const [state, setState] = useState(false);
  const [cep, setCep] = useState('');

  const maskCep = '99999-999';
  const { Search } = Input;
  const { onSearch, loading } = props;

  const handleCep = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (value.replace(/ /g, '').length === 9) {
      setCep(value);
    }
  };

  return (
    <>
      <Row gutter={8}>
        <Col md={12}>
          <Form.Item label="Nome" name="name" rules={decorator.name}>
            <Input placeholder="Insira o nome..." />
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item label="E-mail" name="email" rules={decorator.email}>
            <Input placeholder="Insira o e-mail..." />
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item label="Telefone" name="phone" rules={decorator.phone}>
            <InputMask maskChar="" mask="99 99999-9999">
              {(inputProps: string) => (
                <Input {...inputProps} placeholder="Insira o Telefone..." />
              )}
            </InputMask>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col md={6}>
          <Form.Item
            label="Data de Nascimento"
            name="birthdate"
            rules={decorator.birthdate}
          >
            <InputMask maskChar="" mask="99/99/9999">
              {(inputProps: string) => (
                <Input
                  {...inputProps}
                  placeholder="Insira a data de nascimento..."
                />
              )}
            </InputMask>
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item
            label="Estado Civil"
            name="maritalStatus"
            rules={decorator.maritalStatus}
          >
            <Select placeholder="Insira o Estado Civil...">
              {maritalStatus.map((item) => (
                <Option key={item.value} value={item.value}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item
            label="Profissão"
            name="profession"
            rules={decorator.profession}
          >
            <Input placeholder="Insira a profissão..." />
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item label="CPF" name="cpf" rules={decorator.cpf}>
            <InputMask maskChar="" mask="999.999.999-99">
              {(inputProps: string) => (
                <Input {...inputProps} placeholder="Insira o CPF..." />
              )}
            </InputMask>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8}>
        <Col md={4}>
          <Form.Item
            label="CEP"
            name={['address', 'zipcode']}
            rules={decorator.zipcode}
          >
            <InputMask maskChar="" mask={maskCep} onChange={handleCep}>
              {(inputProps: string) => (
                <Search
                  {...inputProps}
                  placeholder="Insira o CEP..."
                  onSearch={onSearch}
                  loading={loading}
                />
              )}
            </InputMask>
          </Form.Item>
        </Col>
        <Col md={6} lg={6} xs={24} sm={24}>
          <Form.Item
            label="Estado"
            name={['address', 'state']}
            rules={decorator.state}
          >
            <Select>
              {states.map((item) => (
                <Option key={item.key} value={item.uf}>
                  {item.uf}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item
            label="Cidade"
            name={['address', 'city']}
            rules={decorator.city}
          >
            <Input placeholder="Insira a cidade..." />
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item
            label="Bairro"
            name={['address', 'neighborhood']}
            rules={decorator.neighborhood}
          >
            <Input placeholder="Insira o bairro..." />
          </Form.Item>
        </Col>

        <Col md={6}>
          <Form.Item
            label="Rua"
            name={['address', 'street']}
            rules={decorator.street}
          >
            <Input placeholder="Insira a rua..." />
          </Form.Item>
        </Col>
        <Col md={4}>
          <Form.Item
            label="Número"
            name={['address', 'number']}
            rules={decorator.streetNumber}
          >
            <Input placeholder="Insira o número..." />
          </Form.Item>
        </Col>
        <Col md={8}>
          <Form.Item
            label="Complemento"
            name={['address', 'complement']}
            rules={decorator.complement}
          >
            <Input placeholder="(Opcional) Insira um complemento..." />
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item label="Senha" name="password" rules={decorator.password}>
            <Input placeholder="Insira a senha" type="password" />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

export default PersonForm;
