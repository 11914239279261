import { Checkbox, Col, Divider, Form, Input, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { IValues } from 'src/interfaces/misc';
import { store } from 'src/store';
import { STORE_VALUES } from 'src/store/properties/types';

import { decorator } from 'src/utils';

const PriceForm: React.FC = () => {
  const [sale, setSale] = useState(false);
  const [rent, setRent] = useState(false);
  const [salePrice, setSalePrice] = useState<number | undefined>();
  const [rentPrice, setRentPrice] = useState<number | undefined>();
  const [communitySalePrice, setCommunitySalePrice] = useState<
    number | undefined
  >();
  const [communityRentPrice, setCommunityRentPrice] = useState<
    number | undefined
  >();
  const [annualIptu, setAnnualIptu] = useState<number | undefined>();
  const [monthlyIptu, setMonthlyIptu] = useState<number | undefined>();

  const { Item } = Form;

  const values: IValues = {
    salePrice,
    rentPrice,
    communitySalePrice,
    communityRentPrice,
    annualIptu,
    monthlyIptu,
  };

  useEffect(() => {
    store.dispatch({
      type: STORE_VALUES,
      payload: values,
    });
  }, [values]);

  const onChangeSale = (e: any) => {
    setSale(e.target.checked);
    if (!e.target.checked) {
      setSalePrice(undefined);
      setCommunitySalePrice(undefined);
      setAnnualIptu(undefined);
    }
  };

  const onChangeRent = (e: any) => {
    setRent(e.target.checked);
    if (!e.target.checked) {
      setRentPrice(undefined);
      setCommunityRentPrice(undefined);
      setMonthlyIptu(undefined);
    }
  };

  return (
    <>
      <Divider />
      <h3>Valores do Imóvel</h3>
      <Row gutter={16}>
        <Col md={6} lg={6} xs={24} sm={12}>
          <Checkbox onChange={onChangeSale} checked={sale}>
            Venda
          </Checkbox>
        </Col>
      </Row>
      {sale ? (
        <>
          <Row gutter={16}>
            <Col md={8} lg={8} xs={24} sm={12}>
              <Item
                label="Valor de Venda"
                name={['property', 'values', 'salePrice']}
                rules={decorator.propertyPrice}
                preserve={false}
              >
                <NumberFormat
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  prefix={'R$ '}
                  onValueChange={(value) => setSalePrice(value.floatValue)}
                  decimalScale={2}
                  customInput={Input}
                />
              </Item>
            </Col>
            <Col md={8} lg={8} xs={24} sm={12}>
              <Item
                preserve={false}
                label="Valor do Condomínio"
                name={['property', 'values', 'communitySalePrice']}
              >
                <NumberFormat
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  prefix={'R$ '}
                  onValueChange={(value) =>
                    setCommunitySalePrice(value.floatValue)
                  }
                  decimalScale={2}
                  customInput={Input}
                />
              </Item>
            </Col>
            <Col md={8} lg={8} xs={24} sm={12}>
              <Item
                preserve={false}
                label="IPTU (Anual)"
                name={['property', 'values', 'annualIptu']}
              >
                <NumberFormat
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  prefix={'R$ '}
                  onValueChange={(value) => setAnnualIptu(value.floatValue)}
                  decimalScale={2}
                  customInput={Input}
                />
              </Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={24} lg={24} xs={24} sm={24}>
              <Item
                preserve={false}
                label="Taxas extras"
                name={['property', 'values', 'saleExtraTaxes']}
              >
                <Input />
              </Item>
            </Col>
          </Row>
        </>
      ) : null}

      <Row gutter={16}>
        <Col md={6} lg={6} xs={24} sm={12}>
          <Checkbox onChange={onChangeRent} checked={rent}>
            Locação
          </Checkbox>
        </Col>
      </Row>
      {rent ? (
        <>
          <Row gutter={16}>
            <Col md={8} lg={8} xs={24} sm={12}>
              <Item
                preserve={false}
                label="Valor de Locação"
                name={['property', 'values', 'rentPrice']}
                rules={decorator.propertyPrice}
              >
                <NumberFormat
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  prefix={'R$ '}
                  onValueChange={(value) => setRentPrice(value.floatValue)}
                  decimalScale={2}
                  customInput={Input}
                />
              </Item>
            </Col>
            <Col md={8} lg={8} xs={24} sm={12}>
              <Item
                preserve={false}
                label="Valor do Condomínio"
                name={['property', 'values', 'communityRentPrice']}
              >
                <NumberFormat
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  prefix={'R$ '}
                  onValueChange={(value) =>
                    setCommunityRentPrice(value.floatValue)
                  }
                  decimalScale={2}
                  customInput={Input}
                />
              </Item>
            </Col>
            <Col md={8} lg={8} xs={24} sm={12}>
              <Item
                preserve={false}
                label="IPTU (Mensal)"
                name={['property', 'values', 'iptuMonthly']}
              >
                <NumberFormat
                  thousandSeparator={'.'}
                  decimalSeparator={','}
                  prefix={'R$ '}
                  onValueChange={(value) => setMonthlyIptu(value.floatValue)}
                  decimalScale={2}
                  customInput={Input}
                />
              </Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col md={24} lg={24} xs={24} sm={24}>
              <Item
                preserve={false}
                label="Taxas extras"
                name={['property', 'rentExtraTaxes']}
              >
                <Input />
              </Item>
            </Col>
          </Row>
        </>
      ) : null}
    </>
  );
};

export default PriceForm;
