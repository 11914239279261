import { Dispatch } from 'react';
import {
  OwnerActionTypes,
  FETCH_OWNERS,
  CREATE_OWNER,
  REMOVE_OWNER,
  FETCH_OWNER,
  UPDATE_OWNER,
  ADMIN_CREATE_OWNER,
} from './types';
import OwnersServices from 'src/services/owners';
import { ICreateOwnerInput, IUpdateOwnerInput } from 'src/interfaces/owner';
import { forms } from 'src/utils';

export const fetchOwners = () => (dispatch: Dispatch<OwnerActionTypes>) => {
  return OwnersServices.fetchOwners()
    .then((res) => {
      dispatch({
        type: FETCH_OWNERS,
        payload: res,
      });
      return res;
    })
    .catch((err) => {
      throw Error;
    });
};

export const fetchOwner = (id: string) => (
  dispatch: Dispatch<OwnerActionTypes>
) => {
  return OwnersServices.fetchOwner(id).then((res) => {
    dispatch({
      type: FETCH_OWNER,
      payload: res,
    });
    return res;
  });
};

export const createOwner = (input: ICreateOwnerInput) => (
  dispatch: Dispatch<OwnerActionTypes>
) => {
  return OwnersServices.createOwner(input)
    .then((res) => {
      dispatch({
        type: CREATE_OWNER,
        payload: res,
      });
      return res;
    })
    .catch((err) => {
      forms.errors(err);
      throw Error;
    });
};

// export const adminCreateOwner = (input: ICreateOwnerInput) => (
//   dispatch: Dispatch<OwnerActionTypes>
// ) => {
//   return OwnersServices.adminCreateOwner(input)
//     .then((res) => {
//       dispatch({
//         type: ADMIN_CREATE_OWNER,
//         payload: res,
//       });
//       return res;
//     })
//     .catch((err) => {
//       forms.errors(err);
//       throw Error;
//     });
// };

export const removeOwner = (userId: string) => (
  dispatch: Dispatch<OwnerActionTypes>
) => {
  return OwnersServices.removeOwner(userId).then((res) => {
    dispatch({
      type: REMOVE_OWNER,
    });
    return res;
  });
};

export const updateOwner = (input: IUpdateOwnerInput) => (
  dispatch: Dispatch<OwnerActionTypes>
) => {
  return OwnersServices.updateOwner(input)
    .then((res) => {
      dispatch({
        type: UPDATE_OWNER,
        payload: res,
      });
      return res;
    })
    .catch((err) => {
      forms.errors(err);
      throw Error;
    });
};
