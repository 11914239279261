import { Button } from 'antd';
import React, { useState } from 'react';
import FeatherIcon from 'feather-icons-react';

import { Card } from 'src/components';
import { IProps } from './formFinish.types';

const FormFinish: React.FC<IProps> = (props) => {
  const { sending, text } = props;
  return (
    <Card
      title="Finalizar"
      subtitle={`Por favor, revise todos os dados antes de cadastrar.`}
    >
      {sending ? (
        <Button className="btn-success" loading />
      ) : (
        <Button className="btn-success" htmlType="submit">
          <FeatherIcon icon="check" />
          {text}
        </Button>
      )}
    </Card>
  );
};

export default FormFinish;
