import { Col, Divider, Row, Upload, Form } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import {
  RcFile,
  UploadChangeParam,
  UploadFile,
} from 'antd/lib/upload/interface';
import React, { useState } from 'react';
import { FaPlusCircle } from 'react-icons/fa';
import { store } from 'src/store';
import { STORE_IMAGES } from 'src/store/properties/types';
import { decorator } from 'src/utils';

const ImagesForm: React.FC = () => {
  const [images, setImages] = useState<UploadFile[]>([]);
  const [previewImage, setPreviewImage] = useState('');
  const [previewImageTitle, setPreviewImageTitle] = useState('');
  const [previewVisible, setPreviewVisible] = useState(false);

  const { Item } = Form;

  const beforeUpload = (file: RcFile) => {
    return false;
  };

  const getBase64 = (file: File) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const onChangeImages = ({ fileList }: UploadChangeParam) => {
    setImages(fileList);
    const arrayImages: any = [];
    fileList.forEach((image) => {
      arrayImages.push(image.originFileObj);
    });
    store.dispatch({
      type: STORE_IMAGES,
      payload: arrayImages,
    });
  };

  const handleCancel = () => {
    return setPreviewVisible(false);
  };

  // file: UploadFile
  const onPreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
      console.log(file.preview);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewImageTitle(
      file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    );
  };

  const uploadButton = (
    <div>
      <FaPlusCircle />
      <div style={{ marginTop: 8 }}>Adicionar Imagens</div>
    </div>
  );

  return (
    <>
      <Divider />
      <h3>Imagens do Imóvel</h3>
      <Row gutter={16}>
        <Col md={24} lg={24} sm={24} xs={24}>
          <Item label="Imagens" name="images" rules={decorator.images}>
            <Upload
              onChange={onChangeImages}
              fileList={images}
              multiple
              listType="picture-card"
              onPreview={onPreview}
              beforeUpload={beforeUpload}
              accept=".png,.jpg,.jpeg,.webp,.raw,.tiff,.svg"
            >
              {FileList.length < 25 ? uploadButton : null}
            </Upload>
          </Item>
          <Modal
            visible={previewVisible}
            title={previewImageTitle}
            footer={null}
            onCancel={handleCancel}
          >
            <img
              src={previewImage}
              alt="Imagens do Imóvel"
              style={{ width: '100%' }}
            />
          </Modal>
        </Col>
      </Row>
    </>
  );
};

export default ImagesForm;
