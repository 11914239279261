import styled from "styled-components";
import { colors } from 'src/utils/index';


export const Container = styled.div`
  h1 {
    color:rgb(51, 53, 60);
    margin-left: 10px;
  }
`;

export const ImageMask = styled.span`
  margin: 25px 10px;
  display: inline-block;
  font-size: 0;
  position: relative;
  img {
    width: 150px;
    height: 100px;
    &:hover {
      opacity: 0.9;
      
    }
    object-fit: contain;
    
  }
`;


export const AreaContainer = styled.div`

  h1 {
    font-size: 16px;
    line-height: 1.5715;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
    padding-bottom: 10px;
    margin-left: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  }
  span {
    display: flex;
    margin: 15px 0;
  }
  margin-bottom: 35px;

`;

export const Confrontations = styled.div`
  margin-top: 12px;
  display: grid;
  grid-template-columns: 3fr 3fr;
  grid-template-rows: 36px 60px;
  @media ( max-width: 576px) {
    display: flex;
    flex-direction: column;

  }
  h1 {
    font-weight: 500;
    color: #000;  
    margin-left: 0;
  }
  
  span {
    margin: 15px 0;
    
  }
  margin-bottom: 35px;
`;

export const ConfrontationsTitle = styled.h1`
    color:rgb(51, 53, 60);
    grid-column-start: 1;
    grid-column-end: 3;
`;


export const NotFound = styled.div`
  h1 {
    margin-top: 5px;
    margin-left: 0px;
    font-weight: bold;
    font-size: 0.8rem !important;
    color: rgb(219, 74, 57) !important;
  }
`;



export const Title = styled.p`
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 10px;

`;

export const ImageNotFound = styled.span`
  height: 100px;
  margin: 15px;
  width: 150px;
  display: inline-block;
  border: 1px dashed rgb(125, 125, 125);
  background: rgb(240, 240, 240, 1);
  margin-bottom: none;
  position: relative;
  &:hover {
    background: rgb(240, 240, 240, 0.4);
  }
  
  svg {
    width: 100%;
    height: 100%;
    color: #bdbdbd;
  }

`;

export default  {
  h1: {
    textAlign: 'center',
    marginBottom: '30px',
    fontSize: '32px',
  } as React.CSSProperties,

  a: {
    textAlign: 'center',
    margin: '15px 10px',
    fontSize: '16px',
  } as React.CSSProperties,

  title: {
    fontSize: '16px',
    lineHeight: '1.5715',
    color: 'rgba(0, 0, 0, 0.85)',
    fontWeight: 500,
    paddingBottom: '10px',
    marginLeft: '0', 
    borderBottom: '1px solid rgba(0, 0, 0, 0.4)',
  } as React.CSSProperties,
}