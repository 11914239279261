import { Col, Form, Input, InputNumber, Radio, Row } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import React, { useState } from 'react';
import { Card } from 'src/components';
import { ICaracteristics, IRural } from 'src/interfaces/misc';

interface IProps {
  caracteristics?: ICaracteristics;
  rural?: IRural;
}

const PropertyRuralForm: React.FC<IProps> = (props) => {
  const { caracteristics, rural } = props;

  const [checked, setChecked] = useState(!!caracteristics);

  const onCheck = (e: RadioChangeEvent) => {
    setChecked(e.target.value);
  };

  const { Group } = Radio;

  return (
    <Card
      title="Características do Imóvel"
      subtitle="Atualize as características do imóvel."
    >
      <Row gutter={8}>
        <Col md={12}>
          <Form.Item
            label="Hectares (m³)"
            name={['rural', 'acres']}
            initialValue={rural?.acres}
          >
            <InputNumber placeholder="Insira o tamanho dos hectáres do imóvel..." />
          </Form.Item>
        </Col>
        <Col md={4}>
          <Form.Item
            label="Embarcadouros"
            name={['rural', 'docks']}
            initialValue={rural?.docks}
          >
            <InputNumber placeholder="Insira a quantidade de embarcadouros do imóvel..." />
          </Form.Item>
        </Col>
        <Col md={4}>
          <Form.Item
            label="Currais"
            name={['rural', 'corrals']}
            initialValue={rural?.corrals}
          >
            <InputNumber placeholder="Insira a quantidade de currais do imóvel..." />
          </Form.Item>
        </Col>
        <Col md={4}>
          <Form.Item
            label="Troncos"
            name={['rural', 'trunks']}
            initialValue={rural?.trunks}
          >
            <InputNumber placeholder="Insira a quantidade de troncos do imóvel..." />
          </Form.Item>
        </Col>
        <Col md={4}>
          <Form.Item
            label="Bretes"
            name={['rural', 'bretes']}
            initialValue={rural?.bretes}
          >
            <InputNumber placeholder="Insira a quantidade de bretes do imóvel..." />
          </Form.Item>
        </Col>
        <Col md={4}>
          <Form.Item
            label="Aguardas"
            name={['rural', 'waits']}
            initialValue={rural?.waits}
          >
            <InputNumber placeholder="Insira a quantidade de aguardas do imóvel..." />
          </Form.Item>
        </Col>
        <Col md={4}>
          <Form.Item
            label="Balanças"
            name={['rural', 'scales']}
            initialValue={rural?.scales}
          >
            <InputNumber placeholder="Insira a quantidade de balanças do imóvel..." />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={8}>
        <Form.Item label="Casa sede">
          <Group onChange={onCheck} value={checked}>
            <Radio value={true} checked={checked}>
              Sim
            </Radio>
            <Radio value={false} checked={!checked}>
              Não
            </Radio>
          </Group>
        </Form.Item>
      </Row>

      {checked ? (
        <>
          <Row gutter={8}>
            <Col md={4} xs={24}>
              <Form.Item
                label="Quartos"
                name={['caracteristics', 'rooms']}
                initialValue={caracteristics?.rooms}
              >
                <InputNumber />
              </Form.Item>
            </Col>
            <Col md={4} xs={24}>
              <Form.Item
                label="Suítes"
                name={['caracteristics', 'suites']}
                initialValue={caracteristics?.suites}
              >
                <InputNumber />
              </Form.Item>
            </Col>
            <Col md={4} xs={24}>
              <Form.Item
                label="Salas"
                name={['caracteristics', 'livingRooms']}
                initialValue={caracteristics?.livingRooms}
              >
                <InputNumber />
              </Form.Item>
            </Col>
            <Col md={4} xs={24}>
              <Form.Item
                label="Banheiros"
                name={['caracteristics', 'bathrooms']}
                initialValue={caracteristics?.bathrooms}
              >
                <InputNumber />
              </Form.Item>
            </Col>
            <Col md={4} xs={24}>
              <Form.Item
                label="Varandas"
                name={['caracteristics', 'balconies']}
                initialValue={caracteristics?.balconies}
              >
                <InputNumber />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col md={4} xs={24}>
              <Form.Item
                label="Cozinhas"
                name={['caracteristics', 'kitchens']}
                initialValue={caracteristics?.kitchens}
              >
                <InputNumber />
              </Form.Item>
            </Col>
            <Col md={4} xs={24}>
              <Form.Item
                label="Lavabos"
                name={['caracteristics', 'toilets']}
                initialValue={caracteristics?.toilets}
              >
                <InputNumber />
              </Form.Item>
            </Col>
            <Col md={4} xs={24}>
              <Form.Item
                label="Dependências"
                name={['caracteristics', 'dependencies']}
                initialValue={caracteristics?.dependencies}
              >
                <InputNumber />
              </Form.Item>
            </Col>
            <Col md={4} xs={24}>
              <Form.Item
                label="Área Técnica"
                name={['caracteristics', 'technicalAreas']}
                initialValue={caracteristics?.technicalAreas}
              >
                <InputNumber />
              </Form.Item>
            </Col>
            <Col md={4} xs={24}>
              <Form.Item
                label="Garagens"
                name={['caracteristics', 'garages']}
                initialValue={caracteristics?.garages}
              >
                <InputNumber />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col md={24} xs={24}>
              <Form.Item label="Descrição">
                <Input.TextArea rows={4} />
              </Form.Item>
            </Col>
          </Row>
        </>
      ) : null}
    </Card>
  );
};

export default PropertyRuralForm;
