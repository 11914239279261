import React, { useState, useEffect } from 'react';
import { message, notification, Popconfirm, Table } from 'antd';
import { connect } from 'react-redux';
import FeatherIcon from 'feather-icons-react';

import { Card } from 'src/components';
import { table } from 'src/utils';
import { IReduxState } from 'src/store';
import { fetchOwners, removeOwner } from 'src/store/owners/actions';
import TableActions from 'src/components/tableActions/tableActions.component';

import { IProps } from './owners.types';

const OwnersScreen: React.FC<IProps> = (props) => {
  const [loading, setLoading] = useState(true);
  const { owners } = props;

  useEffect(() => {
    props.fetchOwners();
    setLoading(false);
  }, []);

  const remove = (id: string) => {
    props.removeOwner(id).then(() => {
      notification.success({
        message: 'Proprietário removido com sucesso!',
      });
      props.fetchOwners();
    });
  };

  const dataSource =
    owners &&
    owners.map((item) => ({
      ...item,
      key: item.id,
      names: item.companyName || item.name,
      documents: item.owner?.cpf || item.owner?.cnpj,
      email: item.email,
      properties: item.owner?.properties.length,
    }));

  const columns = [
    {
      title: 'Nome/Empresa',
      key: 'names',
      dataIndex: 'names',
    },
    {
      title: 'CPF/CNPJ',
      key: 'documents',
      dataIndex: 'documents',
    },
    {
      title: 'E-mail',
      key: 'email',
      dataIndex: 'email',
    },
    {
      title: 'Propriedades',
      key: 'properties',
      dataIndex: 'properties',
    },
    {
      title: 'Ações',
      dataIndex: 'actions',
      key: 'actions',
      render: (text: any, record: any) => (
        <>
          <TableActions
            view={{ link: `/painel/proprietarios/${record.key}` }}
            edit={{ link: `/painel/proprietarios/editar/${record.key}` }}
            popup={{
              action: () => remove(record.key),
              title:
                'Tem certeza? Os imóveis deste proprietário serão removidos também.',
            }}
          />
        </>
      ),
    },
  ];
  return (
    <Card
      title="Proprietários"
      subtitle="Veja aqui os proprietários cadastrados no sistema."
    >
      {loading ? (
        <p>Carregando...</p>
      ) : (
        <Table
          dataSource={dataSource}
          {...table}
          columns={columns}
          scroll={{ x: 500 }}
        />
      )}
    </Card>
  );
};

const mapStateToProps = (state: IReduxState) => ({
  owners: state.owners.owners,
});

export default connect(mapStateToProps, { fetchOwners, removeOwner })(
  OwnersScreen
);
