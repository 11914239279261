import { AppointmentActionTypes, FETCH_APPOINTMENT, FETCH_APPOINTMENTS, IReducerAppointmentState } from "./types";
import storage from 'redux-persist/es/storage';
import persistReducer from "redux-persist/es/persistReducer";





const initialState: IReducerAppointmentState = {
  appointment: undefined,
  appointments: [],
}


const persistConfig = {
  storage,
  key: 'appointments',
  whitelist: ['appointments'],
}

const reducers = ( state = initialState, action: AppointmentActionTypes) => {
  switch (action.type) {
    case FETCH_APPOINTMENT:
      return {
        ...state,
        appointment: action.payload,
      }
    case FETCH_APPOINTMENTS:
      return {
        ...state,
        appointments: action.payload,
      }
    default: {
      return state
    }
  }
}

export default persistReducer(persistConfig, reducers);