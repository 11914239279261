import gql from 'graphql-tag';
import client from './client.auth';

export const appointmentService = {
  appointments: () =>
    client
      .query({
        query: gql`
          query appointments {
            me {
              id
              admin {
                id
                appointments {
                  id
                  appointmentDate
                  appointmentHour
                  broker {
                    id
                    name
                    companyName
                    broker {
                      id
                      creci
                    }
                  }
                  property {
                    id
                    type
                    subtype
                    address {
                      city
                      neighborhood
                      street
                      number
                    }
                    owner {
                      id
                      name
                      companyName
                    }
                  }
                  createdAt
                  updatedAt
                  canceledDate
                }
              }
            }
          }
        `,
      })
      .then((res) => res.data.me.admin.appointments),

  appointment: (id: string) =>
    client
      .query({
        query: gql`
          query appointment($id: ID!) {
            me {
              id
              admin {
                id
                appointment(appointmentId: $id) {
                  id
                  appointmentDate
                  appointmentHour
                  broker {
                    id
                    name
                    companyName
                    broker {
                      id
                      creci
                    }
                  }
                  property {
                    id
                    owner {
                      id
                      name
                      companyName
                    }
                  }
                  createdAt
                  updatedAt
                  canceledDate
                }
              }
            }
          }
        `,
        variables: { id },
      })
      .then((res) => res.data.me.admin.appointment),
};
