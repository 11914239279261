import React, { useState } from 'react';
import { Form, Row, Radio, Button, message, notification } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { connect } from 'react-redux';

import { Card } from 'src/components';
import { createBroker } from 'src/store/broker/actions';

import PersonForm from './components/newBroker/personForm';
import CompanyForm from './components/newBroker/companyForm';
import { IProps } from './components/types/brokers.types';
import { Redirect } from 'react-router-dom';
const NewBrokerScreen: React.FC<IProps> = (props) => {
  const [checked, setChecked] = useState('');
  const [sending, setSending] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState('');

  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    setSending(true);
    props
      .createBroker(values)
      .then((res) => {
        message.success('Corretor Cadastrado com sucesso!');
        setSending(false);
        setRedirect(`/painel/corretores/${res.id}/`);
      })
      .catch((err) => {
        setSending(false);
      });
  };

  const onSearchPerson = async (
    value: string,
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.MouseEvent<HTMLElement, MouseEvent>
      | React.KeyboardEvent<HTMLInputElement>
      | undefined
  ) => {
    setLoading(true);
    const formattedZipcode = value.replace('-', '');
    if (formattedZipcode.length !== 8) {
      return setLoading(false);
    }
    await fetch(`https://viacep.com.br/ws/${formattedZipcode}/json`)
      .then((res) =>
        res.json().then((data) => {
          if (data.erro === true) {
            setLoading(false);
            return notification.error({
              message:
                'CEP inválido, verifique se o CEP digitado está correto ou se ele existe.',
            });
          }
          form.setFieldsValue({
            address: {
              city: data.localidade,
              neighborhood: data.bairro,
              street: data.logradouro,
              complement: data.complemento,
            },
          });
          setLoading(false);
        })
      )
      .catch((err) => {
        setLoading(false);
        notification.error({
          message: 'Insira o CEP para pesquisar.',
        });
      });
  };

  const onSearchCompany = async (
    value: string,
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.MouseEvent<HTMLElement, MouseEvent>
      | React.KeyboardEvent<HTMLInputElement>
      | undefined
  ) => {
    setLoading(true);
    const formattedZipcode = value.replace('-', '');
    if (formattedZipcode.length !== 8) {
      return setLoading(false);
    }
    await fetch(`https://viacep.com.br/ws/${formattedZipcode}/json`)
      .then((res) =>
        res.json().then((data) => {
          if (data.erro === true) {
            setLoading(false);
            return notification.error({
              message:
                'CEP inválido, verifique se o CEP digitado está correto ou se ele existe.',
            });
          }
          form.setFieldsValue({
            address: {
              city: data.localidade,
              neighborhood: data.bairro,
              street: data.logradouro,
              complement: data.complemento,
            },
          });
          setLoading(false);
        })
      )
      .catch((err) => {
        setLoading(false);
        notification.error({
          message: 'Insira o CEP para pesquisar.',
        });
      });
  };

  const onChange = (e: RadioChangeEvent) => {
    setChecked(e.target.value);
  };

  return (
    <Row align="middle" justify="center">
      {!!redirect && <Redirect to={redirect} />}
      <Card
        title="Cadastrar novo Corretor"
        subtitle="Cadastre um novo corretor, físico ou jurídico."
      >
        <Radio.Group onChange={onChange} value={checked}>
          <Radio value="person" checked={checked === 'person'}>
            Pessoa Física
          </Radio>
          <Radio value="company" checked={checked === 'company'}>
            Pessoa Jurídica (Empresa)
          </Radio>
        </Radio.Group>
        <Form layout="vertical" onFinish={onFinish} form={form}>
          {checked === 'person' ? (
            <PersonForm onSearch={onSearchPerson} loading={loading} />
          ) : undefined}
          {checked === 'company' ? (
            <CompanyForm onSearch={onSearchCompany} loading={loading} />
          ) : undefined}
          {!!checked ? (
            <Row align="middle" justify="center">
              <Button
                type="primary"
                htmlType="submit"
                className="btn-success"
                loading={sending}
              >
                Cadastrar
              </Button>
            </Row>
          ) : undefined}
        </Form>
      </Card>
    </Row>
  );
};

export default connect(undefined, { createBroker })(NewBrokerScreen);
