import gql from 'graphql-tag';
import { ICreateOwnerInput, IUpdateOwnerInput } from 'src/interfaces/owner';
import client from './client.auth';

const OwnersServices = {
  fetchOwners: () =>
    client
      .query({
        query: gql`
          query owners {
            me {
              id
              admin {
                id
                owners {
                  id
                  name
                  companyName
                  email
                  birthdate
                  phone
                  owner {
                    id
                    cpf
                    cnpj
                    profession
                    address {
                      city
                      state
                      zipcode
                      street
                      number
                      neighborhood
                      complement
                    }
                    maritalStatus
                    stateRegistration
                    properties {
                      id
                      type
                      subtype
                      zone
                      status
                      address {
                        street
                        number
                        neighborhood
                        city
                        state
                        zipcode
                        complement
                        complex
                        latitude
                        longitude
                      }
                      builder
                      images {
                        path
                      }
                      car
                      subscription
                      itr
                      scripture
                      ccir
                      registration
                      bank
                      overbalance
                      extraHouses {
                        balconies
                        bathrooms
                        dependencies
                        garages
                        kitchens
                        livingRooms
                        others
                        rooms
                        suites
                        technicalAreas
                        toilets
                      }
                      area {
                        backyard
                        frontLot
                        leftLot
                        lotArea
                        otherMeasures
                        privateArea
                        rightLot
                      }
                      caracteristics {
                        balconies
                        bathrooms
                        dependencies
                        garages
                        kitchens
                        livingRooms
                        others
                        rooms
                        suites
                        technicalAreas
                        toilets
                      }
                      values {
                        annualIptu
                        monthlyIptu
                        communityRentPrice
                        communitySalePrice
                        rentPrice
                        salePrice
                        rentExtraTaxes
                        saleExtraTaxes
                      }
                      columns
                      isSold
                    }
                  }
                }
              }
            }
          }
        `,
      })
      .then((res) => res.data.me.admin.owners),

  fetchOwner: (id: string) =>
    client
      .mutate({
        mutation: gql`
          query owner($id: ID!) {
            me {
              admin {
                owner(id: $id) {
                  id
                  name
                  companyName
                  email
                  birthdate
                  phone
                  owner {
                    id
                    cpf
                    cnpj
                    profession
                    address {
                      city
                      state
                      zipcode
                      street
                      number
                      neighborhood
                      complement
                    }
                    maritalStatus
                    stateRegistration
                    properties {
                      id
                      status
                      type
                      subtype
                      values {
                        salePrice
                        rentPrice
                      }
                    }
                  }
                }
              }
            }
          }
        `,
        variables: { id },
      })
      .then((res) => res.data.me.admin.owner),

  createOwner: (input: ICreateOwnerInput) =>
    client
      .mutate({
        mutation: gql`
          mutation createOwner($input: CreateOwnerInput!) {
            createOwner(input: $input) {
              id
              name
              companyName
              email
              birthdate
              phone
              owner {
                id
                cpf
                cnpj
                profession
                stateRegistration
                maritalStatus
                address {
                  city
                  state
                  zipcode
                  street
                  number
                  neighborhood
                  complement
                }
              }
              createdAt
              updatedAt
            }
          }
        `,
        variables: { input },
      })
      .then((res) => res.data.createOwner),

  updateOwner: (input: IUpdateOwnerInput) =>
    client
      .mutate({
        mutation: gql`
          mutation updateOwner($input: UpdateOwnerInput!) {
            updateOwner(input: $input) {
              id
              companyName
              name
              email
              phone
              owner {
                id
                cpf
                cnpj
                stateRegistration
                maritalStatus
                profession
                address {
                  city
                  state
                  zipcode
                  complement
                  neighborhood
                  street
                  number
                }
              }
            }
          }
        `,
        variables: { input },
      })
      .then((res) => res.data.updateOwner),

  removeOwner: (userId: string) =>
    client
      .mutate({
        mutation: gql`
          mutation removeOwner($userId: ID!) {
            removeOwner(userId: $userId)
          }
        `,
        variables: { userId },
      })
      .then((res) => res.data.removeOwner),
};

export default OwnersServices;
